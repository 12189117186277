import React from 'react';
import './style.css';
import { ListData } from '../journaler/components/Wrappers';

import { BulletListContents, MainSectionWrapper, SectionWrapper, MulitiList } from '../journaler/components/Wrappers';
import {
  intro_overall_contents,
  tableOfContents,
  acceptanceContents,
  susbscriptionContents,
  privacyPoliceContents,
  terminateAccount,
  rightCopyrightsContents,
  liabilityContents,
  changesContents,
} from './contents';

const AemPlusTermsConditions = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='pageWrapper'>
      <div className='row justify-content-center'>
        <h1 className='titleBorder text-center'>AEM Algorithm’s ‘AEM+’: Terms and Conditions</h1>
      </div>

      <div className='container py-4'>
        {/*  Introduction & Overall Section */}
        {intro_overall_contents.map((eachSection) => (
          <MainSectionWrapper
            key={eachSection.id}
            id={eachSection.id}
            title={eachSection.title}
            contents={eachSection.contents}
          />
        ))}

        <ListData dataCollection={acceptanceContents}/>
        <ListData dataCollection={privacyPoliceContents}/>
        <ListData dataCollection={susbscriptionContents}/>
        <ListData dataCollection={terminateAccount}/>
        <ListData dataCollection={rightCopyrightsContents}/>
        <ListData dataCollection={liabilityContents}/>
        <ListData dataCollection={changesContents}/>
      </div>
    </div>
  );
};

export default AemPlusTermsConditions;
