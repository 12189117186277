import React from 'react';
import SeamlessTransaction from '../../../../assets/aemPlus/wallet.png'
const aemDesContents = {

    sectionTwoTitle: 'SEND, RECIEVE, SWAP AND SUBSCRIBE',
    sectionTwoDes: 'Seamless Transactions Between Wallets'
  };

const SendAndRecieve = () => (
    <div className='sectionTwoBg'>
        <div className='container'>
            <div className='row justify-content-center'>
                {/* <div className='col-md-6 col-sm-12'> */}
                    <h4 className='SendAndRecievetitle mb-4'>{aemDesContents.sectionTwoTitle}</h4>
                    <p className='SubSendAndRecievetitle my-4'>{aemDesContents.sectionTwoDes}</p>
                {/* </div> */}
                {/* <div className='col-md-6 col-sm-12 justify-content-end'> */}
                    <img src={SeamlessTransaction} alt='Seamless Transaction' className='seamlessTransactionImg' />
                {/* </div> */}
            </div>
        </div>
    </div>
)

export default SendAndRecieve