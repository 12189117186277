import React from 'react';
import '../style.css'
import CheckCircle from '../../../../assets/journaler/solutions/CheckCircle.png'
import JournalerWalletPage from '../../../../assets/journaler/solutions/wallet page demo.png'



const FeatureContentList = ({ content }) => (
  <div className='row justify-content-between' >
    <div className='col-1'>
      <img src={CheckCircle} alt='tick' />
    </div>
    <div className='col-11 content-sub'>
      <p>{content}</p>
    </div>
  </div>
)


const WalletConnection = (props) => {
  if (props.section === 1) {
    return (
      <div className='row justify-content-end pt-5 pb-5'>
        <div className='col-md-6 col-sm-12 pt-5 colLeft' >
          <div>
            <p className='titleMidHeader pb-4'>{props.content.header}
              <span className='titleMidHeaderGradient'> {props.content.headerGradient}</span></p>
            <div>
              {props.content.content.map((content) => (
                <FeatureContentList content={content} />
              ))}
            </div>

          </div>
        </div>
        <div className='col-md-6 col-sm-12'>
          <img className='img-fluid' src={props.image} alt="Responsive image" />
        </div>
      </div>
    )
  }
  else if (props.section === 2) {
    return (
      <div className='row justify-content-start pt-5 pb-5'>
        <div class='col-md-6 col-sm-12 ' >
          <img className='img-fluid' src={props.image} alt="Responsive image" />
        </div>
        <div className='col-md-6 col-sm-12 pt-3 '>
          <p className='titleMidHeader pb-4' >
            <span className='titleMidHeaderGradient'>{props.content.headerGradient}</span> {props.content.header}</p>
          <div>
            {props.content.content.map((content) => (
              <FeatureContentList content={content} />
            ))}
          </div>
        </div>
      </div>
    )
  }
  else {
    return (
      <div className='row justify-content-end pt-5 pb-5'>
        <div className='col-md-6 col-sm-12 pt-5 colLeft'>
          <div>
            <p className='titleMidHeader pb-4'>
              <span className='titleMidHeaderGradient'> {props.content.header} </span> {props.content.headerGradient}</p>
            <div>
              {props.content.content.map((content) => (
                <FeatureContentList content={content} />
              ))}
            </div>
          </div>
        </div>
        <div className='col-md-6 col-sm-12'>
          <img className='img-fluid' src={props.image} alt="Responsive image" />
        </div>
      </div>
    )

  }


}

export default WalletConnection