import React, { Component } from 'react';
import ButtonGroup from './components/ButtonGroup';
import Integrations from './components/Integrations';
import JournalerFeatures from './components/JournalerFeatures';
import OurFeatures from './components/OurFeatures';
import { Helmet } from 'react-helmet';
import Journaler from '../../../assets/journaler/main/Journaler.png';
import Journaler_1 from '../../../assets/journaler/main/Journaler_1.png';
import './Main.css';

class JournalerMain extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  screenWidth = (width) => {
    return window.screen.width > width;
  }

  render() {
    return (
      <div className='container-header'>
        <Helmet>
          <title>AEM Algorithm | Simple Crypto Accounting Software for Business</title>
          <meta name="Journaler" content="Automate how you track, reconcile & report crypto with AEM Journaler. 
        Integrated with Xero and Intuit QuickBooks. Supports 10,000+ currencies. Sign up for free today." />
        </Helmet>
        <div className='desktop'>
          <div className='journalerMainHeader d-flex justify-content-between'>
            <div className='pt-5 row'>
              <div className='col-3'>
              </div>
              <div className='col-8 pt-5 d-flex align-items-center'>
                <div className='d-flex justify-content-center flex-column'>

                  <div className='d-flex justify-content-left'>
                    <img className='img-fluid' src={Journaler} alt='demo' />
                  </div>
                  <div className='titleJournaler pt-3'>
                    The future of crypto accounting is here
                  </div>
                  <div className='subJournaler pt-3'>
                    We've transformed how you manage your crypto transactions. Easily record, reconcile & report—all in one powerful software.
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex flex-row-reverse colImage'>
              <img className='img-fluid img-main' src={Journaler_1} alt='demo' />
            </div>
          </div>
        </div>
        <div className='mobile journalerMainHeader pt-5'>
          <div className='d-flex justify-content-center align-items-center flex-column'>
            <div className='d-flex justify-content-left'>
              <img src={Journaler} alt='demo' />
            </div>
            <div className='titleJournaler pt-3'>
              The future of crypto accounting is here
            </div>
            <div className='subJournaler pt-3'>
              We've transformed how you manage your crypto transactions. Easily record, reconcile & report—all in one powerful software.
            </div>
          </div>
          <div className='d-flex justify-content-center align-items-center pt-5 pb-3'>
            <img className='img-fluid ' src={Journaler_1} alt='demo' />
          </div>
        </div>
        <JournalerFeatures />
        <div className='spaceHeight'></div>
        <OurFeatures />
        <div className=' bgIntegration'>
          <div className='container'>
            <Integrations isMobile={!this.screenWidth(767)} />
            <div className='d-flex justify-content-center pb-5'>
              <button className='btnLearnMore' onClick={(e) => {
                e.preventDefault();
                window.location.href = '/journaler';
              }}>Learn more</button>
            </div>
          </div>
        </div>
        <ButtonGroup fdata={'Start using'} gradientData={'Journaler'} ldata={'today'} />
      </div>
    );
  }
}

export default JournalerMain;
