import {API} from "../constants";

class HelpCenterService {
    async getHelpCenter() {
        try {
            const response = await fetch(API.HELP_CENTER)
            const {data} = await response.json()
            if (data && data.length > 0) {
                return data
            }
            throw Error('Cannot get help center data')
        }catch (e) {
            return []
        }
    }
}

export const helpCenterServiceInstance = Object.freeze(new HelpCenterService())
