import React, { Component } from 'react';
import './newsList.css'
import HeaderImg from '../../../assets/news/article1_3.svg'
import SocialMedia from '../../../assets/news/aem_social media-Linkedin.png'

const data = {
  title: 'Who are we?',
  date: '2021.05.17',
  contentPartOne: [
    'The story of AEM Algorithm begins in early 2017, in the lead up to the Crypto boom of 2018. Jakub Sawczuk discovered the enormous lack of resources available for businesses to report and track cryptocurrency transactions, and to make it seamless for B2B and B2C. Jakub knew that there was a gap in the market for services that provide consumers with the ability to pass accounting data with payments using crypto currency, enabling them to take full advantage of the decentralised system that could remove reliance on standard banking and manual processes for both reporting but also capturing of transaction data.',
    'Commencing the draft for his accounting software Jakub got involved with the Blockchain Centre Block Engine program through the encouragement of his wife and step-father. It was here that he pitched the solution on the last day of entry submission, by creating a short 1 minute, unrehearsed video where he sold the concept of a full size accounting program and enterprise solution that would one day create a complete ecosystem outside of traditional banking. This could one day provide clients with versatile and accessible reconciliation of crypto transactions through the blockchain and enable them to instantaneously send data and store it across the transaction and storage layers on the blockchain, utilising cutting edge algorithm’s and cryptographic technologies. The result was so successful that the program was immediately shortlisted, and he joined an elite group of businesses also looking to enter the blockchain industry. All that he needed now was a name: AEM - standing for Accounting Enterprise Middleware, but also holding an underlying significance, with AEM representing the first 3 letters of his daughter Emanuela’s name, Similarly the word Algorithm, would represent the complex computations that the platform had to make.',
    'Jakub knew that in order for his business to flourish, he needed an intelligent and passionate team. After working with him previously in his past roles as a Financial Controller in IT, Jakub reached out to Matthew Vanmullen as his CTO, infrastructure architect, soon to become his business partner to develop the software, and Matthew contracted Russell Black as his senior developer to help bring the conceptual design of AEM into a workable Proof of Concept. With Jakub’s background in finance, and systems, and Mathew’s strong technical background they knew that the idea of AEM was both approachable and realistic and more importantly was going to be highly scalable. The goal was to make this one day worldwide and the standard/or the go to system for blockchain and finance reporting and use.',
    'Through the process of refinement and setting achievable targets, AEM developed an integrated accounting tool that provided individuals the ease necessary to sort through cryptocurrency transactions and automatically and filter them out from the gigabytes of data that each chain would hold. From there the transaction data would be from the blockchain and into standard accounting packages, but initially into recognisable summary files with all the information converted and ready to use by the accountants. After brainstorming the name of the package was labelled the AEM ‘Journaler’, suggested by Matthew as there were only a scarce number businesses creating these accounting journals, and core functionality was that of creating accounting Journals.',
    'In terms of the product offer, AEM Journaler is the first in the market to have a complete set of necessary interfaces accounting tools that allow businesses and individuals to fully capitalise on the tracking and reconciling of cryptocurrency and managing the transaction flows. AEM has a holistic approach in providing crypto solutions, attending to all needs with simplicity and scalability - allowing it to host tens of thousands of consumers. What it means in simple terms, it is a first in the world system that facilitates flow of transaction data from the moment the cryptocurrency transaction occurring all the way to the financial system and ultimately even the Taxation Office What makes it unique and on of it’s kind is that the data comes from the blockchain but also (with the work in the current pipeline) that data can also be pulled from Exchanges, imported manually, and last but not least, AEM would also provide the user with their own and secure mobile cryptocurrency wallet that would enable them to make those transaction and keep everything in one place. This is a very different concept of looking at accounting systems and meshing it with a process surrounding B2B, C2B and investment or trading.',
    'To track back, in late 2019, the organisation had been successful in building the cloud version of the main Journaler MVP – but this simply wasn’t enough for AEM. To fully optimise and capitalise on the necessity for B2B accounting tools, it was also necessary to create a wallet, which allowed their users an easy way to conduct their Crypto transactions. After reaching out to their ecosystem partners within NEM, the promoters of the earlier block engine program, Jakub found suitable contractors to perform this work. Providing AEM with an ongoing connection with the NEM ecosystem and support of their currencies and any latest upgrades. Since then however their outlook has really broadened and is about adapting to the most used and utilised cryptocurrencies that continue to evolve and enter the blockchain ecosystem.',
    'Through years of hard work, bootstrapping and working on the app whenever possible - AEM finally started to expand. What helped AEM stand apart from the other BlockEngine projects was their ability to stay consistent in what they wanted, and their brute determination to work nights, weekends outside of their day jobs. One of the early offers of the 2018 startup program was to receive a significant amount of pre investment. That unfortunately has coincided with the market crash and as a result, almost all the participants of the BlockEngine program either modified, dropped or moved on from their solutions into something else. It was also explained by the fact that most projects in early 2018 were after an ICO (Initial Coin Offering) which was a way to source a lot of funding by launching a token. AEM was never really going down that path knowing that in their opinion a technical solution to an accounting problem for cryptocurrency would need to precede a rollout of it’s own cryptocurrency if it was ever to be even considered and with the aim of bridging the gap between the blockchain and regular business usage it was always about providing the necessary tools for business to report, use and make payments as alternative to standard banking and regular currencies. On the topic of the currency, their focus was more on governments and regulation coming in eventually and creating more stable and useful currencies that would not fluctuate in value. Again the solutions would still center itself around both the fluctuating assets such as Bitcoin.'
  ],
  titleTwo: 'So, where is AEM now?',
  contentPartTwo: [
    'Within the last few years, AEM has grown substantially! Through its evolution, the team was spread across Australia, Europe and Asia. However with the recent move by Jakub and acquisition of the new senior team the shift has now commenced to everyone being centered in Australia and new stakeholders entering the business, given that the business is now reaching its commercialisation and pre-investment phase. The last 12 months have been dedicated to not only the technical development but also business planning, bringing in staff across different areas, such as IT, Business and Marketing, all thanks to the Partnership with RMIT Blockchain Innovation Hub, which has flourished since early 2020 and coincidental introduction and the Digital Innovation Festival in Melbourne in 2019.',
    'AEM constantly strives to grow and improve, with a young and dynamic group of individuals who are passionate about blockchain. Going through a substantial scale up, they are working on a range of projects, and finalising their technology - the biggest being their integration with Xero and becoming an App Partner in second part of the 2020 and their preapproval to be most likely the first to market a true cryptocurrency / blockchain app that would connect DLT transactions with the standard accounting system of Xero . With Journaler’s launch date now fast approaching, having worked through additional upgrades and scaleup steps that would enable a significant number of users onto it’s platform from Xero and beyond. The work in fact is so extensive that Xero is set to be the initial springboard and integrations with various blockchains and cryptocurrency trading platforms are already under way. Further to that there are new projects being born alongside or as an extension of the Journaler package. The system is set to enter the scene at the perfect time with wide adoption of cryptocurrency and recognition of it as perfect investment vehicle, but important to mention those currencies are now set to find more utility and use cases, with an example of companies such as Tesla already coming on board and investing and opening itself up to accepting Bitcoin as means of payment.',
    'In summary, AEM provides businesses and clients the ability to navigate and facilitate their crypto transactions, filling the gap in the market, and cuts down on processing time. It is important to note that the processes of capturing and correctly reporting transactions are still imperfect and in many cases can be very manual and labour intensive, resulting in time and money being spent and deterring some from considering using the cryptocurrencies more broadly., AEM is constantly working towards new innovations and ways to integrate with traditional accounting platforms and expand as the interest in digital currencies takes off.',
    'Jakub states that the most important thing in his business is consistency, flexibility and the ability to be realistic. Not only in his role as CEO but also acting largely as the product owner, essentially coming up with every new system and module in line with where the demand would sit or the new invention would solve. The ability to do that comes from the fact that Jakub has spent 10 years before moving into Financial Management and systems in Product Design and Development, Engineering and high level high intensity work with various engineering teams from his days back at GE – and though he can’t tell the future, he has the unique ability to adapt to the rapidly changing financial trends. With Matthew, assisting to fill in the gaps to make these concepts a reality. None of the systems would have come about with Matthew’s years of experience in IT and connecting the infrastructure, with development and assuring a code and platform is built to convert the prototypes into workable solutions. AEM has a mission to empower businesses with enterprise-ready tools, providing an infrastructure that prepares traditional industries for the advent of digital transactions using crypto-currency by providing a system adapted to their needs. They want to be part of a movement that transforms the idea of transactions – through more safe, transparent and effective ways.',
    'In the future, once fully commercialised, AEM has already set its sights beyond Australia to help businesses easily and efficiently track their crypto currency – starting with those in New Zealand, and eventually growing to the Europe, North America and Asia markets and as previously mentioned, link more systems and platforms such as Binance, Coinbase, and other exchanges, with the same approach on the accounting side with systems outside of Xero being already considered or being integrated as the next integration stepping stones .'
  ]
}

const contentOne = data.contentPartOne.map((content) =>
  <p className='text-justify'>{content}</p>
)

const contentTwo = data.contentPartTwo.map((content) =>
  <p className='text-justify'>{content}</p>
)

class WhoAreWe extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <div className='globalHeaderBgS newsListHeader text-center'>
          <div className='row'>
            <div className='col'>
              <h1 className='h1Bold'>News</h1>
            </div>
          </div>
        </div>

        <div className='newsContentBg'>
          <div className='container'>
            {/* <div className='newsHeaderImgBg'> */}
              <img className="card-img-top" src={HeaderImg} alt='header image' />
            {/* </div> */}
            <div className='newsContent'>
              <h3 className='font-weight-bold'>{data.title}</h3>
              <p style={{ color: '#707070' }}>{data.date}</p>
              <div>{contentOne}</div>
              <img src={SocialMedia} alt='social media' style={{ width: '100%' }} />
              <h3 className='font-weight-bold pt-3'>{data.titleTwo}</h3>
              <div>{contentTwo}</div>
            </div>
            {/* TODO: share to media function */}
            <div className='newsContentFooter'>
              {/* <p className="text-right">Share with</p> */}
            </div>
          </div>
        </div>
      </>
    );
  };
}

export default WhoAreWe;
