export const general = {
  title: 'AEM Algorithm’s ‘AEM+’: Privacy Policy',
  subtitle: 'GENERAL',
  content: [
    'Hi there! In recognition of your privacy rights as a consumer, the following policy outlines the practices, principles and procedures put in place to ensure the security of your personal information and data. This document is intended to help you, the consumer, better understand why we collect your data, what information we extract, and furthermore how this is done. We understand that the safety of your personal information is of the utmost importance to you, so rest assured that the following information will enlighten you on the extent to which ‘AEM Technologies Pty Ltd’ has guarded against the exploitation of your data collected through our ‘AEM+’ mobile application.',
    'If you have any further questions or concerns, please feel free to contact our friendly team through any of the various channels outlined below.',
    'Thank you for your interest and we look forward to facilitating your cryptocurrency journey!'
  ]
}

export const acknowledgement = {
  id: 'Acknowledgement',
  title: '1. Acknowledgement',
  desc: [
    {
      subTitle: '1.1 AEM Algorithm ("us", "we", or "our") operates https://www.aemalgorithm.io/ (the "Site").',
      details: [
        'This section informs you of our policies regarding the collection, use and disclosure of personal Information we receive from users of the Site. We use your Personal Information only for providing and improving the Site. By using the Site, you agree to the collection and use of information in accordance with this policy.',
      ],
    },
  ]
};

export const dataCollection = {
  id: 'dataCollection',
  title: '2. Data collection',
  desc: [
    {
      subTitle: '2.1 Information collection and use',
      details: [
        `While using our Site, we may ask you to provide us with certain personally identifiable information that
          can be used to contact or identify you. Personally identifiable information may include, but is not limited to your name ("Personal Information").`,
        `Any data installed within the app ‘lives’ within the app.`,
        'Personal data and information extracted:',
        'Personal email and other forms of communication',
        'Name',
        'ABN',
        'Blockchain address/es',
        'Residential and/or postal address',
        'Account information from affiliated third party sources - Xero, QuickBooks, Binance, etc.'
      ],
    },
    {
      subTitle: '2.2 Log Data',
      details: [

        "Like many site operators, we collect information that your browser sends whenever you visit our Site ('Log Data'). This Log Data may include information such as your computer's Internet Protocol ('IP') address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages and other statistics.",

      ],
    },
    {
      subTitle: '2.3 Communications',
      details: [

        "We may use your Personal Information to contact you with newsletters, marketing or promotional materials and other information that may help us to inform/update you about",

      ],
    },
  ],
};

export const dataStorage = {
  id: 'Data Storage and Protection',
  title: '3. Data Storage and Protection',
  desc: [
    {
      subTitle: '3.1 Cookies',
      details: [
        "Cookies are files with a small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer's hard drive. Like many sites, we use 'cookies' to collect information.",
      ],
    },
    {
      subTitle: '3.2 Security',
      details: [
        'AEM Algorithm has abided by and adhered to the security criteria put in place by the Marketplaces, ‘Google play’ and the ‘App store’, through the development of AEM+. AEM+ has implemented password protection to secure the users private data and personal information.',
        'The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.'
      ],
    },
  ],
};

export const changes = {
  id: 'Changes to our Privacy Policy',
  title: '4. Changes to our Privacy Policy',
  desc: [
    {
      subTitle: '4.1 Changes to Privacy Policy',
      details: [
        `This ‘Privacy Policy’ is effective as of 1/04/2021 and will remain in effect except with 
        respect to any changes in its provisions in the future, which will be in effect 
        immediately after being posted on this page. We reserve the right to update or 
        change our Privacy Policy at any time and you should check these Privacy Policy
         periodically. Your continued use of the Service after we post any modifications 
         to the Privacy Policy on this page will constitute your acknowledgment of the 
         modifications and your consent to abide and be bound by the modified Privacy 
         Policy. If we make any material changes to this Privacy Policy, we will 
         notify you either through the email address you have provided us, or by 
         placing a prominent pop-up notice through our ‘AEM+’ mobile application.`,
      ],
    },
    {
      subTitle: '4.2 Contact Us',
      details: [
        'If you have any questions about this ‘Privacy Policy’, please contact us.',
        'EMAIL : support@aemalgorithm.io',
        'LOCATION: RMIT Blockchain Innovation Hub, Lv 2, Building 97, 106 Victoria St, Carlton 3053, VIC, Australia',
      ],
    },
  ],
};

export const xero = {
  id: 'Xero',
  title: 'Xero',
  desc: [
    {
      subTitle: 'Information we collect',
      details: [
        "For example, we ask for your contact information when you sign up for a free trial, respond to a job application or an email offer, participate in community forums, join us on social media, take part in training and events, contact us with questions or request support. If you don’t want to provide us with personal data, you don’t have to, but it might mean you can’t use some parts of our websites or services.",
      ],
    },
    {
      subTitle: 'Information we acquire automatically',
      details: [
        'We collect some information about you automatically when you visit our websites or use our services, like your IP address and device type. We also collect information when you navigate through our websites and services, including what pages you looked at and what links you clicked on. This information is useful for us as it helps us get a better understanding of how you’re using our websites and services so that we can continue to provide the best experience possible (e.g., by personalising the content you see).'
      ],
    },
  ],
};