import React, { Component } from 'react';
import AEMapp from '../../../../assets/aemPlus/aemPlusMock.svg';

import '../Main.css';


class AemPlusDemo extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className='aemDemoBg'>
        <div className='pt-0'>
          <h2 className='text-center' style={{ color: 'white' }}>LIVE NOW</h2>
        </div>
        <div className='pt-0'>
          <h2 className='text-center title py-4' style={{ color: '#000000' }}>NEW & IMPROVED AEM +</h2>
        </div>
        <div className='d-flex justify-content-center'>
          <img src={AEMapp} alt='aem app' className='aemMockImg'/>
        </div>
      </div>
    );
  }
}

export default AemPlusDemo;
