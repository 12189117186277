import React, { Component } from 'react';
import './ContactUs.css';
import JoinNewsletter from './components/JoinNewsletter'
import ContactUsForm from './components/ContactUsForm'
import SendEmailSection from './components/SendEmailSection'

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      name: '',
      subject: '',
      message: '',
      showAlert: false,
      isLoading: true,
    };
  }

  componentDidMount() {
    this.timer = setTimeout(() => {
      this.setState({ isLoading: false });
    }, 500); // 1 second delay
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }


  render() {
    const { isLoading } = this.state;
    return (
      <> {isLoading ? (
        <div style={{ color: 'black',
          padding: '200px',
          display: 'flex',
          justifyContent:'center'
       }}>Loading...</div> 
      ) : (
          <div style={{ height: '1100px' }}>
            <iframe
              src="https://forms.clickup.com/6952527/f/6m5jf-6516/Q9TWBQ5MRIWXG97JFE"
              onWheel={() => { }}
              width="100%"
              height="100%"
              style={{  border: '1px solid #ccc' }}
            ></iframe>
          </div>
         )}
      </>
    );
  }
}

export default ContactUs;
