import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import '../node_modules/font-awesome/css/font-awesome.min.css'; 
import 'font-awesome/css/font-awesome.min.css';
<link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet"/>;
<link rel="stylesheet" type="text/css" href="path/to/notifications.css"></link>;
<link rel="stylesheet" href="css/style.css"></link>;
ReactDOM.render(<App />, document.getElementById('root'));