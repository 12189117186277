import './NewIntegration.css';

// assets
import Safe from '../../../assets/journaler/NewJournaler/safe.png';
import binance from '../../../assets/journaler/NewJournaler/binance.svg';
import coinbase from '../../../assets/journaler/NewJournaler/coinbase.svg';
import fireblock from '../../../assets/journaler/NewJournaler/fireblock.png';
import IntegrationAnimation from '../../../assets/journaler/NewJournaler/integration.mov';
import quickbooks from '../../../assets/journaler/NewJournaler/quickbooks.svg';
import xero from '../../../assets/journaler/NewJournaler/xero.png';

// modules
import { Helmet } from 'react-helmet';
import ReactPlayer from 'react-player';
import {Col, Container, Row, Image} from "react-bootstrap";

// components
import ButtonGroup from '../main/components/ButtonGroup';

const NewIntegration = () => {
    return (
        <div>
            <Helmet>
                <title>AEM Algorithm | Easily Report Your Crypto Into Xero Accounting</title>
                <meta name="Integrations" content="Sync crypto transactions, invoices, bills, tax liabilities & wallet balances directly into your Xero accounting system with AEM Journaler. Sign up now with a free trial." />
            </Helmet>
            <div className='networkHeader newIntegration'>
                <div className='container text-center'>
                    <div className='pt-5'>
                        <span className='headerTitle'>Integrations </span>  <span className='headerTitleWhite'> for powerful <br></br> crypto accounting</span>
                    </div>
                    <div className='subeaderTitle pt-3'>
                        Set up direct connections with your existing accounting systems, exchanges, <br></br>and wallet platforms with Journaler's supported integrations.
                    </div>
                </div>
            </div>
            <div className='container pt-5'>
                <div className='row  align-items-center'>
                    <div className='col-6'>
                        {/* <img className='img-fluid' src={IntegrationAnimation}></img> */}
                        {/* <div class="embed-responsive embed-responsive-16by9">
                            <iframe class="embed-responsive-item" src={IntegrationAnimation} allowfullscreen></iframe>
                        </div> */}
                        <ReactPlayer
                            url={IntegrationAnimation}
                            controls={true}
                            muted={true}
                            playing={true}
                            width='100%'
                            height='100%'
                        />
                    </div>
                    <div className='col-6'>
                        <div> <span className='seamless'>Seamless</span> <span className='crypto'> crypto accounting experience </span></div>
                        <div className='subtitleIntegrate pt-3'>
                            Connect Journaler with you accounting software and cryptocurrency exchanges.
                        </div>
                    </div>
                </div>
            </div>

            {/* Accounting Software Section */}
            <Container className="pt-5">
                <Row className="align-items-center justify-content-around">
                    <Col xs={12} sm={12} md={4} className='label-col col-stack-400'>
                        <div className="seamless">Accounting software</div>
                        <div className="subtitleIntegrate">Accounting software</div>
                    </Col>
                    <Col xs={6} sm={6} md={4} className='col-stack-400'>
                        <div className='colData'>
                            <div className="branch-top-content">
                                <Image className="branch-image pt-3 pb-3" src={xero} style={{width: 100, height: 100}}/>
                            </div>
                            <div className="branch-content">
                                <div className="colTitle sub-branch-content">Xero</div>
                                <div className="sub-branch-content align-items-end">
                                    <a className="linkdetailCol" href="/journaler/xero-integration">Learn more</a>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} sm={6} md={4} className='col-stack-400'>
                        <div className="colData">
                            <div className="branch-top-content">
                                <Image className="branch-image" src={quickbooks} style={{width: 111}}/>
                            </div>
                            <div className="branch-content">
                                <div className="colTitle sub-branch-content">QuickBooks</div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            {/* Exchange Section */}
            <Container className="pt-5">
                <Row className="align-items-center justify-content-around">
                    <Col xs={12} sm={12} md={4} className='label-col col-stack-400'>
                        <div className="seamless">Exchange</div>
                        <div className="subtitleIntegrate">Import wallet transaction data via exchange platforms</div>
                    </Col>
                    <Col xs={6} sm={6} md={4} className='col-stack-400'>
                        <div className="colData">
                            <div className="branch-top-content">
                                <Image className="branch-image" src={binance} style={{width: 112}}/>
                            </div>
                            <div className="branch-content">
                                <div className="colTitle sub-branch-content">Binance</div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} sm={6} md={4} className='col-stack-400'>
                        <div className="colData">
                            <div className="branch-top-content">
                                <Image className="branch-image" src={coinbase} style={{width: 103}}/>
                            </div>
                            <div className="branch-content">
                                <div className="colTitle sub-branch-content">Coinbase</div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            {/* NFT Section */}
            <Container className="pt-5 pb-5">
                <Row className="align-items-center justify-content-around">
                    <Col xs={12} sm={12} md={4} className='label-col col-stack-400'>
                    <div className="seamless">NFT</div>
                        <div className="subtitleIntegrate">Integrate with digital asset and wallet platforms</div>
                    </Col>
                    <Col xs={6} sm={6} md={4} className='col-stack-400'>
                        <div className="colData">
                            <div className="branch-top-content">
                                <Image className="branch-image" src={fireblock} style={{width: 142}}/>
                            </div>
                            <div className="branch-content">
                                <div className="colTitle sub-branch-content">Fireblocks</div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} sm={6} md={4} className='col-stack-400'>
                        <div className="colData">
                            <div className="branch-top-content">
                                <Image className="branch-image" src={Safe} style={{width: 108}}/>
                            </div>
                            <div className="branch-content">
                                <div className="colTitle sub-branch-content">Gnosis</div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <ButtonGroup fdata={'Set up your '} gradientData={'Journaler integration'} ldata={'now'}/>
        </div>
    );

}

export default NewIntegration;
