import React from 'react';
const SignUpForm = () => {
    // Your HTML content as a string
    const htmlContent = `  <div id="mc_embed_shell">
    <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css">
    <style type="text/css">
        #mc_embed_signup {
            background-color: #fff;
            false;
            clear: left;
            font: 14px Helvetica, Arial, sans-serif;
            
        }
        .parent{
            display: inline-block;
            width: max-content;
        }

        .inline {
            display: inline-block;
            border: 1px solid red;
            margin: 10px;
        }

        .child {
            height: 45px;
            display: inline-block;
            margin-right: 23px;
            vertical-align: middle;
        }

        .text {
            max-height: 100%;
            height: 45px;
            padding: 14px 16px;
            border-radius: 4px;
            border: 1px solid var(--Light-grey, rgba(222, 222, 222, 0.80));
            background: #FFF;
            font-size:16px
        }

        .buttonSubmit {
            border: unset !important;
            margin:0 !important;
            width: 100px !important;
            max-height: 100%;
            height: 45px;
            background: #0B4875;
            color: #fff;
            border-radius: 4px;
            font-size: 16px;
            font-weight:bold;
           
        }

        .buttonSubmit:hover { 
            background-color: rgba(11, 72, 117, 0.80);
        }

        /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
        We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
    </style>
    <div id="mc_embed_signup">
        <form
        class="form"
            action="https://aemalgorithm.us1.list-manage.com/subscribe/post?u=e4aa9421fa89f722e6810ea12&amp;id=0a9ad69528&amp;f_id=00ee6ee2f0"
            method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate"
            target="_blank">
            <div id="mc_embed_signup_scroll">
                <div class="parent">
                    <div class="child">
                        <input placeholder="First Name" type="text" name="FNAME" class="text" id="mce-FNAME"
                            value="">
                    </div>
                    <div class="child">
                        <input placeholder="Last Name" type="text" name="LNAME" class="text" id="mce-LNAME"
                            value="">
                    </div>
                    <div class="child">
                        <input placeholder="Email Address" type="email" name="EMAIL" class="required text"
                            id="mce-EMAIL" value="" required="">
                    </div>
                    <div class="child">
                        <input type="submit" name="subscribe" id="mc-embedded-subscribe" class="buttonSubmit"
                            value="Submit">
                    </div>
                    <div hidden="">
                        <input type="hidden" name="tags" value="3204062">
                    </div>
                    <div id="mce-responses" class="clear">
                        <div class="response" id="mce-error-response" style="display: none;"></div>
                        <div class="response" id="mce-success-response" style="display: none;"></div>
                    </div>
                    
                </div>
            </div>
        </form>
    </div>
    <script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script>
    <script
        type="text/javascript">(function ($) { window.fnames = new Array(); window.ftypes = new Array(); 
            fnames[0] = 'EMAIL'; ftypes[0] = 'email'; 
            fnames[1] = 'FNAME'; ftypes[1] = 'text'; 
            fnames[2] = 'LNAME'; ftypes[2] = 'text'; 
            fnames[4] = 'PHONE'; ftypes[4] = 'phone'; 
            fnames[5] = 'BIRTHDAY'; ftypes[5] = 'birthday'; 
            fnames[6] = 'MMERGE6'; ftypes[6] = 'address'; }(jQuery)); var $mcj = jQuery.noConflict(true);</script>
</div>`;
    return (
        <div className='container'>
            <div className='d-flex justify-content-center' >
                <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
            </div>
        </div>
    );
}

export default SignUpForm;