import React, { useState } from 'react';
import journaler_computer from '../../../assets/home/journaler_computer.png';
import quote_journaler from '../../../assets/home/quote_journaler.svg';
import AEMlogo from '../../../assets/home/simplify/AEMlogo.png';
import journaler from '../../../assets/home/simplify/Journaler.png';
import imageMobile from '../../../assets/home/simplify/imageMobile.png';
import '../Home.css';

const Simplify = ({ chooseWallet }) => {
  const [mobileWallet, setMobileWallet] = useState(false);

  const onChangeMobile = (item) => {
    chooseWallet(item)
    setMobileWallet(item)
  }

  return (
    <div className='container'>
      <div className='d-flex justify-content-center' style={{
        padding: '28px 0px'
      }}>
        <h1 className='simplify-header'>Simplify crypto tracking & reconciliation</h1>
      </div>
      <div className="simplify-btn-group" style={{
        paddingTop: '28px'
      }}>
        <div
          onMouseOver={() => onChangeMobile(false)}
          className={mobileWallet ? 'btnSimplify' : 'clickbBtnSimplify'}
        >
          Crypto Accounting Solution
        </div>
        <div
          onMouseOver={() => onChangeMobile(true)}
          className={!mobileWallet ? 'btnSimplify' : 'clickbBtnSimplify'}
        >
          Multi-Chain Mobile Wallet
        </div>
      </div>

      {!mobileWallet ? (
        <>
          <div class="d-flex justify-content-center pt-5">
            <img src={journaler} width={279} height={80} />
          </div>
          <div class="d-flex justify-content-center pt-5">
            <img className='img-fluid' src={journaler_computer} />
          </div>
          <div class="d-flex justify-content-center pt-5 pb-5">
            <img className='img-fluid' src={quote_journaler} />
          </div>
        </>
      ) :
        (
          <>
            <div class="d-flex justify-content-center pt-5">
              <img src={AEMlogo} width={186} height={66} />
            </div>
            <div class="d-flex justify-content-center">
              <img className='img-fluid' src={imageMobile} />
            </div>
          </>
        )
      }

      <div className='d-flex justify-content-center pb-5'>
        <button
          className='btnLearnMore'
          onClick={(e) => {
            e.preventDefault();
            window.location.href = !mobileWallet ? '/journaler/mrJournaler' : '/aem-plus';
          }}
        >
          {!mobileWallet ? 'Mint Mr. Journaler now' : 'Learn More'}
        </button>
      </div>
    </div >
  );
};

export default Simplify;
