import React from 'react';
import { FaCheck } from 'react-icons/fa';

import { IconContext } from 'react-icons/lib';

const jounalerHelpData = {
  title: 'Tired of wasting time wrangling spreadsheets?',
  subtitle: 'Journaler can help',
  contents: [
    'Track and reconcile your crypto transactions',
    'Calculate and report crypto tax liabilities, in compliance with the ATO, via Xero',
    'Generate fast and easy CSV exports straight from the blockchain',
    'Add and view your wallets, supporting NEM, Bitcoin and Ethereum',
  ],
};

const ListContents = ({ contents }) => (
  <ul className='list justify-content-left'>
    {contents.map((content, index) => (
      <li key={index} className= 'lijounalerHelp'>
        <FaCheck />
        {content}
      </li>
    ))}
  </ul>
);

const JounalerHelp = () => {
  return (
    <IconContext.Provider value={{ color: '#3081aa', size: 20, style: { marginRight: '8px' } }}>
      <div className='bg-light helpWrapper'>
        <h2 className='jounalerHelpData text-center title pb-3'>{jounalerHelpData.title}</h2>

        <div className='contentContainer'>
          <div className='subTitleDiv'>
            <p className='subTitle'>{jounalerHelpData.subtitle}</p>
          </div>

          <div>
            <ListContents contents={jounalerHelpData.contents} />
          </div>
        </div>
      </div>
    </IconContext.Provider>
  );
};

export default JounalerHelp;
