// styles
import "./style.css";

// modules
import { faPlay, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

// components
import { currencies } from "./data";

// custom list
const CurrencyList = ({ img, label }) => (
  <div className="card py-2 border-0 currencyCard my-3 token-circle">
    <div className="d-flex justify-content-center align-self-center">
      <img src={img} alt={label} height='85px' className="pt-3" />
    </div>
    <div className="card-body-currency">
      <p className="currencyLabel text-center token-label">{label}</p>
    </div>
  </div>
);

const SupportedCurrencies = () => {
  // state declarations
  const [wordList, setWordlist] = useState([]);
  const [cryptoList, setCryptoList] = useState([]);
  const [cryptoListOriginal, setCryptoListOriginal] = useState([]);
  const [selectedWord, setSelectedWord] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // search functions
  const handleSearch = () => {
    var crypto = document.getElementById("crypto").value;
    if (crypto) {
      const lowercasedValue = crypto.toLowerCase();
      let filteredData = cryptoListOriginal.filter((crypto) => {
        return (
          crypto.name.toLowerCase().includes(lowercasedValue) ||
          crypto.value.toLowerCase().includes(lowercasedValue)
        );
      });
      setCryptoList(filteredData);
    } else {
      setCryptoList(cryptoListOriginal);
    }
  };

  const handleSelectChange = (word) => {
    if (word) {
      if (word === selectedWord) {
        setSelectedWord(null)
        setCryptoList(cryptoListOriginal);
      } else {
        setSelectedWord(word)
        const filterInput = word.value;
        let filteredData = cryptoListOriginal.filter((crypto) => {
          return (
            crypto.value.slice(0, 1).toLowerCase().includes(filterInput)
          );
        });
        setCryptoList(filteredData);
      }
    }
  };

  const handleOpenDropDown = () => {
    setIsDropdownOpen((prevIsOpen) => !prevIsOpen);
  };

  const generateFullAlphabet = () => {
    const startChar = 'A';
    const endChar = 'Z';

    const alphabetRange = Array.from({ length: endChar.charCodeAt(0) - startChar.charCodeAt(0) + 1 }, (_, index) => {
      const charCode = startChar.charCodeAt(0) + index;
      const char = String.fromCharCode(charCode);
      return {
        id: char.toLocaleLowerCase(),
        value: char.toLocaleLowerCase(),
        title: char,
      };
    });

    setWordlist(alphabetRange);
  };

  // useEffect
  React.useEffect(() => {
    const getBlockchainSupportedTokens = async () => {
      fetch('https://journaler.aem.ac/api/blockchain-supported-token')
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          const cryptoList = data.filter(token => token.isActive);
          setCryptoList(cryptoList);
          setCryptoListOriginal(cryptoList);
        })
        .catch(error => {
          const cryptoList = currencies.filter(token => token.isActive);
          setCryptoList(cryptoList);
          setCryptoListOriginal(cryptoList);
        });
    };
    getBlockchainSupportedTokens();
    generateFullAlphabet();
  }, []);

  return (
    <>
      <div className="spcurrencies">
        <div className="SupportedCurHeader text-center">
          <div className="container">
            <h5 className="pricingSc">
              <FontAwesomeIcon icon={faSearch} /> BLOCKCHAIN WALLETS
            </h5>
            <h1 className="globalHeaderTitleSc pb-4">Supported Currencies</h1>
            <p className="pricingSc">
              Search our catalogue of Blockchain Wallet supported currencies.
              More coming soon…
            </p>
            <p className="pricingSc">
              Our <strong>Exchange Wallets</strong> support 20,000 currencies!
            </p>
            <div className="inputContainer">
              <input
                className="col-md-5 col-sm-12 searchCrypto"
                placeholder="Search"
                onChange={handleSearch}
                id="crypto"
                name="crypto"
                type="text"
              />
              <FontAwesomeIcon icon={faPlay} className={`${isDropdownOpen ? 'filter' : 'filter-open'}`} onClick={handleOpenDropDown} />
            </div>
            <div className="custom-dropdown">
              <div className={`${isDropdownOpen ? 'dropdown-list-open' : 'dropdown-list'}`}>
                {wordList.map((word) => (
                  <label key={word.id} className="dropdown-item">
                    <input
                      type="radio"
                      name="selectedWord"
                      className="checkbox"
                      value={word.value}
                      checked={selectedWord && selectedWord.id === word.id}
                      onClick={() => handleSelectChange(word)}
                    />
                    <span>{word.title}</span>
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div
          className="container"
          style={{ marginTop: "-80px", paddingBottom: "50px" }}
        >
          <div className="row row-cols-md-4 row-cols-sm-1">
            {cryptoList.map((currencies) => (
              <div className="col-6 col-md-6 col-lg-4 col-xl-3">
                <CurrencyList img={currencies.img} label={currencies.value} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SupportedCurrencies;
