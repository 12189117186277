import React from 'react'
import image1 from '../../assets/articles/GetStart/image1.png'
import ArticleHeader from './ArticleHeader'
import ChooseArticle from '../help/journaler/components/Body'
import './Articles.css'

const GetStart = () => {
  return (
    <>
      <ArticleHeader />

      <div className='container'>
        <div className='articleContainer'>
          <div className='border-bottom text-center'>
            <h3><strong>Getting Started</strong></h3>
            <p style={{ color: '#707070' }}>2021.05.17</p>
          </div>
          <div className='pt-5'>
            <p className='text-justify'>Welcome to AEM Journaler Crypto Champions!</p>
            <p className='text-justify'>Welcome to your new frustration free, automated crypto accounting solution! Your days of wasting time over manually managing your crypto transactions are over. Follow these easy steps to get started.</p>

            <h4 className='pt-3'><strong>Step 1 Register for a Journler Account</strong></h4>
            <p className='text-justify'>Go to <a href='https://journaler.aem.ac/user/register' rel='noreferrer' target='_blank'>https://journaler.aem.ac/user/register</a> and enter in your details to create your new Journaler account.</p>
            <p className='text-justify'>If you have a Xero account and would like to sign up with Xero, please <a href='https://journaler.aem.ac/' rel='noreferrer' target='_blank'>click here</a>.</p>

            <h4 className='pt-3'><strong>Step 2 Add Your Wallets</strong></h4>
            <img src={image1} className='py-1 articleImg' alt='wallet page demo' />
            <p className='text-justify'>When you sign in for the first time, you will be taken to your <strong>Wallets Page</strong>.</p>
            <p className='text-justify'>By clicking the <strong>Add Wallet button</strong>, found on the <strong>top right</strong> you can select to add either a <strong>Bitcoin Ethereum, NEM, USDT</strong> or <strong>XRP</strong> wallet. <a href='/journaler/help/articles/how_to_add_a_wallet' rel='noreferrer'>Learn more about how to add a wallet</a></p>

            <div className='tipContainer'>
              <h4 className='pt-3'><strong>Need help?</strong></h4>
              <p className='text-justify'>We’re always here to answer any questions you may have. Reach out to us via email at <strong>support@aemalgorithm.io</strong></p>
            </div>

          </div>
        </div>
      </div>

      <ChooseArticle />
    </>
  )
}

export default GetStart
