import React, { Component } from 'react';
import Header from './components/Header'
import FeatureSection from './components/FeatureSection'
import FinancialReporting from './components/FinancialReporting'
import WalletGroup from './components/WalletGroup';
import Simplify from './components/Simplify'
import './style.css';

import { header, secondPart, lastPart } from './components/data';
import FindOutMore from '../../home/components/FindOutMore';
class Accountants extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Header content={header.accountant} content_s={secondPart.accountant} />

        <FeatureSection content={secondPart.accountant} />

        <WalletGroup />

        <FinancialReporting />

        <Simplify content={lastPart.accountant} />

        <div className='findOutMoreContainer'>
          <FindOutMore />
        </div>
      </div>
    );
  };
}

export default Accountants;
