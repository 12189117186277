import React from 'react';
import '../style.css'
import import_export from '../../../../assets/journaler/solutions/Blogimage3.png';
const FeatureSection = (props) => (
  <div className='featureCon'>
    <div className='row'>
      <div className='col-12 col-sm-5 d-flex justify-content-center justify-content-lg-end justify-content-xl-end justify-content-md-end align-items-center'>
        <img src={import_export} className='img-journaler' />
      </div>
      <div className='col-12 col-sm-7 pt-3 pt-md-5 pt-lg-5 pt-xl-5 pb-3 pb-md-5 pb-lg-5 pb-xl-5'>
        <div className='titleSectionMid'>
          {props.content.header1}<span className='titleSectionMidGradient'> {props.content.gradient}</span> {props.content.header2}
        </div>
        <div className='subtitletitleSectionMid pt-3'>
          {props.content.des}
        </div>

      </div>
    </div>
  </div>
)

export default FeatureSection