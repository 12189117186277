import React, { useEffect } from 'react';
import '../Home.css';

import ausindustry from '../../../assets/home/ourNetWork/ausindustry.png';
import Cointelegraph from '../../../assets/home/ourNetWork/Cointelegraph.png';
import cyber from '../../../assets/home/ourNetWork/cyber.png';
import hk from '../../../assets/home/ourNetWork/hk.png';
import uk from '../../../assets/home/ourNetWork/uk.png';
import rmit from '../../../assets/home/ourNetWork/rmit.png';
import stone from '../../../assets/home/ourNetWork/stone.png';
import sys from '../../../assets/home/ourNetWork/sys.png';
import bric from '../../../assets/home/ourNetWork/bric.png';
import AWS from '../../../assets/home/ourNetWork/AWS.png';
import Bookkeepers from '../../../assets/home/ourNetWork/Bookkeepers.png';
import CA from '../../../assets/home/ourNetWork/CA.png';
import CPA from '../../../assets/home/ourNetWork/CPA.png';
import Google from '../../../assets/home/ourNetWork/Google.png';
import IPA from '../../../assets/home/ourNetWork/IPA.png';


const contents = [
  {
    src: Cointelegraph,
    alt: 'Cointelegraph',
    id: '1'
  },
  {
    src: hk,
    alt: 'hk',
    id: '2'
  },
  {
    src: cyber,
    alt: 'cyber',
    id: '3'
  },
  {
    src: rmit,
    alt: 'rmit',
    id: '4'
  },
  {
    src: stone,
    alt: 'stone',
    id: '5'
  },
  {
    src: bric,
    alt: 'bric',
    id: '6'
  },
  {
    src: ausindustry,
    alt: 'ausindustry',
  },
  {
    src: sys,
    alt: 'sys',
  },
  {
    src: uk,
    alt: 'uk',
  },
  {
    src: AWS,
    alt: 'AWS',
  },
  {
    src: Bookkeepers,
    alt: 'Bookkeepers',
  },
  {
    src: CA,
    alt: 'CA',
  },
  {
    src: CPA,
    alt: 'CPA',
  },
  {
    src: Google,
    alt: 'Google',
  },
  {
    src: IPA,
    alt: 'IPA',
  },
];

const IntegrationCard = ({ img, alt }) => (
  <img src={img} alt={alt} className='logoImageOur' />
);

const OurNetWork = () => {
  useEffect(() => {
    const scrollers = document.querySelectorAll(".scroller");
    scrollers.forEach((scroller) => {
      scroller.setAttribute("data-animated", true);
      const scrollerInner = scroller.querySelector(".scroller__inner");
      const scrollerContent = Array.from(scrollerInner.children);
      scrollerContent.forEach((item) => {
        const duplicatedItem = item.cloneNode(true);
        duplicatedItem.setAttribute("aria-hidden", true);
        scrollerInner.appendChild(duplicatedItem);
      });
    });
  }, []);

  return (
    <div className="scroller">
      <div className="scroller__inner">
        {contents.map((item, index) => (
          <IntegrationCard key={index} img={item.src} alt={item.alt} />
        ))}
      </div>
    </div>
  );
};

export default OurNetWork;
