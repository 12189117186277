import React, { Component } from 'react';
import FeatureImg from '../../../../assets/aemPlus/Cryptocurrency Transactions.svg'

import '../Main.css';

const features = {
  title: 'Designed for crypto transaction management on the go',
  contents: [
    {
      titleColor: '#3081AA',
      subtitle: 'Store various wallets in an app',
      des: 'A multi-chain mobile wallet which has been designed to store all your crypto wallets in one place, the palm of your hand'
    },
    {
      titleColor: '#6D69FB',
      subtitle: 'Enables Invoicing',
      des: 'Send and receive crypto payments on the go, anytime, anywhere'
    },
    {
      titleColor: '#0F4B73',
      subtitle: 'Export',
      des: 'Export invoice and transaction data for easy and fast recording'
    },
    {
      titleColor: '#14B4C1',
      subtitle: 'Swaps',
      des: 'Move funds between wallets and swap on the go'
    },
    {
      titleColor: '#2F8BF7',
      subtitle: 'AEM+ your handy sidekick',
      des: 'AEM+ was created for both B2B and C2B use, and works as a companion app for Journaler, and works seamlessly together'
    },
  ]
};

const FeatureList = ({ subtitle, des, titleColor }) => (
  <div className='pb-3'>
    <h5 style={{ color: 'titleColor' }}>{subtitle}</h5>
    <p>{des}</p>
  </div>
)

class Feature extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <div style={{marginTop:'200px'}} className='container pb-5'>
          <h5 className='text-center pricing pt-5'>NEW FEATURES</h5>
          <h2 className='text-center title pb-5 pt-3'>Designed for crypto transaction<br/>management on the go</h2>
          <div className='row align-items-center'>
            <div className='col-md-6 col-sm-12 justify-content-end'>
              <img src={FeatureImg} alt='features' className='featureImg' />
            </div>
            <div className='col-md-6 col-sm-12 featureContent'>
              {/* {features.contents.map((feature) => (
                <FeatureList subtitle={feature.subtitle} des={feature.des} titleColor={feature.titleColor}/>
              ))} */}
              <div className='pb-3'>
                <h5 style={{ color: '#3081AA' }}>Store all your wallets in one simple app</h5>
                <p>A multi-chain mobile wallet which has been designed to store all your crypto wallets in one place, the palm of your hand.</p>
              </div>
              <div className='pb-3'>
                <h5 style={{ color: '#6D69FB' }}>Enables invoicing</h5>
                <p>Send and receive crypto payments on the go, anytime, anywhere.</p>
              </div>
              <div className='pb-3'>
                <h5 style={{ color: '#0F4B73' }}>Export</h5>
                <p>Export invoice and transaction data for easy and fast recording.</p>
              </div>
              <div className='pb-3'>
                <h5 style={{ color: '#14B4C1' }}>Swaps</h5>
                <p>Move funds between wallets and swap on the go.</p>
              </div>
              <div className='pb-3'>
                <h5 style={{ color: '#2F8BF7' }}>AEM+ your handy sidekick</h5>
                <p>AEM+ was created for both B2B and C2B use, and works as a companion app for Journaler, and works seamlessly together.</p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Feature;
