import React from 'react';
import contents from './content';
import './style.css';

const ContentWrapper = ({ title, detail }) => (
  <div className='ContentWrapper'>
    <h5 className='pl-4 pb-2'>{title}</h5>
    <ul className='w-100 p-3'>
      {detail.map((detail, index) => (
        <li key={index} className='h-100 d-inline-block text-justify pt-1 pb-1'>
          {detail}
        </li>
      ))}
    </ul>
  </div>
);

const JournalerPrivacyPolicy = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className='policyPageWrapper'>
        <div className='row justify-content-center'>
          <h1 className='titleBorder'>PRIVACY POLICY</h1>
        </div>

        <div className='container'>
          <h4 className='pt-5 pb-4'>AEM Technologies Pty Ltd</h4>
          {contents.map((contents, index) => (
            <ContentWrapper key={index} title={contents.title} detail={contents.detail} />
          ))}
          <p>6th April 2018</p>
        </div>
      </div>
    </>
  );
};

export default JournalerPrivacyPolicy;
