import React from 'react';
import '../style.css'
import tick from '../../../../assets/journaler/solutions/CheckCircle.png'
import imgOne from '../../../../assets/journaler/solutions/iconOne.png'
import imgTwo from '../../../../assets/journaler/solutions/iconTwo.png'
import imgThree from '../../../../assets/journaler/solutions/iconThree.png'

const content = [
  {
    img: imgOne,
    title: 'Accounting Integration',
    des: [
      'Update general ledger',
      'Update P&L and BS',
      'Correctly track bank accounts',
    ],
  },
  {
    img: imgTwo,
    title: 'Reconciliation',
    des: [
      'FIAT Conversation and Transaction Feeds',
      'Reconciliation',
      'Network and trading fees',
      'Accounts Receivable (Invoices)',
      'Accounts Payable (Bills)'
    ],
  },
  {
    img: imgThree,
    title: 'Journals',
    des: [
      'Manual and reoccurring journals',
      'CGT - FIFO / LIFO Calculations for Wallets',
      'Wallet Balance Tracking'
    ]
  }
]

const ReportingFeature = (props) => (
  <div className='col-md-4 col-sm-12 pt-3 pt-md-5 pt-lg-5 pt-xl-5'>
    <div className='d-flex justify-content-left'>
      <img className='img-fluid' src={props.img} alt={props.title} /><br />
    </div>
    <div className='header-report'>
      <div className='titleReport pt-3 pb-3'>
        {props.title}
      </div>
      {props.des.map((des) => (
        <div className='row pt-1 '>
          <div className='col col-1'>
            <img src={tick} alt='tick' />
          </div>
          <div className='col subReport'>
            <p>{des}</p>
          </div>
        </div>
      ))}
    </div>

  </div>
)

const FinancialReporting = () => (
  <div className='bgcolor-dark financialReportingSectionContainer pt-3 pt-md-5 pt-lg-5 pt-xl-5 pb-3'>
    <div className='container'>
      <h1 className='text-center titleFinancial'>Financial Reporting</h1>
      <div className='row'>
        {
          content.map((content) => (
            <ReportingFeature
              img={content.img}
              title={content.title}
              des={content.des}
            />
          ))
        }
      </div>
    </div>
  </div>
)

export default FinancialReporting