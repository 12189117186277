import React from 'react';
import '../style.css'
import CheckCircle from '../../../../assets/journaler/solutions/CheckCircle.png'
import JournalerWalletPage from '../../../../assets/journaler/solutions/wallet page demo.png'



const FeatureContentList = ({ content }) => (
  <div className='row' >
    <div className='col col-1 pr-1'>
      <img src={CheckCircle} alt='tick' />
    </div>
    <div className='col content-sub pl-3'>
      <p>{content}</p>
    </div>
  </div>
)


const WalletConnectionMobile = (props) => {
  return (
    <div className='row pl-3 pt-3 pr-3 pb-3'>
      <div className='col-12 d-flex justify-content-center'>
        <img className='img-fluid  image-wallet' src={props.image} alt="Responsive image" />
      </div>
      <div className='col-12 '>
        <div className='header-report'>
          {props.section == 1 ? (
            <p className='titleMidHeader pb-1 pt-3'>{props.content.header}
              <span className='titleMidHeaderGradient'> {props.content.headerGradient}</span>
            </p>
          ) : (
            <p className='titleMidHeader pb-1 pt-3'>{props.content.headerGradient}
              <span className='titleMidHeaderGradient'> {props.content.header}</span>
            </p>
          )}

          <div>
            {props.content.content.map((content) => (
              <FeatureContentList content={content} />
            ))}
          </div>

        </div>
      </div>

    </div>
  )
}

export default WalletConnectionMobile