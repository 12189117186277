import React, { Component } from 'react';
import Header from './components/Header'
import FeatureSection from './components/FeatureSection'
import FinancialReporting from './components/FinancialReporting'
import WalletGroup from './components/WalletGroup'
import './style.css'
import { header, secondPart, lastPart } from './components/data'

import FindOutMore from '../../home/components/FindOutMore';
import Simplify from './components/Simplify';
class BusinessOwners extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Header content={header.businessOwner} content_s={secondPart.businessOwner} />

        <FeatureSection content={secondPart.businessOwner} />

        <WalletGroup />

        <FinancialReporting />
        <Simplify content={lastPart.businessOwner} />


        <div className='findOutMoreContainer'>
          <FindOutMore />
        </div>

      </div>
    );
  };
}

export default BusinessOwners;
