import React from 'react'
import SideBar from '../../assets/articles/AddWallet/sideBar.png'
import wallet1 from '../../assets/articles/AddWallet/wallet1.png'
import selector from '../../assets/articles/AddWallet/selector.png'
import wallet2 from '../../assets/articles/AddWallet/wallet2.png'

import ArticleHeader from './ArticleHeader'
import ChooseArticle from '../help/journaler/components/Body'
import './Articles.css'

const GetStart = () => {
  return (
    <>
      <ArticleHeader />

      <div className='container'>
        <div className='articleContainer'>
          <div className='border-bottom text-center'>
            <h3><strong>How to Add a Wallet</strong></h3>
            <p style={{ color: '#707070' }}>2021.05.17</p>
          </div>
          <div className='pt-5'>
            <p className='text-justify'>This is where it all begins. Once you have added your crypto wallets to Journaler, then you can keep easy track of your transactions and export any data you need over to your accounting system.</p>

            <h4 className='pt-3'><strong>Step 1</strong></h4>
            <p className='text-justify'>Navigate to the Wallets page, from the menu.</p>
            <img src={SideBar} className='py-1 articleImg' alt='side bar demo' />

            <h4 className='pt-3'><strong>Step 2</strong></h4>
            <p className='text-justify'>Once on the Wallets page, click on the Add Wallet button found at the top right of the page. From the drop down menu, select the type of crypto wallet you want to add, eg: Bitcoin or Ethereum.</p>
            <img src={wallet1} className='py-1 articleImg' alt='wallet page demo' />
            <img src={selector} className='py-1 articleImg' alt='selector demo' />

            <h4 className='pt-3'><strong>Step 3</strong></h4>
            <p className='text-justify'>Then simply add your <strong>Wallet Address</strong> and <strong>Wallet Name</strong>, click <strong>Ok</strong>.</p>
            <p className='text-justify'>And your first wallet has been added. Repeat these steps as many times as needed, for all the wallets you want to manage.</p>
            <img src={wallet2} className='py-1 articleImg' alt='wallet page demo' />

          </div>
        </div>
      </div>

      <ChooseArticle />
    </>
  )
}

export default GetStart
