import React, { Component } from 'react';
import '../JournalerHelp.css';

const articles = [
  // {
  //   topic: 'Integration',
  //   title: 'How to get started with your Xero integration',
  //   path: '/journaler/help/articles/StartXeroIntegration',
  // },
  {
    topic: 'Using Journaler',
    title: 'Getting Started',
    path: '/journaler/help/articles/getting_started',
  },
  {
    topic: 'Using Journaler',
    title: 'How to Add a Wallet',
    path: '/journaler/help/articles/how_to_add_a_wallet',
  },
  {
    topic: 'Integration',
    title: 'How to get started with your Xero integration',
    path: '/journaler/help/articles/ConfigIntegration',
  },
  {
    topic: 'Using Journaler',
    title: '4 Steps to Troubleshooting Journaler',
    path: '/journaler/help/articles/four_steps_to_troubleshooting_journaler',
  },
];

const ArticleList = ({ topic, title, path }) => (
  <div className='row listItem'>
    <strong className='col-md-3 col-sm-12 align-self-center pl-4 articleTopic'>{topic}</strong>
    <a href={path} rel='noreferrer' className='align-self-center pl-4' style={{ color: 'black' }}>
      {title}
    </a>
  </div>
);

class Body extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: articles,
      filteredData: articles,
      selectTopic: 'Top Articles'
    };
  }

  handleFilter = (e) => {
    const lowercasedValue = e.target.value.toLowerCase();
    this.setState({ selectTopic: e.target.value });

    e.target.value === 'Top Articles' ? this.setState({ filteredData: articles }) :
      this.setState((originalData) => {
        const filteredData = originalData.data.filter(
          (value) =>
            value.topic.toLowerCase().includes(lowercasedValue) || value.title.toLowerCase().includes(lowercasedValue)
        );
        return { filteredData };
      });
  }

  handleSearch = (e) => {
    const { value } = e.target;
    const lowercasedValue = value.toLowerCase();

    this.setState((originalData) => {
      const filteredData = originalData.data.filter(
        (value) =>
          value.topic.toLowerCase().includes(lowercasedValue) || value.title.toLowerCase().includes(lowercasedValue)
      );
      return { filteredData };
    });
  };

  render() {
    return (
      <div className='container'>
        <div className='container-fluid bodyContainer'>
          <div className='row py-5 d-flex'>
            <h2 className='col mr-auto p-2 bodyTitle'>Top Articles</h2>
            <input
              className='col-md-4 col-sm-12 form-control mr-sm-2 align-self-center mb-1 searchInput'
              placeholder='Search'
              onChange={this.handleSearch}
            />
            <select className='col-md-3 col-sm-12 py-2 selector align-self-center mb-1' value={this.state.selectTopic} onChange={this.handleFilter}>
              {/* <select className='form-select selector align-self-center' defaultValue='integration'> */}
              <option selected value='Top Articles'>Top Articles</option>
              <option value='Using Journaler'>Using Journaler</option>
              <option value='Integration'>Integration</option>
              <option value='Manage My Account'>Manage My Account</option>
              <option value='Subscription'>Subscription</option>
              <option value='Privacy and Security'>Privacy and Security</option>
            </select>
          </div>

          <div className='listContainer'>
            {this.state.filteredData.map((articles) => (
              <div key={articles.title} className='col'>
                <ArticleList topic={articles.topic} title={articles.title} path={articles.path} />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Body;
