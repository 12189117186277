import React, { Component } from 'react';
import AemLogoWhite from '../../../assets/aemPlus/AEM+Logo_white.svg';
import AppStore from '../../../assets/aemPlus/appstore.png';
import GooglePlay from '../../../assets/aemPlus/googleplay.png';
import img_transaction from '../../../assets/aemPlus/img_transaction2.png';

import { Helmet } from 'react-helmet';
import './Main.css';
import AemPlusDemo from './component/AemPlusDemo';
import Feature from './component/Features';
import Integrations from './component/Integrations';
import SendAndRecieve from './component/SendAndRecieve';

const aemDesContents = {
  title: 'Your Multi Cryptocurrency Wallet',
  desc: 'A multi-chain mobile wallet, manage your crypto transactions on any device, anywhere, anytime!',
  sectionTwoTitle: 'Seamless Transaction Between Wallets',
  sectionTwoDes: 'Send, receive and authorise'
};

class AemPlusMain extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  screenWidth = (width) => {
    return window.screen.width > width;
  }

  render() {
    return (
      <>
        <Helmet>
          <title>AEM Algorithm | Multi Cryptocurrency Wallet App</title>
          <meta name="AEM plus" content="The mobile crypto wallet that 
                  lets you control your digital assets in the palm of your hand – AEM+. 
                  Supporting BTC, BSB, ETH, XYM, NEM, ASTR + more. Download AEM+ today." />
        </Helmet>
        <div className='container-fluid  aemplusMainHeader'>
          <div className='desktopMain container'>
            <div className='row justify-content-center' id='downloadAEMplus'>
              <div className='col-lg-6 col-md-6 col-sm-12 aemIconDiv justify-content-center'>
                <img className='imageAemplus' src={img_transaction} alt='aem app' />
              </div>
              <div className='col-lg-6 col-md-6 col-sm-12'>
                <div>
                  <img src={AemLogoWhite} alt='aem logo' height='130px' className='aemWhiteLogo' />
                </div>
                <h2 className='titlePlus'>{aemDesContents.title}</h2>
                <p className='subPlus my-4 text-align-left'>{aemDesContents.desc}</p>
                <div className='justify-content-left'>
                  <a href='https://apps.apple.com/au/app/aem/id1607457067' rel='noreferrer' target='_blank'>
                    <img src={AppStore} alt='app store' height='60px' className='mr-4 mt-2' />
                  </a>
                  <a href='https://play.google.com/store/apps/details?id=com.aem.aemplus' rel='noreferrer' target='_blank'>
                    <img src={GooglePlay} alt='google play' height='60px' className='mt-2' />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className='mobileMain'>
            <div className='d-flex justify-content-center'>
              <img src={AemLogoWhite} alt='aem logo' height='130px' className='aemWhiteLogo' />
            </div>
            <h2 className='titlePlus'>{aemDesContents.title}</h2>
            <p className='d-flex subPlus my-4 text-align-center'>{aemDesContents.desc}</p>
            <div className='d-flex justify-content-center'>
              <a href='https://apps.apple.com/au/app/aem/id1607457067' rel='noreferrer' target='_blank'>
                <img src={AppStore} alt='app store' height='60px' className='mr-4 mt-2' />
              </a>
              <a href='https://play.google.com/store/apps/details?id=com.aem.aemplus' rel='noreferrer' target='_blank'>
                <img src={GooglePlay} alt='google play' height='60px' className='mt-2' />
              </a>
            </div>
          </div>
        </div>

        <AemPlusDemo />

        <Feature />

        <SendAndRecieve />

        <Integrations isMobile={!this.screenWidth(767)} />

      </>
    );
  }
}

export default AemPlusMain;
