import React, { Component } from 'react';
import './News.css'

import ImgOne from '../../assets/news/article1.svg'
import ImgTwo from '../../assets/news/Group 1230.svg'

const news = [
  {
    img: ImgOne,
    alt: '',
    title: 'AEM launches Journaler with support of RMIT!',
    des: 'AEM Algorithm has launched the new version of its Journaler package with the support of RMIT Blockchain Innovation Hub and School of Computer Science and IT. A pioneer…',
    date: '4 June 2021',
    link: '/news/aem_launches_journaler_with_support_of_rmit'
  },
  {
    img: ImgTwo,
    alt: '',
    title: 'Who are we?',
    des: 'The story of AEM Algorithm begins in early 2017, in the lead up to the Crypto boom of 2018. Jakub Sawczuk discovered the enormous lack of resources available for businesses to report and track cryptocurrency transactions…',
    date: '4 June 2021',
    link: '/news/who_are_we'
  },
]

const NewsCard = ({ img, alt, title, des, date, link }) => (
  <div className="card newsCard">
    {/* <div className="newsCardImg"> */}
      <img className="card-img-top" src={img} alt={alt} />
    {/* </div> */}
    <div className="card-body newsCardBody">
      <h5 className="font-weight-bold">{title}</h5>
      <p className="card-text">{des}</p>
      <p className="text-right" style={{ color: '#707070' }}>{date}</p>
    </div>
    <div class="card-footer text-center newsCardFooter">
      <a href={link} className="card-link" style={{ color: '#094674' }}>View Full Article</a>
    </div>
  </div>
)

class News extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className='globalHeaderBgS newsHeader text-center'>
          <div className='row'>
            <div className='col'>
              <h1 className='h1Bold'>News</h1>
            </div>
          </div>
        </div>

        <div className='container pb-5'>
          <div className='row row-cols-lg-3 row-cols-md-1 my-2 justify-content-center'>
            {news.map((news) => (
              <div key={news.alt} className='px-3 pb-3 card-group'>
              <NewsCard
                  img={news.img}
                  alt={news.alt}
                  title={news.title}
                  des={news.des}
                  date={news.date}
                  link={news.link} />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
}

export default News;
