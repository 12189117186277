import React from 'react';
import './styles.css';

import { FaCheck } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';

import XeroScreen from '../../../../../assets/xeroIntegration/xero_screenimage.svg';
import JounalerXero from '../../../../../assets/xeroIntegration/1.svg';

import TwoBtns from './TwoBtns';

const aboutXeroContents = {
  title: 'ABOUT XERO',
  subTitle: 'Xero is world-leading online accounting software built for small business.',
  xeroFeatures: [
    'Get a real-time view of your cashflow. Log in anytime, anywhere on your Mac, PC, tablet of phone to get a real-time view of your cash flow. It’s small business accounting software that’s simple, smart and occasionally magical. ',
    'Run your business on the go. Use our mobile app to reconcile, send invoices, or create expense claims - from anywhere.',
    ' Get paid faster with online invoicing. Send online invoices to your customers - and get updated when they’re opened.',
    ' Reconcile in seconds. Xero imports and categorises your latest bank transactions. Just click ok to reconcile.',
  ],
  howIntegration: {
    title: 'How Journaler integrates with Xero',
    contents: [
      {
        id: 1,
        title: 'Fast transactions',
        desc:
          'With AEM Journaler’s crypto accounting plugin for Xero you can sync crypto transactions to Xero in minutes',
      },
      {
        id: 2,
        title: 'Easy and effective',
        desc: 'Journaler brings all of your business’s accounting into one easy and effective place.',
      },
      {
        id: 3,
        title: 'Seamless accounting',
        desc:
          'Our Xero integration seamlessly combines your business’s traditional accounting transactions with your crypto trades',
      },
    ],
  },
};

const HowToIntegrateSection = (props) => (
  <div className='card border-0'>
    <div className='card-body text-center'>
      <h5 className='card-title card_title'>{props.title}</h5>
      <p className='card-text normal_14_font' style={{ height: 'fit-content' }}>{props.desc}</p>
    </div>
  </div>
);

const AboutXero = () => {
  return (
    <IconContext.Provider value={{ color: '#3081aa', size: 20 }}>
      <div className='container my-5'>
        <div className='aboutXeroDiv'>
          <div className='imgDiv'>
            <img src={XeroScreen} alt='xero screenshot' className='img' />
          </div>

          <div className='contentDiv'>
            <h2 className='title'>{aboutXeroContents.title}</h2>
            <p className='bold_14_font'>{aboutXeroContents.subTitle}</p>
            <ul className='list-group border-0'>
              {aboutXeroContents.xeroFeatures.map((item) => (
                <li className='list-group-item border-0 normal_14_font' key={item}>
                  <div className='row'>
                    <div className='col col-1'>
                      <FaCheck />
                    </div>
                    <div className='col colaboutXero' >{item}</div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <TwoBtns />

        {/* -------- imgage  */}
        <div className='row'>
          <div className='col'>
            <img src={JounalerXero} alt='jounaler screenshot' className='img' />
          </div>
        </div>
      </div>

      {/* -------- how  */}
      <div className='container text-center py-4'>
        <h2 className='text-center title pb-3 titlehowIntegration'> {aboutXeroContents.howIntegration.title}</h2>
        <div className='row'>
          {aboutXeroContents.howIntegration.contents.map((data) => (
            <div className='col' key={data.id}>
              <HowToIntegrateSection title={data.title} desc={data.desc} />
            </div>
          ))}
        </div>
      </div>
    </IconContext.Provider>
  );
};

export default AboutXero;
