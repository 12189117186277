import React from 'react';
import './styles.css';

const TwoBtns = () => (
  <div className='text-center pt-4'>
    <a
      href='https://journaler.aem.ac/'
      rel='noreferrer'
      target='_blank'
      className='btn btnGroup_blueBgBtn'
    >
      Get started with Journaler
    </a>
    <a
      href='https://www.xero.com/au/signup/?xtid=x30aemjournaler'
      rel='noreferrer'
      target='_blank'
      className='btn btnGroup_blueBorderBtn'
    >
      Try Xero for Free
    </a>
  </div>
);
export default TwoBtns;
