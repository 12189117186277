import React from 'react';
import '../Main.css';

const ButtonGroup = ({ fdata, gradientData, ldata }) => (
  <div className='start_journaler_bg text-center pb-5'>
    <div className='start_journaler pt-5 pb-5'> {fdata} <span className='solutionJounalerGradient'>{gradientData}</span> {ldata}</div>


    <button className='btnLearnMore' onClick={(e) => {
      e.preventDefault();
      window.location.href = 'https://journaler.aem.ac/';
    }}> Get started</button>
  </div>
);
export default ButtonGroup;
