import React from 'react';

// images:
import feature1 from '../../../../../assets/xeroIntegration/feature1.svg';
import feature2 from '../../../../../assets/xeroIntegration/feature2.svg';
import feature3 from '../../../../../assets/xeroIntegration/feature3.svg';
import feature4 from '../../../../../assets/xeroIntegration/feature4.svg';
import feature5 from '../../../../../assets/xeroIntegration/feature5.svg';
import feature6 from '../../../../../assets/xeroIntegration/feature6.svg';

const features = {
  title: 'See our features',
  contents: [
    {
      id: 1,
      img: feature1,
      alt: 'feature 1 image',
      desc: 'Integrated with Xero and puts all your accounting into one place',
    },
    {
      id: 2,
      img: feature2,
      alt: 'feature 1 image',
      desc: 'Reconcile your crypto transactions',
    },
    {
      id: 3,
      img: feature3,
      alt: 'feature 1 image',
      desc: 'Enables Invoicing (accounts payable) & Enables Billing (accounts receivable)',
    },
    {
      id: 4,
      img: feature4,
      alt: 'feature 1 image',
      desc: 'CSV exports',
    },
    {
      id: 5,
      img: feature5,
      alt: 'feature 1 image',
      desc: 'Live bank feed integration',
    },
    {
      id: 6,
      img: feature6,
      alt: 'feature 1 image',
      desc: 'Add and view your wallets',
    },
  ],
};

const Feature = ({ rowNum, img, alt, description }) => (
  <div className='card p-2 border-0' style={{ height: '18em' }}>
    <div style={{ width: '10rem', height: '10em' }} className='d-flex justify-content-center align-self-center'>
      <img src={img} className='card-img-top ' alt={alt} width='90px' />
    </div>

    <div className='card-body text'>
      {/* <p className='card-text text-center'>{description}</p> */}
      <p className={rowNum === '2' ? 'lightText card-text text-center' : 'card-text text-center'}>{description}</p>
    </div>
  </div>
);

const Features = () => {
  return (
    <div className='container'>
      <h2 className='text-center title py-5'> {features.title}</h2>
      <div className='row row-cols-md-3 row-cols-sm-1 my-2'>
        {features.contents.map((feature) => (
          <div key={feature.id} className='col col-sm-12'>
            <Feature img={feature.img} alt={feature.alt} description={feature.desc} rowNum='' />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Features;
