import React from "react";
import btc from "../../../../../assets/xeroIntegration/iconWalletNew/Bitcoin.png";
import eth from "../../../../../assets/xeroIntegration/supportCrypto/eth-new.svg";
import xym from "../../../../../assets/xeroIntegration/supportCrypto/xym.svg";
import xem from "../../../../../assets/xeroIntegration/supportCrypto/NEM-XEM.svg";

import binance from "../../../../../assets/xeroIntegration/iconWalletNew/binace.png";
import polygon from "../../../../../assets/xeroIntegration/iconWalletNew/polygon.png";
import avalanch from "../../../../../assets/xeroIntegration/iconWalletNew/avalach.png";

const cryptos = [
  {
    img: btc,
    name: "Bitcoin ",
  },
  {
    img: xym,
    name: "NEM2.0 - XYM",
  },

  {
    img: xem,
    name: "NEM - XEM",
  },

  {
    img: eth,
    name: "Ethereum - ETH",
  },
];

const CryptoList = ({ img, name }) => (
  <div className="row justify-content-center text-center">
    <div>
      <img src={img} alt={name} width="70px" height="70px" />
      <p className="py-4 card-text text-center">{name}</p>
    </div>
  </div>
);

const CryptoSupport = () => (
  <div className="container text-center py-4">
    <h2 className="titleNew">Blockchains & Cryptocurrencies</h2>
    <div className="row py-4">
      {cryptos.map((cryptos) => (
        <div key={cryptos.name} className="col">
          <CryptoList
            img={cryptos.img}
            alt={cryptos.name}
            name={cryptos.name}
          />
        </div>
      ))}
    </div>
  </div>
);

export default CryptoSupport;
