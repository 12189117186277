import React, { Component } from 'react';
import '../ContactUs.css';
import SignUpForm from '../../home/components/FormSignUp';
class JoinNewsletter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      name: '',
      subject: '',
      message: '',
      showAlert: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleSubmit(event) {
    event.preventDefault();
    console.log('Receive data:', this.state);
    this.setState({
      email: '',
      name: '',
      subject: '',
      message: '',
      showAlert: true,
    });
  }

  render() {
    return (
      <div className='joinNewsBg'>
        <div className='container' style={{ padding: '80px 0' }}>
          <div className='justify-content-center px-2'>
            <h1><strong>Join Our Newsletter</strong></h1>
            <br />
          </div>
          <div className='justify-content-center px-2'>
            <p>Sign up to receive new product updates, exclusive blockchain information and more.</p>
          </div>
          <SignUpForm />
        </div>
      </div>
    );
  }
}

export default JoinNewsletter;
