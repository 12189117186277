import React from 'react';
import Header from './components/Header';
import Body from './components/Body';

const JournalerHelp = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <Body />
    </>
  );
};

export default JournalerHelp;
