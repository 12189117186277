import React, { useState } from 'react';
import export_wallet from '../../../assets/home/export_wallet.png';
import export_xero from '../../../assets/home/export_xero.png';
import import_export from '../../../assets/home/import_export.png';
import green from '../../../assets/home/simplify/green.svg';
import white from '../../../assets/home/simplify/white.svg';
import '../Home.css';


const FeatureItem = ({ view, setView, number, title, subtitle, image }) => {
  const isExpanded = view === number;
  const onHandleChangeVide = (isExpanded) => {
    if (!isExpanded) {
      setView(isExpanded ? 0 : number)
    }
  }

  return (
    <div className={`title_export ${isExpanded ? '' : 'title_export_pick'} d-flex flex-row pt-3`} onClick={() => onHandleChangeVide(isExpanded)}>

      <div className='pr-1' >
        <img src={isExpanded ? green : white} />
      </div>
      <div >
        {title}
        <div className='subtitle_export pt-3'> {isExpanded ? subtitle : ''}</div>
      </div>
    </div>
  );
};
const FeatureItemMobile = ({ view, setView, number, title, subtitle, image }) => {
  const isExpanded = view === number;

  const onHandleChangeVide = (isExpanded) => {
    if (!isExpanded) {
      setView(isExpanded ? 0 : number)
    }
  }

  return (
    <div className='row d-flex justify-content-center'>
      {isExpanded ? (<div className='col-12 d-flex justify-content-center pt-3'>
        <img className='img-fluid' src={image} />
      </div>) : null}

      <div className={`title_export ${isExpanded ? '' : 'title_export_pick'} col-12 d-flex flex-row pt-3`} onClick={() => onHandleChangeVide(isExpanded)}>
        <div className='pr-2' >
          <img src={isExpanded ? green : white} />
        </div>
        <div >
          {title}
          <div className='subtitle_export pt-3'> {isExpanded ? subtitle : ''}</div>
        </div>
      </div>
    </div>
  );
};
const features = [
  {
    number: 1,
    title: 'Add & view crypto wallets',
    subtitle:
      `Easily consolidate your crypto transactions with our live 
            Blockchain Wallets and 20,000 Exchange Wallets for manual imports, where managing your digital assets becomes second nature.`,
    image: export_wallet,
  },
  {
    number: 2,
    title: 'Import 100,000s of transactions in seconds',
    subtitle: `Unlock high level data import and export capabilities like never before, 
        allowing you to send bulk transaction data into CSV or Xero – in just one click.`,
    image: import_export,
  },
  {
    number: 3,
    title: 'Connect Journaler with your accounting system',
    subtitle:
      `Set up direct integrations with Xero or QuickBooks, allowing you to 
            instantly sync customized data into your preferred accounting system. For example, 
            you can send bank, invoice or bill feeds straight into Xero.`,
    image: export_xero,
  },
];

const JournalerFeature = () => {
  const [view, setView] = useState(1);
  const getImageForView = (view) => {
    if (view === 1) return export_wallet;
    if (view === 2) return import_export;
    if (view === 3) return export_xero;
    return null;
  };
  const imageSrc = getImageForView(view);
  return (
    <div className='journalerFeature container'>
      <h1 className='journaler-center pt-5 pb-3'>Journaler features</h1>
      <div className='desktop'>
        <div className='row pt-5 pb-5'>
          <div className='col-6 pl-5 d-flex justify-content-center'>
            {imageSrc && <img className='img-fluid' src={imageSrc} />}
          </div>
          <div className='col-6 pr-5'>
            <div className='contain-wallet'>
              {features.map((feature) => (
                <FeatureItem
                  key={feature.number}
                  view={view}
                  setView={setView}
                  {...feature}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='mobile'>
        {features.map((feature) => (
          <FeatureItemMobile
            key={feature.number}
            view={view}
            setView={setView}
            {...feature}
          />
        ))}
      </div>
      <div class="d-flex justify-content-center pt-5 pb-5">
        <button
          rel='noreferrer'
          onClick={() => window.location.href = 'https://www.aemalgorithm.io/journaler/main'}
          className='btnGroup_learnmore mb-3'
        >
          Learn more
        </button>
      </div>
    </div>

  );
};


export default JournalerFeature;
