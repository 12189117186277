import "./index.css"

import React, {forwardRef} from "react";

// modules
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

const processYouTubeEmbeds = (content) => {
    // Regex to match Markdown-style links with YouTube embed URLs
    const markdownLinkRegex = /\[([^\]]+)]\((https:\/\/www\.youtube\.com\/embed\/[a-zA-Z0-9_-]+)(\?[^\s]*)?\)/g;

    return content.replace(markdownLinkRegex, (match, linkText, videoUrl) => {
        // Extract the video ID from the URL
        return `<div class="video-iframe-container">
                <iframe 
                    src="${videoUrl}" 
                    title="YouTube video player" frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen></iframe>
            </div>`;
    });
};

export const MarkdownView = forwardRef(({children}, ref) => {
    return (
        <div ref={ref} className="markdown-content p-3">
            <Markdown
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]} // Allows raw HTML rendering
            >
                {processYouTubeEmbeds(children)}
            </Markdown>
        </div>
    )
})
