import React from 'react'
import step1 from '../../assets/articles/ConfigIntegration/step1.png'
import step21 from '../../assets/articles/ConfigIntegration/step21.png'
import step31 from '../../assets/articles/ConfigIntegration/step31.png'
import step32 from '../../assets/articles/ConfigIntegration/step32.png'
import ArticleHeader from './ArticleHeader'
import ChooseArticle from '../help/journaler/components/Body'
import './Articles.css'

const ConfigIntegration = () => {
  return (
    <>
      <ArticleHeader />

      <div className='container'>
        <div className='articleContainer'>
          <div className='border-bottom text-center'>
            <h3><strong>How to get started with your Xero integration</strong></h3>
            <p style={{ color: '#707070' }}>2021.05.17</p>
          </div>
          <div className='pt-5'>
            <p className='text-justify'>It’s time to integrate Journaler and Xero together, to put all your accounting into one place!</p>
            <p className='text-justify'>With the Xero integration have all your business transactions in one place. AEM Journaler seamlessly integrates with Xero so that you can effortlessly manage your business’s cryptocurrency transactions. Once integrated any and all transactions you make will be tracked by Journaler and recorded within Xero.</p>

            <h4 className='pt-3'><strong>Step 1</strong></h4>
            <p>Navigate to the integration page in the sidebar to access the integration with Xero.</p>
            <img src={step1} className='py-1 articleImg' alt='Navigation bar' />

            <h4 className='pt-3'><strong>Step 2</strong></h4>
            <p>From there select the <strong>Connect</strong> button.</p>
            <img src={step21} className='py-1 articleImg' alt='Xero Integration' /><br />

            <h4 className='pt-3'><strong>Step 3</strong></h4>
            <p className='text-justify'>You will be taken to <strong>Login with Xero</strong>.</p>
            <p className='text-justify'>Once you have authenticated with Xero your Journaler account will be connected to Xero. After an organisation has been connected, an integration with a Journaler wallet can be created.</p>
            <img src={step31} className='py-1 articleImg' alt='Xero login' />
            <img src={step32} className='py-1 articleImg' alt='Xero Journaler connect success' />
            <p className='text-justify'>You can manage your integration by selecting the <strong>Manage</strong> button. Here you can; Connect to another organisation Disconnect an organisation - which will disable the connection between Xero and Jouranler View which wallets are connected to this Xero organisation</p>

            <h4 className='pt-3'><strong>Step 4</strong></h4>
            <p className='text-justify'>Repeat these steps to add any other Xero organisation that you may have.</p>
          </div>
        </div>
      </div>

      <ChooseArticle />
    </>
  )
}

export default ConfigIntegration
