import React from 'react';
import blockchain from '../../../../assets/journaler/main/blockchain.png'
import importWallet from '../../../../assets/journaler/main/importWallet.png'
import '../Main.css'

const features = [
  {
    img: blockchain,
    title: 'Blockchain wallet',
    subTitle: `See full list`,
    link: '/journaler/supported-currencies'
  },
  {
    img: importWallet,
    title: 'Imported wallet',
    subTitle: 'All known cryptocurrencies'
  }
]

const FeatureCard = () => (
  <div className='row justify-content-center align-items-center pt-5'>
    {features.map((data, index) => (
      <div key={index} className='col-12 col-md-6 col-sm-6 col-lg-6 col-xl-6 text-center'>
        <div className='d-flex justify-content-center flex-column pt-3'>
          <div>
            <img className='img-fluid img-optimis' src={data.img} alt='demo' />
          </div>
          <div className='titleCard pt-3'>{data.title} </div>
          <div className='pt-1'> <a className='subTitleCard' href='/journaler/supported-currencies'>{data.subTitle}</a></div>

        </div>
      </div>
    ))}

  </div>
);

const JournalerFeatures = () => {
  return (
    <div className='container pt-5'>
      <div className='solutionJounaler'>The <span className='solutionJounalerGradient'>optimised</span>  crypto accounting <br /> software for business </div>
      <div className='solutionJounalerSub pt-3'>Our powerhouse of accounting tools and integrated features will simplify your
        crypto transaction management like never before.</div>
      <FeatureCard />

    </div>
  );
};

export default JournalerFeatures;
