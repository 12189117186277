import React from 'react';
import './styles.css';
import DemoImg from '../../../../../assets/xeroIntegration/demo_journaler.svg';

const DemoSection = () => {
  return (
    <div className='demoWrapper '>
      <img src={DemoImg} alt='demo' className='img' width='758px' />
    </div>
  );
};

export default DemoSection;
