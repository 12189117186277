import { React } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

// pages:
import Home from './pages/home/Home';
import AboutUs from './pages/aboutUs/AboutUs';
import Team from './pages/aboutUs/team/Team';
import Networks from './pages/aboutUs/network/Networks';

import JournalerMain from './pages/journaler/main/Main';
import XeroIntegration from './pages/journaler/integration/xeroIntegration/XeroIntegration';
import Pricing from './pages/journaler/pricing/pricing';
import SupportedCurrencies from './pages/journaler/supportedCurrencies/SupportedCurrencies';

import JournalerHelp from './pages/help/journaler/JournalerHelp';
import ConfigIntegration from './pages/articles/ConfigIntegration';
import StartXeroIntegration from './pages/articles/StartXeroIntegration';
import GetStart from './pages/articles/GetStart'
import AddWallet from './pages/articles/AddWallet'
import TroubleshootJournaler from './pages/articles/TroubleshootJournaler'

import AemPlusMain from './pages/aemPlus/main/Main'
import AemPlusPrivacyPolicy from './pages/privacyPolicy/aemPlus/AemPlusPrivacyPolicy'
import AemPlusTermsConditions from './pages/termConditions/aemPlus/AemPlusTermsConditions'

import News from './pages/news/News';
import RmitSupportJournaler from './pages/news/newsList/rmitSupportJournaler'
import WhoAreWe from './pages/news/newsList/whoAreWe'

//journaler solutions
import UseCase from './pages/journaler/solutions/UseCase';
import BusinessOwners from './pages/journaler/solutions/BusinessOwners'
import Accountants from './pages/journaler/solutions/Accountants'
import FinancialInstitution from './pages/journaler/solutions/FinancialInstitution'
import NewIntegration from './pages/journaler/new_integration/NewIntegration';

import ContactUs from './pages/contactUs/ContactUs';

import { NotFoundPage } from './pages/404page/NotFoundPage';
// import  ReturnSuccess  from './pages/returnPage/ReturnPage';
// import  ReturnSuccess  from './pages/returnPage/ReturnPage';
import ReturnSuccess from './pages/returnPage/ReturnPage';

import JournalerPrivacyPolicy from './pages/privacyPolicy/journaler/JournalerPrivacyPolicy';
import JournalerTermsConditions from './pages/termConditions/journaler/JournalerTermsConditions';
import Investor from './pages/investor/Investor';

// components:
import Toolbar from './components/toolbar/Toolbar';
import Footer from './components/footer/Footer';
import MrJournaler from './pages/mrJournaler/MrJournaler';

import HelpCenter from "./pages/help-center/help-center";

// provider
import AppProvider from "./AppProvider";

function App() {
  return (
      <AppProvider>
        <Router>
          <Toolbar />

          <Switch>
            <Route path='/' exact component={Home} />

            <Route path='/about-us' component={AboutUs} />
            <Route path='/team' component={Team} />
            <Route path='/networks' component={Networks} />

            <Route path='/journaler/xero-integration' component={XeroIntegration} />
            <Route path='/journaler/pricing' component={Pricing} />
            <Route path='/journaler/integration' component={NewIntegration} />
            <Route path='/journaler/supported-currencies' component={SupportedCurrencies} />
            <Route path='/journaler/help' component={JournalerHelp} exact />
            <Route path='/journaler/help/articles/ConfigIntegration' component={ConfigIntegration} />
            <Route path='/journaler/help/articles/StartXeroIntegration' component={StartXeroIntegration} />
            <Route path='/journaler/help/articles/getting_started' component={GetStart} />
            <Route path='/journaler/help/articles/how_to_add_a_wallet' component={AddWallet} />
            <Route path='/journaler/help/articles/four_steps_to_troubleshooting_journaler' component={TroubleshootJournaler} />
            <Route path='/business-owner' component={BusinessOwners} />
            {/* <Route path='/accountant' component={Accountants} /> */}
            <Route path='/journaler/accountant' component={UseCase} />
            <Route path='/financial-institution' component={FinancialInstitution} />

            <Route path='/journaler/terms-conditions' component={JournalerTermsConditions} />

            <Route path='/aem-plus' component={AemPlusMain} />
            <Route path='/journaler/aem-plus/privacy-policy' component={AemPlusPrivacyPolicy} />
            <Route path='/journaler/aem-plus/terms-conditions' component={AemPlusTermsConditions} />

            <Route path='/news' component={News} exact />
            <Route path='/news/aem_launches_journaler_with_support_of_rmit' component={RmitSupportJournaler} />
            <Route path='/news/who_are_we' component={WhoAreWe} />

            <Route path='/contact' component={ContactUs} />
            <Route path='/investor' component={Investor} />

            <Route path='/privacy-policy' component={JournalerPrivacyPolicy} />



            <Route path='/payment-success' component={ReturnSuccess} />
            <Route path='/404' component={NotFoundPage} />
            {/* <Route path='/journaler/signup-form' component={SignUpForm} /> */}
            <Route path='/journaler/main' component={JournalerMain} />
            <Route path='/journaler/mrJournaler' component={MrJournaler} />

            <Route path='/help-center' component={HelpCenter} />
          </Switch>
          <Footer />
        </Router>
      </AppProvider>
  );
}

export default App;
