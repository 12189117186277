import AEMAlgorithmIcon from '../../assets/menu/WhatWeDo.svg'
import NetworkIcon from '../../assets/menu/network.svg'
import TeamIcon from '../../assets/menu/team.svg'
import JournalerIcon from '../../assets/menu/Jouanelr_about.svg'
import AEMPlusIcon from '../../assets/menu/aem-plus.svg'
import XeroIcon from '../../assets/menu/XERO.svg'
import PricingIcon from '../../assets/menu/pricing.svg'
import HelpIcon from '../../assets/menu/help.svg'
import TermsIcon from '../../assets/menu/T&C.svg'
import PolicyIcon from '../../assets/menu/Privacy.svg'
import BlockchainIcon from '../../assets/menu/whatIsBlockchain.svg'
import ServicesIcon from '../../assets/menu/services.svg'
import NewsIcon from '../../assets/menu/newspaper-o.svg'
import Advise from '../../assets/menu/advice.png'
import Investor from '../../assets/menu/investor.svg'


export const aboutUs = {
  id: 'aboutNavDropdown',
  label: 'About',
  content: [
    {
      type: 'relative',
      path: '/about-us',
      name: 'About Us',
      alt: 'aem logo',
      icon: AEMAlgorithmIcon,
      isLast: false
    },
    {
      type: 'relative',
      path: '/networks',
      name: 'Our Network',
      alt: 'Our networks',
      icon: NetworkIcon,
      isLast: false
    },
    {
      type: 'relative',
      path: '/team',
      name: 'Our Team',
      alt: 'team icon',
      icon: TeamIcon,
      isLast: true
    },
  ]
}

export const journaler = {
  id: 'journalerNavDropdown',
  label: 'JOURNALER',
  content: [
    {
      type: 'relative',
      path: '/journaler',
      name: 'About',
      alt: 'Journaler Icon',
      icon: JournalerIcon,
      isLast: false
    },
    {
      type: 'relative',
      path: '/journaler/xero-integration',
      name: 'Xero Integration',
      alt: 'Xero Icon',
      icon: XeroIcon,
      isLast: false
    },
    {
      type: 'relative',
      path: '/journaler/pricing',
      name: 'Pricing',
      alt: 'pricing icon',
      icon: PricingIcon,
      isLast: false
    },
    // {
    //   type: 'relative',
    //   path: '/journaler/help',
    //   name: 'HELP',
    //   alt: 'help icon',
    //   icon: HelpIcon,
    //   isLast: false
    // },
    {
      type: 'relative',
      path: '/journaler/terms-conditions',
      name: 'Terms & Conditions',
      alt: 'T&C Icon',
      icon: TermsIcon,
      isLast: false
    },
    {
      type: 'relative',
      path: '/privacy-policy',
      name: 'Privacy Policy',
      alt: 'Privacy policy Icon',
      icon: PolicyIcon,
      isLast: true
    }
  ]
}

export const aemPlus = {
  id: 'aemPlusNavDropdown',
  label: 'AEM+',
  content: [
    {
      type: 'relative',
      path: '/aem-plus',
      name: 'ABOUT',
      alt: 'AEM plus Icon',
      icon: AEMPlusIcon,
      isLast: false
    },
    {
      type: 'relative',
      path: '/journaler/aem-plus/terms-conditions',
      name: 'TERMS & CONDITIONS',
      alt: 'T&C Icon',
      icon: TermsIcon,
      isLast: false
    },
    {
      type: 'relative',
      path: '/journaler/aem-plus/privacy-policy',
      name: 'PRIVACY POLICY',
      alt: 'Privacy policy Icon',
      icon: PolicyIcon,
      isLast: true
    },
  ]
}

export const useCases = {
  id: 'useCasesNavDropdown',
  label: 'Use Cases',
  path: '/accountant',

}

export const Products = {
  id: 'solutionsNavDropdown',
  label: 'Products',
  content_1: [
    {
      row: 1,
      isHeader: true,
      type: 'relative',
      // path: '/journaler',
      name: 'Crypto Accounting',
      // alt: 'Journaler Icon',
      // icon: JournalerIcon,
      isLast: false
    },
    {
      row: 1,
      isHeader: false,
      type: 'relative',
      path: '/journaler/main',
      name: 'Journaler',
      alt: 'Journaler Icon',
      icon: JournalerIcon,
      isLast: false
    },
    {
      row: 1,
      isHeader: false,
      type: 'relative',
      path: '/journaler/integration',
      name: 'Integrations',
      alt: 'Xero Icon',
      icon: XeroIcon,
      isLast: false
    },
    {
      row: 1,
      isHeader: false,
      type: 'relative',
      path: '/journaler/accountant',
      name: 'Use Cases',
      alt: 'Use cases',
      icon: ServicesIcon,
      isLast: false
    },
    {
      row: 1,
      isHeader: false,
      type: 'relative',
      path: '/journaler/pricing',
      name: 'Pricing',
      alt: 'pricing icon',
      icon: PricingIcon,
      isLast: false
    },
    {
      row: 1,
      isHeader: false,
      type: 'relative',
      path: '/journaler/supported-currencies',
      name: 'Supported Currencies',
      alt: 'crypto icon',
      icon: ServicesIcon,
      isLast: true
    },


  ],
  content_2: [
    {
      row: 2,
      isHeader: true,
      type: 'relative',
      // path: '/journaler',
      name: 'Crypto Wallets',
      // alt: 'Journaler Icon',
      // icon: JournalerIcon,
      isLast: false
    },
    {
      row: 2,
      isHeader: false,
      type: 'relative',
      path: '/aem-plus',
      name: 'AEM+',
      alt: 'AEM plus Icon',
      icon: AEMPlusIcon,
      isLast: true
    },
    {
      row: 2,
      isHeader: true,
      type: 'relative',
      // path: '/journaler',
      name: 'NFT',
      // alt: 'Journaler Icon',
      // icon: JournalerIcon,
      isLast: false
    },
    {
      row: 2,
      isHeader: false,
      type: 'relative',
      path: '/journaler/mrJournaler',
      name: 'Mr. Journaler',
      alt: 'Journaler Icon',
      icon: JournalerIcon,
      isLast: false
    },
  ]
}

export const resources = {
  id: 'trainingNavDropdown',
  label: 'Resources',
  content: [

    {
      type: 'relative',
      /** deprecated
      path: 'https://www.aemledger.com/help',
       */
      path: '/help-center',
      name: 'Help Center',
      alt: 'Help Icon',
      icon: HelpIcon,
      isLast: false
    },
    {
      type: 'blankLink',
      path: 'https://www.aemledger.com/accounting-advisor',
      name: 'Accounting Advisors',
      alt: 'News Icon',
      icon: Advise,
      isLast: false
    },

    {
      type: 'blankLink',
      path: 'https://www.aemledger.com/blog',
      name: 'Blog',
      alt: 'News Icon',
      icon: NewsIcon,
      isLast: true
    },

  ]
}

export const contacUs = {
  id: 'trainingNavDropdown',
  label: 'Contact Us',
  content: [
    {
      type: 'relative',
      path: '/contact',
      name: 'Support',
      alt: 'Contact Us',
      icon: HelpIcon,
      isLast: false
    },
    {
      type: 'relative',
      path: '/investor',
      name: 'For Investors',
      alt: 'Investors',
      icon: Investor,
      isLast: true
    },


  ]
}
