import React from "react";
import { Ellipse6, disabledPoint } from "../../../assets/mrJournaler/imageMJ";
import "./roadMap.css";

const RoadMap = ({
  roadmapQ1Position,
  roadmapQ1OrdinalPosition,
  roadmapQ2Position,
  roadmapQ3Position,
  roadmapQ4Position,
  roadmap25Position,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "32px",
        alignItems: "center",
      }}
    >
      {/* Active roadmap */}
      <div
        className="roadActive"
        style={{
          height: `${roadmapQ2Position - roadmapQ1Position}px`,
        }}
      />
      <img
        src={Ellipse6}
        alt="Ellipse6"
        className="activePoint"
        style={{
          top: `${roadmapQ1Position}px`,
        }}
      />
      <img
        src={Ellipse6}
        alt="Ellipse6"
        className="activePoint"
        style={{
          top: `${roadmapQ1OrdinalPosition}px`,
        }}
      />
      <img
        src={Ellipse6}
        alt="Ellipse6"
        className="activePoint"
        style={{
          top: `${roadmapQ2Position}px`,
        }}
      />

      {/* Inactive roadmap */}
      <div
        className="roadDisabled"
        style={{
          height: `${roadmap25Position - roadmapQ2Position}px`,
        }}
      />
      <img
        src={disabledPoint}
        alt="disabledPoint"
        className="inactivePoint"
        style={{
          top: `${roadmapQ3Position}px`,
        }}
      />
      <img
        src={disabledPoint}
        alt="disabledPoint"
        className="inactivePoint"
        style={{
          top: `${roadmapQ4Position}px`,
        }}
      />
      <img
        src={disabledPoint}
        alt="disabledPoint"
        className="inactivePoint"
        style={{
          top: `${roadmap25Position}px`,
        }}
      />
    </div>
  );
};

export default RoadMap;
