import xeroLogo from '../assets/home/integrateSoftware/xero.png'
import coinbaseLogo from '../assets/home/integrateSoftware/coinbase.png'
import quickbooksLogo from '../assets/journaler/NewJournaler/quickbooks.svg'
import binanceLogo from '../assets/home/integrateSoftware/binance.png'
import safeLogo from '../assets/home/integrateSoftware/safe.png'
import fireBlockLogo from '../assets/home/integrateSoftware/fireblock.png'

import blockchainBG from '../assets/journaler/main/Addandview.png';
import importedWalletBG from '../assets/journaler/main/import_wallet.png';
import importExportBG from '../assets/journaler/main/import_and_export.png';
import CGTTaxCalculatorBG from '../assets/journaler/main/cgt.png';
import accountingSystemBG from '../assets/journaler/main/connect.webp';

import circleCheckIcon from '../assets/pricing/check.png';

import roadmapBG from '../assets/about/roadmap.webp';
import aboutUsNew from '../assets/about/AboutusNew.png';
import xeroBG from '../assets/about/aboutus-xero.png';
import AEMPlusBG from '../assets/about/aemplus.png';
import fmDiagramBG from '../assets/about/diagrams-futuremission.jpg';
import journalerAEMLogo from '../assets/about/jounaleraem.png';

export const images = {
    quickbooksLogo,
    xeroLogo,
    coinbaseLogo,
    binanceLogo,
    safeLogo,
    fireBlockLogo,
    icon: {
        circleCheckIcon,
    },
    aboutUs: {
        roadmapBG,
        aboutUsNew,
        xeroBG,
        AEMPlusBG,
        fmDiagramBG,
        journalerAEMLogo,
    },
    journaler: {
        main: {
            blockchainBG,
            importedWalletBG,
            accountingSystemBG,
            importExportBG,
            CGTTaxCalculatorBG,
        }
    }
}
