import React, { useState, useEffect } from 'react';
import './SelectOption.css';
import usdImage from '../../../../assets/pricing/usd.png';
import audImage from '../../../../assets/pricing/aud.png';

const SelectOption = ({ currency, valuecurrency }) => {
    const [selectedCurrency, setSelectedCurrency] = useState('aud');

    useEffect(() => {
        if (valuecurrency) {
            setSelectedCurrency(valuecurrency);
        }
    }, [valuecurrency]);

    const handleChange = (event) => {
        currency(event);
        setSelectedCurrency(event);
    };

    return (
        <>
            <div className="dropdown btn-select">
                <button className="btn-inside dropdown-toggle" type="button" id="dropdownMenuButton"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <a className='titleSelect' >
                        <img
                            src={selectedCurrency == 'aud' ? audImage : usdImage}
                            style={{ width: '20px', height: '20px', marginRight: '10px' }}
                            alt=""
                        />{selectedCurrency.toUpperCase()}
                    </a>
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">

                    <a className="dropdown-item" onClick={() => handleChange('aud')}>
                        <img
                            src={audImage}
                            style={{ width: '20px', height: '20px', marginRight: '10px' }}
                            alt=""
                        />AUD
                    </a>


                    <a className="dropdown-item" onClick={() => handleChange('usd')}>
                        <img
                            src={usdImage}
                            style={{ width: '20px', height: '20px', marginRight: '10px' }}
                            alt=""
                        />USD
                    </a>

                </div>
            </div>
        </>
    );
};

export default SelectOption;
