import businessOwnerHeaderImg from '../../../../assets/journaler/solutions/business.png'
import accountantHeaderImg from '../../../../assets/journaler/solutions/accountantimage.png'
import financialInstitutionHeaderImg from '../../../../assets/journaler/solutions/Artboard.png'

export const header = {
  businessOwner: {
    subtitle: 'Business owners',
    title: 'Enterprise-grade accounting made easy for',
    gradient: 'business owners',
    des: `Simplify digital asset and crypto accounting 
    operations for your business. Access a full suite of 
    integrated tools for easy compliance, reconciliation, and reporting.`,
    btn: 'Get started with Journaler',
    img: businessOwnerHeaderImg
  },
  accountant: {
    subtitle: 'Accountants',
    title: 'Crypto accounting made simple for ',
    gradient: 'accountants',
    des: 'Service your crypto clients with our integrated accounting tools and attend to all their digital asset needs with confidence.',
    btn: 'Join our Accountant Partner Program',
    img: accountantHeaderImg
  },
  financialInstitution: {
    subtitle: 'Financial Institutions',
    title: 'Effortless digital asset reporting for',
    gradient: 'investment funds and exchanges',
    btn: 'Get started with Journaler',
    des: 'Crypto investors and financial institutions including any hedge funds, superfunds, exchanges and asset managers Customisable plans to fit your unique translation loads',
    img: financialInstitutionHeaderImg
  }
}

export const secondPart = {
  accountant: {
    header1: `Made with`,
    header2: `in mind`,
    gradient: `accounting professionals and firms`,
    des: `Grow your client base with our complete suite of tools and resources that will help enhance your 
    financial services from end-to-end. Optimise how you track, manage, and reconcile crypto assets – with 100% accuracy every single time.`
  },
  businessOwner: {
    header1: `Streamlining`,
    header2: `from end-to-end`,
    gradient: 'crypto accounting for business',
    des: `Drive the growth of your business and securely manage your digital assets with our intuitive crypto accounting software. Gain control over your 
    crypto asset activity with a full suite of resources and tools, helping you make smarter financial decisions from day one.`
  },
  financialInstitution: {
    header1: `Optimising your`,
    header2: ``,
    gradient: 'financial management & accounting',
    des: `Our intuitive crypto accounting software helps you stay compliant, so you can meet your reporting 
    obligations on time. Utilise our complete suite of advanced accounting tools to 
    efficiently manage your portfolios, drive smart financial decisions, and accurately monitor digital asset data. We make institutional accounting easy, instantly.`
  },
}

export const lastPart = {
  accountant: {
    header1: 'Simplify your',
    header2: 'workflow',
    subHeader: 'crypto accounting'
  },
  businessOwner: {
    header1: 'Unlock',
    header2: 'for business',
    subHeader: 'advanced accounting tools'
  },

  financialInstitution: {
    header1: 'Transform your ',
    header2: '',
    subHeader: 'digital asset and accounting operations'
  }
}



export const contentsWallet = {
  accountant: {
    header: 'Client portfolio',
    headerGradient: 'management',
    content: [
      'Sync and Monitor Blockchain Wallets with 100+ Live Feed Options',
      'Manually Import Transaction data for 20,000 currencies',
      'Automatic Exchange Rate for Transactions, Wallet Balance and Gain Calculations.'
    ]
  },
  businessOwner: {
    header: 'Streamlining crypto accounting for business from end-to-end',
    headerGradient: 'Streamlining crypto accounting for business from end-to-end',
    des: `Drive the growth of your business and securely manage your digital assets with our intuitive crypto accounting software. 
    Gain control over your crypto asset activity with a full suite of resources and tools, helping you make smarter financial decisions from day one.`
  },
  financialInstitution: {
    header: 'Optimising your financial management & accounting',
    des: `Our intuitive crypto accounting software helps you stay compliant, so you can meet 
    your reporting obligations on time. Utilise our complete suite of advanced accounting tools to efficiently manage your portfolios, 
    drive smart financial decisions, and accurately monitor digital asset data. We make institutional accounting easy, instantly.`
  },
}

export const contentsIntegrate = {
  accountant: {
    header: 'with your Accounting System',
    headerGradient: 'Integrate',
    content: [
      'Direct integration with Xero and QuickBooks',
      'Sync transaction feeds, post invoices and bill, record balance and tax journal',
      'Customise large export live batches'
    ]
  },
  businessOwner: {
    header: 'Streamlining crypto accounting for business from end-to-end',
    des: `Drive the growth of your business and securely manage your digital assets with our intuitive crypto accounting software. 
    Gain control over your crypto asset activity with a full suite of resources and tools, helping you make smarter financial decisions from day one.`
  },
  financialInstitution: {
    header: 'Optimising your financial management & accounting',
    des: `Our intuitive crypto accounting software helps you stay compliant, so you can meet 
    your reporting obligations on time. Utilise our complete suite of advanced accounting tools to efficiently manage your portfolios, 
    drive smart financial decisions, and accurately monitor digital asset data. We make institutional accounting easy, instantly.`
  },
}

export const collabIntegrate = {
  accountant: {
    header: 'Collaborative',
    headerGradient: ' accounting',
    content: [
      'Set up multiple company profiles',
      'Invite your accountant and bookkeeper and assign team roles',
      'Choose the plan that’s best for you'
    ]
  },
  businessOwner: {
    header: 'Streamlining crypto accounting for business from end-to-end',
    des: `Drive the growth of your business and securely manage your digital assets with our intuitive crypto accounting software
    Gain control over your crypto asset activity with a full suite of resources and tools, helping you make smarter financial decisions from day one`
  },
  financialInstitution: {
    header: 'Optimising your financial management & accounting',
    des: `Our intuitive crypto accounting software helps you stay compliant, so you can meet 
    your reporting obligations on time. Utilise our complete suite of advanced accounting tools to efficiently manage your portfolios, 
    drive smart financial decisions, and accurately monitor digital asset data. We make institutional accounting easy, instantly`
  },
}
