import React, { Component } from 'react';
import { icons } from './data';
import email from '../../../assets/contactUs/email.svg';
import '../ContactUs.css';

const IconImg = ({ img, alt, link }) => (
  <a href={link} target='_blank' rel='noreferrer'>
    <img src={img} alt={alt} width='40px' className='py-3 mr-3' />
  </a>
);

class SendEmailSection extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    let email, name;
    return (

      <div className='container'>
        <div className='contactFormBg text-left pt-5 pb-4' id='contactForm'>
          <p><span className='step'>Step 1 </span><span className='titleDetail'>Your Detail</span></p>
          <div className='row g-0'style={{marginLeft:'2%' }} >
            <div className='col-6'>
              <input
                name='name'
                className='form-control contactFormInput'
                ref={node => (name = node)}
                type="text"
                placeholder="Your Name"
              />
            </div>
            <div className='col-6'>
              <input
                name='email'
                className='form-control contactFormInput'
                ref={node => (email = node)}
                type="email"
                placeholder="Email address"
                required
              />
            </div>
          </div>
          <p ><span className='step'>Step 2 </span><span className='titleDetail'>Select a topic</span></p>
          <div className='row' style={{ width: '70%' }}>
            <div className='col-md-6 pb-2'>
              <input
                name='name'
                className='form-control contactFormInput'
                ref={node => (name = node)}
                type="text"
                placeholder="Name"
              />
            </div>

          </div>
          {/* <img src={email} alt='email icon' className='pt-3 pb-4' />
          <h5><strong>support@aemalgorithm.io</strong></h5>
          <p style={{ fontSize: '18px' }}>If you have any questions, bug reports, feedback<br />Please email us. We will get back to you as soon as possible</p>
          <button className='btn sendBtn' style={{ height: '50px' }}>
            <a href="mailto:support@aemalgorithm.io" style={{ color: 'white' }}>Email Us</a>
          </button>

          <div className='d-flex justify-content-center pt-5'>
            {icons.map((icons) => (
              <IconImg img={icons.img} alt={icons.alt} link={icons.link} />
            ))}
          </div> */}

        </div>
      </div>
    );
  }
}

export default SendEmailSection;
