import React from 'react'
import CreateIntegration from '../../assets/articles/StartXeroIntegration/1createIntegration.png'
import DateRange from '../../assets/articles/StartXeroIntegration/2date_range.png'
import InvoiceOptions from '../../assets/articles/StartXeroIntegration/3invoice_options.png'
import TransactionOptions from '../../assets/articles/StartXeroIntegration/4transaction_options.png'
import SampleBillXero from '../../assets/articles/StartXeroIntegration/5sample_bill_xero.png'
import SampleTransactionXero from '../../assets/articles/StartXeroIntegration/6sample_transaction_xero.png'
import ArticleHeader from './ArticleHeader'
import ChooseArticle from '../help/journaler/components/Body'
import './Articles.css'

// const Article = ({ data }) => (
//   <div className='pt-5'>
//     {data.map((article) => {
//       return (
//         <React.Fragment>
//           {article.type === 'img' ?
//             <img src={article.detail} className='py-2 articleImg' alt={article.alt} />
//             :
//             <p className='text-justify'>{article.detail}</p>
//           }
//         </React.Fragment>
//       )
//     })}
//   </div>
// )

const StartXeroIntegration = () => {
  return (
    <>
      <ArticleHeader />

      <div className='container'>
        <div className='articleContainer'>
          <div className='border-bottom text-center'>
            <h3><strong>How to get started with your Xero integration</strong></h3>
            <p style={{ color: '#707070' }}>2021.05.17</p>
          </div>
          <div className='pt-5'>
            <img src={CreateIntegration} className='py-2 articleImg' alt='Create Integration' />
            <p className='text-justify'>The integration of a Journaler wallet with Xero enables the export of cryptocurrency transactions into Xero.
              The date range of the export can be limited, allowing the definition of an end or start date of the integration.
              The restriction of the date range will be reflected in the transactions displayed at the bottom of the screen.</p>
            <img src={DateRange} className='py-2 articleImg' alt='Date Range' />
            <p className='text-justify'>The export can be configured as either an Invoicing/Billing export or a Bank Transaction export.</p>
            <p className='text-justify'>AEM Journaler will automatically import information from your Xero account, including Account Codes, Payment Accounts
              and Tax Rates, allowing the user to define the details of their integration.</p>
            <p className='text-justify'>Both export options allow for the configuration of the account codes associated with the transaction and the fee associated with it.
              They also allow the configuration of the tax inclusion, as well as the tax rates applied. If the export is an invoicing export,
              there is an option for the invoice status. If the invoice status is set to Authorised, the invoice can be marked as paid, allowing
              the choice of payment account.</p>
            <img src={InvoiceOptions} className='py-2 articleImg' alt='Invoice Options' /><br />
            <p>All Bank Transaction exports require the selection of the payment account.</p>
            <img src={TransactionOptions} className='py-2 articleImg' alt='Transaction Options' />
            <p className='pt-5 text-justify'>The accounts displayed to the user include all accounts, however the payment account options will only list account codes that
              have payments enabled to the account, or accounts that are a bank account. Once the integration is finalised any historical transactions
              that have been selected will be exported. If an end date has not been supplied, or an end date is in the future, any transactions up to
              that end date will be exported as they happen on the blockchain. </p>
            <p className='pt-4 text-justify'>An example of the output of a cryptocurrency transaction exported as a bank transaction is shown here:</p>
            <img src={SampleBillXero} className='py-2 articleImg' alt='Sample Bill Xero' />
            <p className='pt-4'>An example of a bill exported to Xero is also shown here:</p>
            <img src={SampleTransactionXero} className='py-2 articleImg' alt='Sample Xero Transaction' />
            <p className='text-justify'>Once integrations have been created, a summary of the details of the integration is viewable on the main integration page. Here,
              any live integrations can be stopped, stopping any future transactions from being exported.</p>
            <img src={CreateIntegration} className='py-2 articleImg' alt='Create Integration' />
          </div>
        </div>
      </div>

      <ChooseArticle />
    </>
  )
}

export default StartXeroIntegration
