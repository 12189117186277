import React from 'react';
import './Networks.css';

import ACME from '../../../assets/businessNetworks/ACME.png';
import AWS from '../../../assets/businessNetworks/AWS.png';
import Canvasland from '../../../assets/businessNetworks/Canvasland.png';
import FdsNewLogo from '../../../assets/businessNetworks/FdsNewLogo.png';
import Google from '../../../assets/businessNetworks/Google.png';
import mutaverse from '../../../assets/businessNetworks/Mutaverse.png';
import Onesatoshi from '../../../assets/businessNetworks/Onesatoshi.png';
import Prodigital from '../../../assets/businessNetworks/Prodigital.png';
import REAP from '../../../assets/businessNetworks/REAP.png';
import Synopsys from '../../../assets/businessNetworks/Synopsys.png';
import xero from '../../../assets/businessNetworks/Xero.png';
import CA from '../../../assets/businessNetworks/accounting/CA.png';
import CPA from '../../../assets/businessNetworks/accounting/CPA.png';
import CST from '../../../assets/businessNetworks/accounting/CST.png';
import Electrafi from '../../../assets/businessNetworks/accounting/Electrafi.png';
import Fullstack from '../../../assets/businessNetworks/accounting/Fullstack.png';
import ICB from '../../../assets/businessNetworks/accounting/ICB.png';
import IPA from '../../../assets/businessNetworks/accounting/IPA.png';
import Infinity22 from '../../../assets/businessNetworks/accounting/Infinity22.png';
import bakertilly from '../../../assets/businessNetworks/accounting/bakertilly.png';
import harves from '../../../assets/businessNetworks/accounting/harves.png';
import hkMS from '../../../assets/businessNetworks/accounting/hkMS.png';
import steedman from '../../../assets/businessNetworks/accounting/steedman.png';
import micro from '../../../assets/businessNetworks/micro.png';
import quickbook from '../../../assets/businessNetworks/quickbook.png';
import ListNetwork from './listNetwork/ListNetwork';


import Dragonfly from '../../../assets/businessNetworks/clients/Dragonfly.png';
import Helix from '../../../assets/businessNetworks/clients/Helix.png';
import Himarievergy from '../../../assets/businessNetworks/clients/Himarievergy.png';
import Mean from '../../../assets/businessNetworks/clients/Mean.png';
import Metavate from '../../../assets/businessNetworks/clients/Metavate.png';
import OX from '../../../assets/businessNetworks/clients/OX.png';
import Tamadoge from '../../../assets/businessNetworks/clients/Tamadoge.png';
import oraclize from '../../../assets/businessNetworks/clients/oraclize.png';
import proximaX from '../../../assets/businessNetworks/clients/proximaX.png';
import pwc from '../../../assets/businessNetworks/clients/pwc.png';
import seascape from '../../../assets/businessNetworks/clients/seascape.png';
import xload from '../../../assets/businessNetworks/clients/xload.png';

import Ausindustry from '../../../assets/businessNetworks/Government/Ausindustry.png';
import Bodouniversity from '../../../assets/businessNetworks/Government/Bodouniversity.png';
import Cointelegraph from '../../../assets/businessNetworks/Government/Cointelegraph.png';
import Cyberport from '../../../assets/businessNetworks/Government/Cyberport.png';
import InvestHK from '../../../assets/businessNetworks/Government/InvestHK.png';
import RMITuniversity from '../../../assets/businessNetworks/Government/RMITuniversity.png';
import Shenzhengov from '../../../assets/businessNetworks/Government/Shenzhengov.png';
import Sonechalk from '../../../assets/businessNetworks/Government/Sone&chalk.png';
import Swinburne from '../../../assets/businessNetworks/Government/Swinburne.png';

import Googleweb3 from '../../../assets/businessNetworks/accelerator/Googleweb3.png';
import RMITactivator from '../../../assets/businessNetworks/accelerator/RMITactivator.png';
import Startupbootcamp from '../../../assets/businessNetworks/accelerator/Startupbootcamp.png';
import brinc from '../../../assets/businessNetworks/accelerator/brinc.png';
import cointelegraph_accelerator from '../../../assets/businessNetworks/accelerator/cointelegraph_accelerator.png';

import Astar from '../../../assets/businessNetworks/BlockchainEcosystem/Astar.png';
import BNB from '../../../assets/businessNetworks/BlockchainEcosystem/BNB.png';
import Filecoin from '../../../assets/businessNetworks/BlockchainEcosystem/Filecoin.png';
import fireblock from '../../../assets/businessNetworks/BlockchainEcosystem/Fireblocks.png';
import Foundinal from '../../../assets/businessNetworks/BlockchainEcosystem/Foundinal.png';
import Gamma from '../../../assets/businessNetworks/BlockchainEcosystem/Gamma.png';
import IndependentReserve from '../../../assets/businessNetworks/BlockchainEcosystem/IndependentReserve.png';
import Kusama from '../../../assets/businessNetworks/BlockchainEcosystem/Kusama.png';
import LogoSymbol from '../../../assets/businessNetworks/BlockchainEcosystem/LogoSymbol.png';
import Nem from '../../../assets/businessNetworks/BlockchainEcosystem/Nem.png';
import Solana from '../../../assets/businessNetworks/BlockchainEcosystem/Solana.png';
import Stacks from '../../../assets/businessNetworks/BlockchainEcosystem/Stacks.png';
import Virgocx from '../../../assets/businessNetworks/BlockchainEcosystem/Virgocx.png';
import Zerocap from '../../../assets/businessNetworks/BlockchainEcosystem/Zerocap.png';
import deStore from '../../../assets/businessNetworks/BlockchainEcosystem/deStore.png';
import ordinalsbot from '../../../assets/businessNetworks/BlockchainEcosystem/ordinalsbot.png';
import tiertop from '../../../assets/businessNetworks/BlockchainEcosystem/tiertop.png';



const tech_partners = [
  {
    img: AWS,
    alt: 'AWS',
    name: 'AWS',
    url: 'https://aws.amazon.com/'
  },
  {
    img: Google,
    alt: 'Google',
    name: 'Google',
    url: 'https://cloud.google.com/web3'
  },
  {
    img: micro,
    alt: 'Microsoft',
    name: 'Microsoft',
    url: 'https://www.microsoft.com/en-au'

  },
  {
    img: Synopsys,
    alt: 'Synopsys',
    name: 'Synopsys',
    url: 'https://www.synopsys.com/'
  },
  {
    img: quickbook,
    alt: 'QuickBooks',
    name: 'Intuit QuickBooks',
    url: 'https://quickbooks.intuit.com/au/'

  },
  {
    img: xero,
    alt: 'Xero',
    name: 'Xero',
    url: 'https://www.xero.com/au/'

  },
  {
    img: proximaX,
    alt: 'Proxima X',
    name: 'Proxima X',
    url: 'https://www.proximax.ltd/'

  },
  {
    img: Dragonfly,
    alt: 'Dragonfly',
    name: 'Dragonfly',
    url: 'https://www.dfintech.com/'
  },
  {
    img: mutaverse,
    alt: 'Mutaverse',
    name: 'Mutaverse',
    url: 'https://twitter.com/Mutaverse_Ai'
  },
  {
    img: Canvasland,
    alt: 'Canvasland',
    name: 'Canvasland',
    url: 'https://www.canvasland.io/'
  },
  {
    img: FdsNewLogo,
    alt: 'FDS',
    name: 'FDS',
    url: 'https://fdssoft.com/'
  },
  {
    img: REAP,
    alt: 'REAP',
    name: 'REAP',
    url: 'https://reap.global/'
  },
  {
    img: ACME,
    alt: 'ACME',
    name: 'ACME',
    url: 'https://acme.am/'
  },
  {
    img: Onesatoshi,
    alt: 'One satoshi',
    name: 'One satoshi',
    url: ''
  },
  {
    img: Prodigital,
    alt: 'Prodigital',
    name: 'Prodigital',
    url: 'https://www.prodigitalfund.com/'
  },
]
const accounting_partners = [
  {
    img: CPA,
    alt: 'CPA',
    name: 'CPA',
    url: 'https://www.cpaaustralia.com.au/'

  },
  {
    img: CA,
    alt: 'CA',
    name: 'CA',
    url: 'https://www.charteredaccountantsanz.com/'

  },
  {
    img: IPA,
    alt: 'IPA',
    name: 'IPA',
    url: 'https://www.publicaccountants.org.au/'

  },
  {
    img: ICB,
    alt: 'ICB',
    name: 'ICB',
    url: 'https://www.icb.org.au/'

  },
  {
    img: Electrafi,
    alt: 'ElectraFi',
    name: 'ElectraFi',
    url: 'https://electrafi.finance/'

  },
  {
    img: bakertilly,
    alt: 'Bakertilly',
    name: 'Bakertilly',
    url: 'https://www.bakertilly.com/'

  },
  {
    img: steedman,
    alt: 'Steedman',
    name: 'Steedman',
    url: 'https://www.steedman.co.uk/'

  },
  {
    img: harves,
    alt: 'Harves+',
    name: 'Harves+',
    url: 'https://www.harvestaccounting.com.sg/'
  },
  {
    img: CST,
    alt: 'CST',
    name: 'CST',
    url: 'https://csttax.com/en-au/'

  },
  {
    img: hkMS,
    alt: 'hkMS',
    name: 'hkMS',
    url: 'https://hkmsgroup.com/'

  },
  {
    img: Infinity22,
    alt: 'Infinity22',
    name: 'Infinity22',
    url: 'https://infinity22.co/'

  },
  {
    img: Fullstack,
    alt: 'Fullstack',
    name: 'Fullstack',
    url: 'https://www.fullstack.com.au/'

  },
]

const government_partners = [
  {
    img: Cointelegraph,
    alt: 'Cointelegraph',
    name: 'Cointelegraph',
    url: 'https://cointelegraph.com/'
  },
  {
    img: RMITuniversity,
    alt: 'RMIT',
    name: 'RMIT',
    url: 'https://www.rmit.edu.au/content/rmit/au/en'
  },
  {
    img: Cyberport,
    alt: 'Cyberport',
    name: 'Cyberport',
    url: 'https://cyberport.hk/'
  },
  {
    img: Shenzhengov,
    alt: 'Shenzhen Government',
    name: 'Shenzhen Government',
    url: 'http://www.sz.gov.cn/en/'
  },
  {
    img: InvestHK,
    alt: 'Invest HK',
    name: 'Invest HK',
    url: 'https://www.investhk.gov.hk/en/home.html'
  },
  {
    img: Sonechalk,
    alt: 'Stone & Chalk',
    name: 'Stone & Chalk',
    url: 'https://www.stoneandchalk.com.au/'
  },
  {
    img: Ausindustry,
    alt: 'Aus Industry',
    name: 'Aus Industry',
    url: ''
  },
  {
    img: Swinburne,
    alt: 'Swinburne',
    name: 'Swinburne',
    url: 'https://www.swinburne.edu.au/'
  },
  {
    img: Bodouniversity,
    alt: 'Bodo University',
    name: 'Bodo university',
    url: 'https://www.budo-u.ac.jp/english/'
  },

]
const clients_partners = [
  {
    img: Helix,
    alt: 'Helix',
    name: 'Helix',
    url: ''
  },
  {
    img: oraclize,
    alt: 'Oraclize',
    name: 'Oraclize',
    url: ''
  },
  {
    img: Tamadoge,
    alt: 'Tamadoge',
    name: 'Tamadoge',
    url: 'https://tamadoge.io/'
  },
  {
    img: OX,
    alt: 'OX securities',
    name: 'OX securities',
    url: 'https://oxsecurities.com/'
  },
  {
    img: Mean,
    alt: 'Meanfi',
    name: 'Meanfi',
    url: 'https://www.meanfi.com/'
  },
  {
    img: Metavate,
    alt: 'Metavate',
    name: 'Metavate',
    url: 'https://www.metavate.io/'
  },
  {
    img: pwc,
    alt: 'PWC',
    name: 'PWC',
    url: 'https://www.pwc.com.au/'
  },
  {
    img: xload,
    alt: 'Xload',
    name: 'Xload',
    url: ''
  },
  {
    img: seascape,
    alt: 'seascape',
    name: 'Seascape',
    url: 'https://www.seascapeconsultants.co.uk/'

  },
  {
    img: Himarievergy,
    alt: 'Himari Energy',
    name: 'Himari Energy',
    url: 'https://www.himarienergy.com.au/'
  },



]

const Accelerators_partners = [
  {
    img: Startupbootcamp,
    alt: 'Startup Bootcamp',
    name: 'Startup Bootcamp',
    url: 'https://www.startupbootcamp.org/'
  },
  {
    img: RMITactivator,
    alt: 'RMIT Activator',
    name: 'RMIT Activator',
    url: 'https://www.rmit.edu.au/partner/hubs/activator'
  },
  {
    img: cointelegraph_accelerator,
    alt: 'Cointelegraph Accelerator',
    name: 'Cointelegraph Accelerator',
    url: 'https://cointelegraph.com/accelerate/'
  },
  {
    img: brinc,
    alt: 'Brinc.io',
    name: 'Brinc.io',
    url: 'https://www.brinc.io/'
  },
  {
    img: Googleweb3,
    alt: 'Google Web3 Accelerator',
    name: 'Google Web3 Accelerator',
    url: 'https://cloud.google.com/startup/web3'
  },
]
const Blockchain_Ecosystem = [
  {
    img: fireblock,
    alt: 'Fireblocks',
    name: 'Fireblocks',
    url: 'https://www.fireblocks.com/'
  },
  {
    img: Astar,
    alt: 'Astar',
    name: 'Astar',
    url: 'https://astar.network/'
  },
  {
    img: Kusama,
    alt: 'Kusama',
    name: 'Kusama',
    url: 'https://kusama.network/'
  },
  {
    img: Stacks,
    alt: 'Stacks',
    name: 'Stacks',
    url: 'https://www.stacks.co/'
  },
  {
    img: Nem,
    alt: 'Nem',
    name: 'Nem',
    url: 'https://explorer.nemtool.com/'
  },
  {
    img: LogoSymbol,
    alt: 'Symbol',
    name: 'Symbol',
    url: 'https://symbol-community.com/'
  },
  {
    img: BNB,
    alt: 'BNB',
    name: 'BNB',
    url: 'https://www.bnbchain.org/en'
  },
  {
    img: IndependentReserve,
    alt: 'Independent Reserve',
    name: 'Independent Reserve',
    url: 'https://www.independentreserve.com/au'
  },
  {
    img: Zerocap,
    alt: 'Zerocap',
    name: 'Zerocap',
    url: 'https://zerocap.com/'
  },
  {
    img: Solana,
    alt: 'Solana',
    name: 'Solana',
    url: 'https://solana.com/'
  },
  {
    img: Filecoin,
    alt: 'Filecoin',
    name: 'Filecoin',
    url: 'https://filecoin.io/'
  },
  {
    img: Foundinal,
    alt: 'Foundinals',
    name: 'Foundinals',
    url: 'https://twitter.com/foundinals'
  },
  {
    img: Virgocx,
    alt: 'VirgoCX',
    name: 'VirgoCX',
    url: 'https://virgocx.ca/'
  },
  {
    img: deStore,
    alt: 'Destore',
    name: 'Destore',
    url: 'https://twitter.com/DeStore_Network'
  },
  {
    img: tiertop,
    alt: 'Tiertop',
    name: 'Tiertop',
    url: 'https://www.tiertop.io/'
  },
  {
    img: Gamma,
    alt: 'Gamma',
    name: 'Gamma',
    url: 'https://gamma.io/'
  },
  {
    img: ordinalsbot,
    alt: 'Ordinalbots',
    name: 'Ordinalbots',
    url: 'https://ordinalsbot.com/collections'
  },
]



const Networks = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className='networkHeader'>
        <div className='container'>
          <div className='headerTitle'>Our Networks</div>
          <div className='subeaderTitle pt-3'>
            Trusted by global leading enterprises in accounting and beyond.
          </div>
        </div>
      </div>
      <ListNetwork dataNetWork={tech_partners} headerTitle={'Meet our tech partners'} class_col={4} />
      <ListNetwork dataNetWork={accounting_partners} headerTitle={'Meet our accounting partners'} class_col={4} />
      <ListNetwork dataNetWork={clients_partners} headerTitle={'Meet some of our clients'} class_col={4} />
      <ListNetwork dataNetWork={government_partners} headerTitle={'Government Academic and Ecosystem Collaborators'} class_col={3} />
      <ListNetwork dataNetWork={Accelerators_partners} headerTitle={'Accelerators'} class_col={3} />
      <ListNetwork dataNetWork={Blockchain_Ecosystem} headerTitle={'Blockchain Ecosystem'} class_col={4} />
    </div>
  );
};

export default Networks;
