import React from 'react';
import { general, acknowledgement, dataCollection, dataStorage, changes, xero } from './content';
import { BulletListContents, MainSectionWrapper, SectionWrapper, MulitiList, ListData } from '../../termConditions/journaler/components/Wrappers';
import './style.css';

const AemPlusPrivacyPolicy = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className='policyPageWrapper'>
        <div className='row justify-content-center'>
          <h1 className='titleBorder text-center'>{general.title}</h1>
        </div>
        <div className='container'>
          <h4 className='text-center pt-4 pb-2'>{general.subtitle}</h4>
          <p className='text-justify'>{general.content[0]}</p>
          <p className='text-justify'>{general.content[1]}</p>
          <p className='text-justify pb-4'>{general.content[2]}</p>



          <ListData dataCollection={acknowledgement}/>
          <ListData dataCollection={dataCollection}/>
          <ListData dataCollection={dataStorage}/>
          <ListData dataCollection={changes}/>

          
        </div>

      </div>
    </>
  );
};

export default AemPlusPrivacyPolicy;
