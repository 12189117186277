import React from 'react';
import { NavLink } from 'react-router-dom';

const DropdownMenu = ({ data }) => {

  const renderLink = (link) => {
    console.log(link);
  
    if (link.isHeader) {
      if(link.name === 'NFT'){
        return (
          <div className="dropdown-item_header pt-5">
            <strong >{link.name}</strong>
          </div>
        );
      }
      else{
        return (
          <div className="dropdown-item_header">
            <strong >{link.name}</strong>
          </div>
        );
      }
     
    }
    if (link.type === "relative") {
      return (
        <NavLink
          className={
            link.isLast ? "dropdown-item lastItem" : "dropdown-item"
          }
          to={link.path}
          data-toggle="collapse"
          data-target=".navbar-collapse.show"
        >
          {link.name}
        </NavLink>
      );
    }
    return (
      <a
        className="dropdown-item"
        rel="noreferrer"
        href={link.path}
        target="_blank"
      >
        {link.name}
      </a>
    );
  };

  return (
    <div className="dropdown-menu" aria-labelledby={data.id}>
      <div className="row" style={{ width: 500 }}>
        <div className="col-6">
          {data.content_1.map(renderLink)}
        </div>
        <div className="col-6">
          {data.content_2.map(renderLink)}
        </div>
      </div>
    </div>
  );
};

export default DropdownMenu;
