import React from "react";

import avalanch from "../../../../assets/xeroIntegration/iconWalletNew/avalach.png";
import binance from "../../../../assets/xeroIntegration/iconWalletNew/binace.png";
import polygon from "../../../../assets/xeroIntegration/iconWalletNew/polygon.png";
const cryptos = [
  {
    id: 1,
    name: "Bitcoin",
    value: "BTC",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/1.png",
    chain: "Bitcoin ",
    contractAddress: "BTC",
    isActive: true,
  },
  {
    id: 93,
    name: "Symbol",
    value: "XYM",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/8677.png",
    chain: "Symbol",
    contractAddress: "xym",
    isActive: true,
  },
  {
    id: 2,
    name: "XEM",
    value: "XEM",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/873.png",
    chain: "NEM",
    contractAddress: "xem",
    isActive: true,
  },
  {
    id: 3,
    name: "Ethereum",
    value: "ETH",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
    chain: "Ethereum",
    contractAddress: "eth",
    isActive: true,
  },
  {
    id: 36,
    name: "Binance",
    value: "BNB",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png",
    chain: "BSC",
    contractAddress: "bnb",
    isActive: true,
  },
  {
    id: 94,
    name: "Astar",
    value: "ASTR",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/200x200/12885.png",
    chain: "Subscan",
    contractAddress: "1",
    isActive: true,
  },
];
const ComingSooncryptos = [
  {
    img: binance,
    name: "Binance ",
  },
  {
    img: polygon,
    name: "Polygon",
  },
  {
    img: avalanch,
    name: "Avalanch",
  },
];

const CryptoList = ({ img, name }) => (
  <div className="row justify-content-center text-center mb-3 mt-3">
    <div>
      <img src={img} alt={name} width="70px" height="70px" />
      <p className="py-4 card-text text-center">{name}</p>
    </div>
  </div>
);

const Integrations = () => (
  <div className="container text-center py-4">
    <h2 className="titleNew">Blockchains</h2>
    <div className="row py-4">
      {cryptos.map((cryptos) => (
        <div key={cryptos.name} className="col-4 col-sm-4 col-md-3 col-lg-3">
          <CryptoList
            img={cryptos.img}
            alt={cryptos.name}
            name={cryptos.name}
          />
        </div>
      ))}
    </div>
  </div>
);

export default Integrations;
