import { useMediaQuery } from "react-responsive";
import { Link, NavLink } from "react-router-dom";
import navLogo from "../../assets/menu/AEMlogo.svg";
import exit from "../../assets/menu/Exit.svg";
import aem_logo from "../../assets/menu/aem_logo.png";
import contacts_icon from "../../assets/menu/contacts_icon.svg";
import more_icon from "../../assets/menu/more_icon.svg";
import DetailToolbar from "./DetailToolbar";
import { aboutUs, contacUs, resources, Products } from "./data";

import React from "react";
import "./Toolbar.css";
import DropdownMenu from "./DropdownMenu";

const MenuList = ({ data }) => (
  <li className="nav-item dropdown d-flex align-items-center">
    <button
      className="nav-link dropdown-toggle"
      id={data.id}
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      {data.label}
    </button>
    <div className="dropdown-menu" aria-labelledby={data.id}>
      {data.content.map((link) => {
        return (
          <React.Fragment key={link.name}>
            {link.type === "relative" ? (
              <NavLink
                className={
                  link.isLast === true
                    ? "dropdown-item lastItem"
                    : "dropdown-item"
                }
                to={link.path}
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                {link.name}
              </NavLink>
            ) : (
              <a
                className={
                  link.isLast === true
                    ? "dropdown-item lastItem"
                    : "dropdown-item"
                }
                rel="noreferrer"
                href={link.path}
                target="_blank"
              >
                {link.name}
              </a>
            )}
          </React.Fragment>
        );
      })}
    </div>
  </li>
);

const MenuListWithHeader = ({ data }) => (
  <li className="nav-item dropdown d-flex align-items-center">
    <button
      className="nav-link dropdown-toggle"
      id={data.id}
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      {data.label}
    </button>
    <DropdownMenu data={data}/>
  </li>
);

const Toolbar = (props) => {
  const onChangeLink = (e) => {
    e.preventDefault();
    window.location.href = "https://journaler.aem.ac/";
  };
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <div>
      {isMobile ? (
        <>
          {/* Initial Navbar */}
          <div className="d-flex justify-content-between fixed-top mobileContent">
            <div className="bd-highlight">
              <button
                class="navbar-toggler btn-link"
                type="button"
                data-toggle="collapse"
                data-target="#navbarToggleExternalContent"
                aria-controls="navbarToggleExternalContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <img
                  id="toggleImage"
                  src={more_icon}
                  alt="logo"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                  className="logoNavMobile"
                />
              </button>
            </div>
            <div onClick={() => window.location.href = "/"}>
              <img
                className="img_fluid"
                src={aem_logo}
                alt="logo"
                style={{ marginLeft: "10px", width: 82 }}
              />
            </div>
            <div onClick={onChangeLink} className="userLogin">
              <img
                src={contacts_icon}
                alt="logo"
                className="logoNavMobile"
              />
            </div>
          </div>
          {/* Sidebar */}
          <div className="sidebar" id="navbarToggleExternalContent">
            {/* Navbar inside Sidebar */}
            <div className="d-flex justify-content-between fixed-top mobileContent">
              <div className="bd-highlight">
                <button
                  class="navbar-toggler btn-link"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarToggleExternalContent"
                  aria-controls="navbarToggleExternalContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <img
                    id="toggleImage"
                    src={exit}
                    alt="logo"
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                    className="logoNavMobile"

                  />
                </button>
              </div>
              <div onClick={() => window.location.href = "/"}>
                <img
                  className="img_fluid"
                  src={aem_logo}
                  alt="logo"
                  style={{ marginLeft: "10px", width: 82 }}
                />
              </div>
              <div onClick={onChangeLink} className="userLogin">
                <img
                  src={contacts_icon}
                  alt="logo"
                  className="logoNavMobile"
                />
              </div>
            </div>
            <DetailToolbar />
          </div>
        </>
      ) : (
        <div>
          <nav className="navbar navbar-expand-md  fixed-top customNavbar">
            <Link className="navbar-brand" to="/">
              <img
                src={navLogo}
                alt="logo"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                className="logoNav"
              />
            </Link>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav m-auto ">
                <MenuList data={aboutUs} />
                <MenuListWithHeader data={Products} />
                <MenuList data={resources} />
                <MenuList data={contacUs} />
                <li className="nav-item d-flex align-items-center pl-5">
                  <a
                    className="sign-in"
                    rel="noreferrer"
                    target="_blank"
                    href="https://journaler.aem.ac/"
                  >
                    Sign in
                  </a>
                </li>
                <li className="nav-item d-flex align-items-center">
                  <button
                    type="button"
                    className="btn signupLink"
                    rel="noreferrer"
                    onClick={() =>
                      window.open(
                        "https://journaler.aem.ac/user/register",
                        "_blank"
                      )
                    }
                  >
                    Journaler Free Trial
                  </button>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      )}
    </div>
  );
};

export default Toolbar;
