import React, { Component, useRef } from 'react';
import { icons, selectorTwoOptions, selectorThreeOptions } from './data';
import '../ContactUs.css';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import emailjs from '@emailjs/browser';

const IconImg = ({ img, alt, link }) => (
  <a href={link} target='_blank' rel='noreferrer'>
    <img src={img} alt={alt} width='20px' className='py-3 mr-3' />
  </a>
);

class ContactUsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      name: '',
      selectorOne: 'Select a topic',
      selectorTwo: 'Select a topic',
      selectorThree: '',
      showTextarea: false,
      showStepThree: false,
      showStepFour: false,
      showStepFive: false,
      message: '',
      showAlert: false,
      show: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.handleClose = () => {
      this.setState({ show: false });
    }
    this.handleShow = () => {
      this.setState({ show: true });
    }

  }


  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleSubmit(event) {
    event.preventDefault();
    var templateParams = {
      from_name: this.state.name,
      sender_email: this.state.email,
      topic: this.state.selectorOne + ' -  ' + this.state.selectorTwo + ' - ' + this.state.selectorThree,
      message: this.state.message,

    };

    console.log('templateParams ', templateParams);
    // emailjs.send('service_0mk08pj', 'template_ndpe0g6', templateParams, 'wa3e8-Dz67AZ5I_Ng')
    //   .then(function (response) {
    //     console.log('SUCCESS!', response.status, response.text);
    //   }, function (error) {
    //     console.log('FAILED...', error);
    //   });

    this.handleShow();

    this.setState({
      email: '',
      name: '',
      selectorOne: 'Select a topic',
      selectorTwo: 'Select a topic',
      selectorThree: '',
      showTextarea: false,
      showStepThree: false,
      showStepFour: false,
      showStepFive: false,
      message: '',
      showAlert: false,
    });
  }


  handleSelectorOne = (e) => {
    e.target.value === 'Journaler Help' ?
      this.setState({
        selectorOne: e.target.value,
        showStepThree: true,
        showTextarea: false,
      })
      :
      this.setState({
        selectorOne: e.target.value,
        showTextarea: true,
        showStepThree: false,
      })
  }

  handleSelectorTwo = (e) => {
    this.setState({
      selectorTwo: e.target.value,
      showStepFour: true,
    });
  }

  handleSelectorThree = (e) => {
    this.setState({
      selectorThree: e.target.value,
      showStepFive: true,
    });
  }

  render() {
    //TODO: show help articles
    const selectorTwoResult = this.state.selectorTwo;
    const seletorThreeArray = selectorThreeOptions.find(option => option.id === selectorTwoResult);

    return (
      <div className='container mb-5'  >
        <div className='contactFormBg' id='contactForm'>

          {this.state.showAlert === false ? (
            ''
          ) : (
            <div className='row justify-content-center'>
              <div
                className='alert alert-warning alert-dismissible fade show'
                role='alert'
                style={{ height: '50px' }}>
                {/* Thank you! Your submission is received and we will contact you soon. */}
                Sorry at the moment the form doesn't work, please email us directly at support@aemalgorithm.io
                <button type='button' className='close' data-dismiss='alert' aria-label='Close'>
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
            </div>
          )}

          <div className='row justify-content-center'>
            <form className='py-3 pt-3 contactUsForm' onSubmit={this.handleSubmit} >

              <label htmlFor="userDetail" className='pt-4 formLabel'>STEP 1: <strong style={{ color: 'black' }}>Your Details</strong></label>
              <div className='row'>
                <div className='form-group col-12 col-md-6'>
                  <input
                    id='name'
                    name='name'
                    type='text'
                    className='form-control contactFormInput'
                    placeholder='Your Name'
                    value={this.state.name}
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className='form-group col-12 col-md-6'>
                  <div className="row"></div>
                  <input
                    id='email'
                    name='email'
                    type='email'
                    className='form-control contactFormInput'
                    placeholder='Email Address'
                    value={this.state.email}
                    onChange={this.handleChange}
                    required
                  />
                </div>
              </div>

              <div className='form-group pt-2'>
                <label htmlFor="selectorOne" className='formLabel'>STEP 2: <strong style={{ color: 'black' }}> Select a topic</strong></label>
                <select className="form-control contactFormInput" id="selectorOne" name='selectorOne' value={this.state.selectorOne} onChange={this.handleSelectorOne}>
                  <option value='Select a topic'>Select a topic</option>
                  <option value='Journaler Help'>Journaler Help</option>
                  <option value='AEM+ help'>AEM+ help</option>
                  <option value='Feedback'>Feedback</option>
                  <option value='Accountant enquiry'>Accountant enquiry</option>
                  <option value='Other'>Other</option>
                </select>
              </div>

              {this.state.showTextarea === false ? '' :
                <>
                  <div className='form-group pt-2'>
                    <label htmlFor="message" className='formLabel'>STEP 3: <strong style={{ color: 'black' }}>Send us a message</strong></label>
                    <textarea
                      className='form-control lightGreyBorder'
                      id='message'
                      name='message'
                      rows='3'
                      placeholder='Message'
                      value={this.state.message}
                      onChange={this.handleChange}></textarea>
                  </div>

                  <div className='text-center'>
                    <button type='submit' className=' sendBtn' >
                      Submit
                    </button>
                  </div>
                </>}

              {this.state.showStepThree === false ? '' :
                <>
                  <div className='form-group pt-2'>
                    <label htmlFor="selectorTwo" className='formLabel'>STEP 3: <strong style={{ color: 'black' }}>Tell us more</strong></label>
                    <select className="form-control contactFormInput" id="selectorTwo" name='selectorTwo' value={this.state.selectorTwo} onChange={this.handleSelectorTwo}>
                      {selectorTwoOptions.map(selectorTwo => (
                        <option value={selectorTwo}>{selectorTwo}</option>
                      ))}
                    </select>
                  </div>

                  {this.state.selectorTwo === 'Report an issue' ?
                    <div className='notification'>
                      <p style={{ marginBottom: '0' }}>If you are having an issue <strong>refreshing your screen</strong> or <strong>logging in again</strong>, <a href='/journaler/help/articles/four_steps_to_troubleshooting_journaler'>try these steps</a></p>
                    </div>
                    : ''
                  }

                  {this.state.showStepFour === false ? '' :
                    <>
                      <div className='form-group pt-2'>
                        <label htmlFor="selectorThree" className='formLabel'>STEP 4: <strong style={{ color: 'black' }}>Please make a selection</strong></label>
                        <select className="form-control contactFormInput" id="selectorThree" name='selectorThree' value={this.state.selectorThree} onChange={this.handleSelectorThree}>
                          {seletorThreeArray.option.map(selectorThree => (
                            <option value={selectorThree}>{selectorThree}</option>
                          ))}
                        </select>
                      </div>

                      {this.state.showStepFive === false ? '' :
                        <>
                          <div className='form-group pt-2'>
                            <label htmlFor="message" className='formLabel'>STEP 5: <strong style={{ color: 'black' }}>Send us a message</strong></label>
                            <textarea
                              className='form-control lightGreyBorder'
                              id='message'
                              name='message'
                              rows='3'
                              placeholder='Message'
                              value={this.state.message}
                              onChange={this.handleChange}></textarea>
                          </div>

                          <div className='text-center'>
                            <button type='submit' className='sendBtn' >
                              Submit
                            </button>
                          </div>
                        </>}
                    </>}
                </>}

            </form>
          </div>

          <div className='d-flex justify-content-center pb-2 pt-4'>
            {icons.map((icons) => (
              <IconImg img={icons.img} alt={icons.alt} link={icons.link} />
            ))}
          </div>
        </div>
        <div>
          <Modal show={this.state.show} onHide={this.handleClose}>
            <Modal.Header >
              <Modal.Title>Thank for your feedback</Modal.Title>
            </Modal.Header>
            <Modal.Body>We will reply to your email as soon as possible</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose}>
                Close
              </Button>

            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}

export default ContactUsForm;
