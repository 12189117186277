
import { faCaretRight, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from 'react';
import './Toolbar.css';

const AboutUs = () => {
  return (
    <div className="activeDivBottom">
      <li>
        <div class="group-item-container"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = '/about-us';
          }}
        >About Us
        </div>
      </li>
      <li>
        <div class="group-item-container"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = '/networks';
          }}
        >Our Network</div>
      </li>
      <li>
        <div class="group-item-container"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = '/team';
          }}
        >Our Team</div>
      </li>
    </div>
  )
}
const JournalerAccounting = () => {
  return (
    <div className="activeDivBottom">

      <li>
        <div type="button" class="group-item-container"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = '/journaler/main';
          }}
        >Journaler</div>
      </li>
      <li>
        <div type="button" class="group-item-container"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = '/journaler/integration';
          }}
        >Integration</div>
      </li>
      <li>
        <div type="button" class="group-item-container"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = '/journaler/accountant';
          }}
        >Use Cases</div>
      </li>
      <li>
        <div class="group-item-container"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = '/journaler/pricing';
          }}
        >Pricing</div>
      </li>
      <li>
        <div type="button" class="group-item-container"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = '/journaler/supported-currencies';
          }}
        >Supported Currencies</div>
      </li>

    </div>
  )
}

const ContactUs = (props) => {
  return (
    <div className="activeDivBottom">
      <li>
        <div class="group-item-container"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = '/contact';
          }}
        >Support</div>
      </li>
      <li>
        <div class="group-item-container"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = '/investor';
          }}
        >For Investors</div>
      </li>

    </div>
  )
}
const Resource = (props) => {

  return (
    <div className="activeDivBottom">
      <li>
        <div class="group-item-container"
          onClick={(e) => {
            e.preventDefault();
            // window.location.href = 'https://www.aemledger.com/help';
              window.location.href = '/help-center'
          }}
        >Help Center</div>
      </li>
      <li>
        <div class="group-item-container"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = 'https://www.aemledger.com/accounting-advisor';
          }}
        >Accounting Advisors</div>
      </li>
      <li>
        <div class="group-item-container"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = 'https://www.aemledger.com/blog';
          }}
        >Blog</div>
      </li>

    </div>
  )
}

const DetailToolbar = () => {
  const [showAboutUs, setShowAboutUs] = useState(false);
  const [showJournaler, setShowJournaler] = useState(false);
  const [showResource, setShowResource] = useState(false);
  const [showContact, setShowContact] = useState(false);

  const Home = () => {
    return (
      <div style={{ zIndex: '1000' }}>
        <ul class="list-group list-group-flush ">
          <li className={showAboutUs ? 'activeDivTop' : ''}>
            <div class="d-flex justify-content-between" onClick={() => {
              setShowAboutUs(!showAboutUs);
              setShowJournaler(false);
              setShowResource(false);
              setShowContact(false);
            }}>
              <div className="group-item">About</div>
              <div className="iconCaret">
                <FontAwesomeIcon icon={showAboutUs ? faCaretUp : faCaretRight} class='icon-faCaretRight' />
              </div>
            </div>

          </li>
          {showAboutUs && (<AboutUs />)}
          <li className={showJournaler ? 'activeDivTop' : ''}>
            <div class="d-flex justify-content-between" onClick={() => {
              setShowJournaler(!showJournaler);
              setShowAboutUs(false);
              setShowResource(false);
              setShowContact(false);
            }}>
              <div className="group-item">Crypto accounting</div>
              <div className="iconCaret">
                <FontAwesomeIcon icon={showJournaler ? faCaretUp : faCaretRight} class='icon-faCaretRight' />
              </div>
            </div>
          </li>
          {showJournaler && (<JournalerAccounting />)}
          <li >
            <div class="d-flex justify-content-between" onClick={(e) => {
              e.preventDefault();
              window.location.href = '/journaler/mrJournaler';
            }}>
              <div className="group-item">Mr.Journaler</div>

            </div>
          </li>
          <li >
            <div class="d-flex justify-content-between" onClick={(e) => {
              e.preventDefault();
              window.location.href = '/aem-plus';
            }}>
              <div className="group-item">AEM+</div>

            </div>
          </li>

          <li className={showResource ? 'activeDivTop' : ''}>
            <div class="d-flex justify-content-between" onClick={() => {
              setShowResource(!showResource);
              setShowAboutUs(false);
              setShowJournaler(false);
              setShowContact(false);
            }}>
              <div className="group-item">Resources</div>
              <div className="iconCaret">
                <FontAwesomeIcon icon={showResource ? faCaretUp : faCaretRight} class='icon-faCaretRight' />
              </div>
            </div>
          </li>
          {showResource && (<Resource />)}
          <li className={showContact ? 'activeDivTop' : ''}>
            <div class="d-flex justify-content-between" onClick={() => {
              setShowContact(!showContact);
              setShowAboutUs(false);
              setShowJournaler(false);
              setShowResource(false);
            }}>
              <div className="group-item">Contact us</div>
              <div className="iconCaret">
                <FontAwesomeIcon icon={showContact ? faCaretUp : faCaretRight} class='icon-faCaretRight' />
              </div>
            </div>
          </li>
          {showContact && (<ContactUs />)}
        </ul>
      </div>
    )
  }

  return (
    <div className='detailToolbar'>
      <Home />
    </div>
  )

}


export default DetailToolbar
