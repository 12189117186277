import React from 'react';

// provider
import {HelpCenterProvider} from "./provider/HelpCenter";

const AppProvider = ({ children }) => {
    return (
        <HelpCenterProvider>
            {children}
        </HelpCenterProvider>
    )
}

export default AppProvider;
