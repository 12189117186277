import React from 'react';
import './NotFoundPage.css';

import { Link } from 'react-router-dom';

import AemTextLogo from '../../assets/AEM_Algorithm_text_logo.png';

export const NotFoundPage = () => {
  return (
    <div className='notFoundContainer'>
      <div className='logoImgDiv'>
        <img src={AemTextLogo} alt='logo' style={{ width: '100%' }} />
      </div>

      <div className='p-4'>
        <h1 style={{ color: '#094674' }}>Whoops!</h1>
        <p className='text-center'>We cannot seem to find the page that you are looking for.</p>
        <Link to='/'>Go back home</Link>
      </div>
    </div>
  );
};
