import React from 'react';
import '../style.css';
import WalletConnection from './WalletConnection';
import WalletConnectionMobile from './WalletConnectionMobile';
import UPDATED_WALLET from '../../../../assets/journaler/solutions/UPDATED_WALLET.png'
import xero_account from '../../../../assets/journaler/solutions/input-xero.png'
import mark_account from '../../../../assets/journaler/solutions/mark-account.png'
import { contentsWallet, contentsIntegrate, collabIntegrate } from './data';


const WalletGroup = () => (
    <div>
        <div className='desktop'>
            <WalletConnection content={contentsWallet.accountant} dark={'light'} revert={true} image={UPDATED_WALLET} section={1} />
            <WalletConnection content={contentsIntegrate.accountant} dark={'dark'} revert={false} image={xero_account} section={2} />
            <WalletConnection content={collabIntegrate.accountant} dark={'light'} revert={true} image={mark_account} section={3} />
        </div>
        <div className='mobile'>
            <WalletConnectionMobile content={contentsWallet.accountant} image={UPDATED_WALLET} section={1} />
            <WalletConnectionMobile content={contentsIntegrate.accountant} image={xero_account} section={2} />
            <WalletConnectionMobile content={collabIntegrate.accountant} image={mark_account} section={1} />
        </div>
    </div>

)

export default WalletGroup