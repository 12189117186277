export const intro_overall_contents = [
  {
    id: 'introduction',
    title: 'INTRODUCTION',
    contents: [
      'Hi there! We would like to extend the warmest of welcomes to ‘you’, a prospective subscriber of AEM Algorithm’s ‘AEM+’ mobile application. AEM Algorithm aims to bridge the gap between cryptocurrency and financial services in a market where there are currently no ‘easy-to-use’ cryptocurrency accounting tools.',
      'Before we provide you with the tools to immerse yourself into our innovative world, we want to ensure that you’re fully aware of your rights and obligations as an AEM+ user. Read through our ‘Terms and Conditions’, referring to each subheading for specific clarification, and tick ‘yes’ once you have fully understood and agree with the terms outlined.',
      'If you have any further questions or concerns, please feel free to contact our friendly team through any of the various channels outlined below.',
      'Thank you for your interest and we look forward to facilitating your cryptocurrency journey!'
    ]
  },
  {
    id: 'overview',
    title: 'OVERVIEW',
    contents: [
      'This website is operated by AEM Algorithm. Throughout the site, the terms “we”, “us” and “our” refer to AEM Algorithm. AEM Algorithm offers this application, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.'
    ],
  },
];

export const acceptanceContents = {
  id: 'acceptance',
  title: '1. Acceptance of terms',
  desc: [{
    details: [
      "These T&C (‘Term and Conditions’) are a legally binding contract between ‘you’ and us. As part of these T&C, you agree to comply with the most recent version of this document, which is incorporated by reference into these T&C. If you access or use the Services, or continue accessing or using the Services after being notified of a change to the T&C, you confirm that you have read, understand and agree to be bound by the T&C.",
    ]
  }],
};

export const privacyPoliceContents = {
  id: 'reference',
  title: '2. Reference to privacy policy',
  desc: [
    {
      subTitle: '2.1 Privacy Policy (Last updated: 1/04/2021)',
      details: [
        'AEM Algorithm ("us", "we", or "our") operates https://www.aemalgorithm.io/ (the "Site").',
        'This section informs you of our policies regarding the collection, use and disclosure of personal Information we receive from users of the Site. We use your Personal Information only for providing and improving the Site. By using the Site, you agree to the collection and use of information in accordance with this policy.',
      ],
    },
    {
      subTitle: '2.2 Information collection and use',
      details: [
        'While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to your name ("Personal Information").',
      ],
    },
    {
      subTitle: '2.3 Log Data',
      details: [
        "Like many site operators, we collect information that your browser sends whenever you visit our Site ('Log Data'). This Log Data may include information such as your computer's Internet Protocol ('IP') address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages and other statistics.",
      ],
    },
    {
      subTitle: '2.4 Communications',
      details: [
        'We may use your Personal Information to contact you with newsletters, marketing or promotional materials and other information that may help us to inform/update you about',
      ],
    },
    {
      subTitle: '2.5 Cookies',
      details: [
        'Cookies are files with a small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer\'s hard drive. Like many sites, we use "cookies" to collect information.',
      ],
    },
    {
      subTitle: '2.6 Security',
      details: [
        'The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.',
      ],
    },
  ],
};

export const susbscriptionContents = {
  id: 'subscription',
  title: '3. Subscription payments and billing',
  desc: [
    {
      subTitle: '3.1 Purchasing procedures:',
      details: [
        'AEM+ purchases will largely be ad-hoc, direct payments for services. Will feature a subscription or direct purchases - depending on the contractual agreements.',
      ],
    },
    {
      subTitle: '3.2 Seasonal promotions:',
      details: [
        'Promotional prices for AEM+ will be strictly limited to the designated timeline outlined in the relevant advertisement. Prices will only be offered during the relevant seasonal cycle. Once this period has expired, the offer will cease and no longer be available for purchase.',
      ],
    },
  ],
};

export const terminateAccount = {
  id: 'termination',
  title: '4. Refunds and/or termination of account',
  desc: [
    {
      subTitle:
        "4.1 You can easily terminate your subscription with one month's notice of cancellation. ",
      details: [
        'We may terminate your subscription as well with the same notice. If you violate these terms, we may terminate your subscription immediately. Due to the nature of our in-app purchases, AEM Algorithm will adhere to the terms and conditions set out by ‘Google Play’ and the ‘App Store’ - all refund processes associated with subscription, recurring and one-time in-app purchases.'
      ]
    },
    {
      subTitle: '4.2 User opted for cancellation of services:',
      details: [
        'Monthly subscription fees',
        'Cancellation at the beginning of the month.',
        'The user will have access to all features of the respective subscription service until the conclusion of the month. After such date, the contract between the provider and the user will expire and expectation of payment will cease.',
        'Yearly subscription services:',
        'The user will have access to all features of the respective subscription service until the conclusion of the month. After such date, the contract between the provider and the user will expire and expectation of payment will cease. ‘AEM Algorithm’ will refund the user on a prorata basis, in a lump sum, for the remaining months until the end of the year.',
      ],
    },
    {
      subTitle:
        "4.3 Chargeback.",
      details: [
        'If, at any time, we record a decline, chargeback or other rejection of a charge of any due and payable Subscription Fees on Customer’s Account, this will be considered as a breach of Customer’s payment obligations hereunder, and Customer’s use of the Service may bedisabled or terminated and such use of the Service will not resume until Customer re-subscribes for any such Service, and pay any applicable Subscription Fees in full, including any fees and expenses incurred by us and/or any Third Party Service for each',
        'Chargeback received (including handling and processing charges and fees incurred by the payment processor), without derogating from any other remedy that may be applicable to us under these Terms or applicable law.'
      ]
    },
  ],
};

export const rightCopyrightsContents = {
  id: 'copyrights',
  title: '5. Intellectual property rights and copyright',
  desc: [
    {
      subTitle: '5.1 Recognition of intellectual property ownership',
      details: [
        'The user, you, acknowledges that all content included in and/or made available through the ‘Journaler’ software, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data complications and software patents are the property of AEM Algorithm and are protected by Australian and international copyright laws.',
      ],
    },
    {
      subTitle: '5.2 Condition breaches of IP and copyright',
      details: [
        'You acknowledge that you cannot interfere with the proprietary interest of AEM Algorithm. Failure to comply with any of these provisions will lead to the termination and/or suspension of your account, disabling the use of any subscribed services. AEM Algorithm will not be liable for issuing a refund on the basis of this termination.',
      ],
    },
  ],
};

export const liabilityContents = {
  id: 'liability',
  title: '6. Indemnities and limitation of liability',
  desc: [
    {
      subTitle: '6.1 Limitation of liability pertaining to the consumer’s error',
      details: [
        'To the fullest extent of the Australian consumer law, AEM algorithm shall not be liable for any indirect, special, incidental, consequential, or exemplary damages arising from your use of the sites or any third party’s use of the sites. These exclusions include, without limitation, damages for lost profits, lost data, computer failure, or the violation of your rights by any third party, even if the services entities have been advised of the possibility thereof and regardless of the legal or equitable theory upon which the claim is based.',
      ],
    },
    {
      subTitle: '6.2 Limitation of liability pertaining to the functionality of individual blockchains',
      details: [
        'AEM algorithm forgos liability pertaining to data errors or delays in relation to individual blockchain streams. We don’t hold people’s fund, we are not a custodian in any way - we provide a tool for accessing someone’s wallet address - we are not responsible for their funds.',
      ],
    },
    {
      subTitle: '6.3 Application of consumer law',
      details: [
        'AEM Algorithm’s ‘AEM+’ product is a cryptocurrency accounting middleware application intended for use by businesses and consumers (b2b and c2b). To the maximum extent permitted by law, you hereby acknowledge and agree that consumer laws do not apply. If however any consumer laws (e.G. In Australia, the competition and consumer act 2010 (cth)) do apply and cannot otherwise be lawfully excluded, nothing in these user terms will restrict, exclude or modify any statutory warranties, guarantees, rights or remedies you have and our liability is limited (at our option) to the replacement, repair or resupply of the services or the pro-rata refund to the customer of pre-paid fees for your subscription covering the remainder of the year (only applicable to annual subscription).',
      ],
    },
  ],
};

export const changesContents = {
  id: 'changes',
  title: '7. Changes to the Terms and Conditions',
  desc: [
    {
      subTitle: '7.1 Changes to terms',
      details: [

        'This ‘Terms and conditions’ is effective as of 1/04/2021 and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page. We reserve the right to update or change our T&C at any time and you should check these T&C periodically. Your continued use of the Service after we post any modifications to the T&C on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified T&C. If we make any material changes to this T&C, we will notify you either through the email address you have provided us, or by placing a prominent notice on our website.',

      ],
    },
    {
      subTitle: '7.2 Contact Us',
      details: [

        'If you have any questions about these ‘Terms and Conditions’, please contact us.',

        'EMAIL : support@aemalgorithm.io',
        'LOCATION: RMIT Blockchain Innovation Hub, Lv 2, Building 97, 106 Victoria St, Carlton 3053, VIC, Australia',


      ],
    },
  ],
};



