
import currencies from '../../../assets/home/currencies.png';
import '../Home.css';

const SupportCryto = ({ isMobile }) => {
  return (
    <div className='divContent' >
      <div className='row d-flex align-items-center justify-content-center supported-currencies'>
        <div className='d-flex justify-content-center flex-column'>
          <div className='textdiv3'>With <span className='currency20home'>20,000</span> Supported <br></br> Cryptocurrencies</div>
          <div className='text2div2'>Tracking all of your crypto wallets in one<br></br> place is made possible with our full-<br></br>scale integrations.</div>
        </div>
        <div>
          <img className={isMobile ? 'img-mobile' : 'img-fluid'} src={currencies} alt='currency' />
        </div>
      </div>
    </div >
  )
};

export default SupportCryto;