import React, { useState } from 'react';
import './Home.css';

// images:
import Hero_image from '../../assets/home/Hero_image.png';
import aem_logo from '../../assets/home/aem_logo.svg';

// components:
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive';
import Integrations from '../journaler/main/components/Integrations';
import JournalerAEM from './components/JournalerAEM';
import JournalerFeature from './components/JournalerFeature';
import OurNetWork from './components/OurNetWork';
import Simplify from './components/Simplify';
import SupportCryto from './components/SupportCryto';

const homeContents = {
  // headerTitle: ['Blockchain Accounting', 'Software Made', 'For Your Business'],
  headerTitle: 'OPTIMISED CRYPTO ACCOUNTING',
  headerDesc:
    'AEM’s innovative software applications ease the pain associated with tracking, reconciling and handling your crypto wallets/assets',
  middleware: {
    aemIntro: `AEM Algorithm helps accountants and SMEs effectively report, track and manage their cryptocurrency transactions. 
    Our aim is to transform standard banking and
     manual processes for crypto accounting by pioneering unique automated solutions, allowing users to achieve more in less time.`,
    signup: 'Sign up to AEM Newsletter',
    desc:
      ['Join our community to receive early access to product', 'announcements, AEM news, updates & more.'],
  },
};

const Home = () => {
  const [chooseWallet, setChooseWallet] = useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const onChangeChooseWallet = (item) => {
    setChooseWallet(item);
  }

  return (
    <div className='fullHome'>
      <div className='divContent'>
        <div className='container' >
          <div class="row" style={{
            padding: '0px 0px 38px 0px'
          }}>
            <div className='col-12 col-xl-6'>
              <div className='containerContent'>
                {!isMobile && <img className='imgdivhome' src={aem_logo} width='300px' height='105px' />}
                <Helmet>
                  <title>AEM Algorithm | Crypto Accounting Software for Financial Reporting</title>
                  <meta name="Homepage" content="Report crypto in seconds with our automated accounting tools. I
                  ntegrated with Xero and Intuit QuickBooks. Made for enterprises & accountants. Get started for free today." />
                </Helmet>
                <div className='textdiv2'>
                  Bringing the future of crypto accounting to you
                </div>
                <div className='text2div2'>
                  <span>Our integrated accounting app lets you track your crypto wallets and reconcile transaction data in seconds.</span>
                </div>
                <div
                  style={{
                    cursor: 'pointer'
                  }}
                  rel='noreferrer'
                  onClick={() => window.open('https://journaler.aem.ac/user/login', '_blank')}
                  className='btnGroup_greenBgBtn_home' >
                  Get started with Journaler
                </div>
              </div>
            </div>
            <div className='col-12 col-xl-6'>
              <img className='imgdiv1' src={Hero_image} />
            </div>
          </div>
        </div>
      </div>
      <div className='pt-5'>
        <Simplify chooseWallet={onChangeChooseWallet} />
      </div>
      {!chooseWallet ? (
        <div className='connected'>
          <div className='container-fluid'>
            <Integrations isMobile={isMobile} />
          </div>
        </div>
      ) : (
        <div className='connected'>
          <div className='container'>
            <div className='text-center title-ournetwork '>Support for</div>
            <div className='text-center title-ournetwork'><span className='currency20home'>20,000+</span> cryptocurrencies</div>
            <div className='d-flex justify-content-center'>
              <div className='text-offer'>We offer a variety of cryptocurrency to store all your crypto wallets in one place.
                Get started in a minute and start managing your crypto payments on the go
              </div>
            </div>
          </div>
        </div>
      )}
      {!chooseWallet ? (
        <div className='pt-5 pb-5'>
          <JournalerFeature />
        </div>
      ) : (
        <div className='pt-5 pb-5'>
          <JournalerAEM />
        </div>
      )}
      <div className='container-ournetwork pt-3 pb-3'>
        <div className='container pt-4'>
          <div className='d-flex justify-content-between'>
            <div
              className='text-left title-ournetwork d-flex align-items-center'
            >
              Our network
            </div>
            <div
              className='See-all d-flex align-items-center'
              onClick={() => window.location.href = 'https://www.aemalgorithm.io/networks'}
            >
              [...]
            </div>
          </div>
          <OurNetWork />
        </div>
      </div>
      <SupportCryto isMobile={isMobile} />
    </div>
  );
};

export default Home;
