import React from 'react';
import '../style.css'

const Header = (props) => (
  <div>
    <div>
      <div className='container'>

        <div className='rounded mx-auto d-block pt-3 pt-md-5 pt-lg-5 pt-xl-5'>
          <img className='img-fluid rounded mx-auto d-block' src={props.content.img}>
          </img>
        </div>

        <div className='d-flex justify-content-center pt-3 pt-md-5 pt-lg-5 pt-xl-5'>
          <div className='titleUsecase'>
            {props.content.title} <span className='currency20home'>{props.content.gradient}</span>
          </div>
        </div>
        <div className='d-flex justify-content-center pt-5'>
          <div className='contentUsecase '>
            {props.content.des}
          </div>
        </div>
        <div className='d-flex justify-content-center pt-5 pb-5'>
          <a
            style={{ color: '#ffffff' }}
            className='btn btnGroup_greenBgBtn_usecase'
            href='https://journaler.aem.ac/'
            rel='noreferrer'
            target='_blank'
          >
            {props.content.btn}
          </a>
        </div>
      </div>


    </div>


  </div>
)

export default Header