export const currencies = [
  {
    id: 1,
    name: "Bitcoin",
    value: "BTC",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/1.png",
    chain: "Bitcoin ",
    contractAddress: "BTC",
    isActive: true,
  },
  {
    id: 2,
    name: "XEM",
    value: "XEM",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/873.png",
    chain: "NEM",
    contractAddress: "xem",
    isActive: true,
  },
  {
    id: 3,
    name: "Ethereum",
    value: "ETH",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
    chain: "Ethereum",
    contractAddress: "eth",
    isActive: true,
  },
  {
    id: 4,
    name: "Ethereum Internal",
    value: "ETH_INTERNAL",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
    chain: "Ethereum",
    contractAddress: "eth_internal",
    isActive: true,
  },
  {
    id: 6,
    name: "USDT",
    value: "ERC20USDT",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png",
    chain: "Ethereum",
    contractAddress: "erc20usdt",
    isActive: true,
  },
  {
    id: 7,
    name: "USDC",
    value: "ERC20USDC",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png",
    chain: "Ethereum",
    contractAddress: "erc20usdc",
    isActive: true,
  },
  {
    id: 8,
    name: "SHIB",
    value: "ERC20SHIB",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/5994.png",
    chain: "Ethereum",
    contractAddress: "erc20shib",
    isActive: true,
  },
  {
    id: 9,
    name: "CRO",
    value: "ERC20CRO",
    order: null,
    img: "https://etherscan.io/token/images/cro_32.png",
    chain: "Ethereum",
    contractAddress: "erc20cro",
    isActive: true,
  },
  {
    id: 10,
    name: "WBTC",
    value: "ERC20WBTC",
    order: null,
    img: "https://etherscan.io/token/images/wbtc_28.png?v=1",
    chain: "Ethereum",
    contractAddress: "erc20wbtc",
    isActive: true,
  },
  {
    id: 11,
    name: "DAI",
    value: "ERC20DAI",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png",
    chain: "Ethereum",
    contractAddress: "erc20dai",
    isActive: true,
  },
  {
    id: 12,
    name: "AXS",
    value: "ERC20AXS",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/6783.png",
    chain: "Ethereum",
    contractAddress: "erc20axs",
    isActive: true,
  },
  {
    id: 13,
    name: "LINK",
    value: "ERC20LINK",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/1975.png",
    chain: "Ethereum",
    contractAddress: "erc20link",
    isActive: true,
  },
  {
    id: 14,
    name: "UNI",
    value: "ERC20UNI",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/7083.png",
    chain: "Ethereum",
    contractAddress: "erc20uni",
    isActive: true,
  },
  {
    id: 15,
    name: "MANA",
    value: "ERC20MANA",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/1966.png",
    chain: "Ethereum",
    contractAddress: "erc20mana",
    isActive: true,
  },
  {
    id: 16,
    name: "SUSHI",
    value: "ERC20SUSHI",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/6758.png",
    chain: "Ethereum",
    contractAddress: "erc20sushi",
    isActive: true,
  },
  {
    id: 17,
    name: "YFI",
    value: "ERC20YFI",
    order: null,
    img: "https://etherscan.io/token/images/yfi_32_2.png",
    chain: "Ethereum",
    contractAddress: "erc20yfi",
    isActive: true,
  },
  {
    id: 18,
    name: "AAVE",
    value: "ERC20AAVE",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/7278.png",
    chain: "Ethereum",
    contractAddress: "erc20aave",
    isActive: true,
  },
  {
    id: 19,
    name: "GALA",
    value: "ERC20GALA",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/7080.png",
    chain: "Ethereum",
    contractAddress: "erc20gala",
    isActive: true,
  },
  {
    id: 20,
    name: "QNT",
    value: "ERC20QNT",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/3155.png",
    chain: "Ethereum",
    contractAddress: "erc20qnt",
    isActive: true,
  },
  {
    id: 21,
    name: "MKR",
    value: "ERC20MKR",
    order: null,
    img: "https://etherscan.io/token/images/mkr-etherscan-35.png",
    chain: "Ethereum",
    contractAddress: "erc20mkr",
    isActive: true,
  },
  {
    id: 22,
    name: "renBTC",
    value: "ERC20RENBTC",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/5777.png",
    chain: "Ethereum",
    contractAddress: "erc20renbtc",
    isActive: true,
  },
  {
    id: 23,
    name: "STETH",
    value: "ERC20STETH",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/8085.png",
    chain: "Ethereum",
    contractAddress: "erc20steth",
    isActive: true,
  },
  {
    id: 24,
    name: "HBTC",
    value: "ERC20HBTC",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/6941.png",
    chain: "Ethereum",
    contractAddress: "erc20hbtc",
    isActive: true,
  },
  {
    id: 25,
    name: "GRT",
    value: "ERC20GRT",
    order: null,
    img: "https://etherscan.io/token/images/TheGraph_32.png",
    chain: "Ethereum",
    contractAddress: "erc20grt",
    isActive: true,
  },
  {
    id: 26,
    name: "CRV",
    value: "ERC20CRV",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/6538.png",
    chain: "Ethereum",
    contractAddress: "erc20crv",
    isActive: true,
  },
  {
    id: 27,
    name: "TAMA",
    value: "ERC20TAMA",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/21968.png",
    chain: "Ethereum",
    contractAddress: "erc20tama",
    isActive: true,
  },
  {
    id: 28,
    name: "Wrapped Ether",
    value: "ERC20WETH",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/2396.png",
    chain: "Ethereum",
    contractAddress: "weth",
    isActive: true,
  },
  {
    id: 29,
    name: "Gnosis",
    value: "ERC20GNO",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/1659.png",
    chain: "Ethereum",
    contractAddress: "gno",
    isActive: true,
  },
  {
    id: 30,
    name: "Reflexer Ungovernance Token",
    value: "ERC20FLX",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/9493.png",
    chain: "Ethereum",
    contractAddress: "erc20flx",
    isActive: true,
  },
  {
    id: 31,
    name: "MetaFactory",
    value: "ERC20ROBOT",
    order: null,
    img: "https://etherscan.io/token/images/metafactory_32.png",
    chain: "Ethereum",
    contractAddress: "erc20robot",
    isActive: true,
  },
  {
    id: 32,
    name: "Vera",
    value: "ERC20VERA",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/12044.png",
    chain: "Ethereum",
    contractAddress: "vera",
    isActive: true,
  },
  {
    id: 33,
    name: "DAOhaus",
    value: "ERC20HAUS",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/9016.png",
    chain: "Ethereum",
    contractAddress: "erc20haus",
    isActive: true,
  },
  {
    id: 34,
    name: "Rai Reflex Index",
    value: "ERC20RAI",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/8525.png",
    chain: "Ethereum",
    contractAddress: "erc20rai",
    isActive: true,
  },
  {
    id: 35,
    name: "STAKE",
    value: "ERC20STAKE",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/5601.png",
    chain: "Ethereum",
    contractAddress: "erc20stake",
    isActive: true,
  },
  {
    id: 36,
    name: "BNB",
    value: "BNB",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png",
    chain: "BSC",
    contractAddress: "bnb",
    isActive: true,
  },
  {
    id: 37,
    name: "Binance-Peg BUSD Token",
    value: "BEP20BUSD",
    order: null,
    img: "https://bscscan.com/token/images/busd_32_2.png",
    chain: "BSC",
    contractAddress: "bep20busd",
    isActive: true,
  },
  {
    id: 38,
    name: "Binance-Peg Ethereum Token",
    value: "BEP20ETH",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
    chain: "BSC",
    contractAddress: "bep20eth",
    isActive: true,
  },
  {
    id: 39,
    name: "USDT",
    value: "BEP20USDT",
    order: null,
    img: "https://bscscan.com/token/images/busdt_32.png",
    chain: "BSC",
    contractAddress: "bep20usdt",
    isActive: true,
  },
  {
    id: 40,
    name: "LINK",
    value: "BEP20LINK",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/1975.png",
    chain: "BSC",
    contractAddress: "bep20link",
    isActive: true,
  },
  {
    id: 41,
    name: "BCH",
    value: "BEP20BCH",
    order: null,
    img: "https://bscscan.com/token/images/bitcoincash_32.png",
    chain: "BSC",
    contractAddress: "bep20bch",
    isActive: true,
  },
  {
    id: 42,
    name: "ADA",
    value: "BEP20ADA",
    order: null,
    img: "https://bscscan.com/token/images/cardano_32.png",
    chain: "BSC",
    contractAddress: "bep20ada",
    isActive: true,
  },
  {
    id: 43,
    name: "EOS",
    value: "BEP20EOS",
    order: null,
    img: "https://bscscan.com/token/images/eos_32.png",
    chain: "BSC",
    contractAddress: "bep20eos",
    isActive: true,
  },
  {
    id: 44,
    name: "TRX",
    value: "BEP20TRX",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/1958.png",
    chain: "BSC",
    contractAddress: "bep20trx",
    isActive: true,
  },
  {
    id: 45,
    name: "ATOM",
    value: "BEP20ATOM",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/3794.png",
    chain: "BSC",
    contractAddress: "bep20atom",
    isActive: true,
  },
  {
    id: 46,
    name: "Binance-Peg BSC-USD",
    value: "BEP20BSCUSD",
    order: null,
    img: "https://bscscan.com/token/images/busdt_32.png",
    chain: "BSC",
    contractAddress: "bep20bscusd",
    isActive: true,
  },
  {
    id: 47,
    name: "Acet Token",
    value: "BEP20ACT",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/11706.png",
    chain: "BSC",
    contractAddress: "bep20act",
    isActive: true,
  },
  {
    id: 48,
    name: "Binance-Peg BTCB Token",
    value: "BEP20BTC",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/1.png",
    chain: "BSC",
    contractAddress: "bep20btc",
    isActive: true,
  },
  {
    id: 49,
    name: "XRP",
    value: "BEP20XRP",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/52.png",
    chain: "BSC",
    contractAddress: "bep20xrp",
    isActive: true,
  },
  {
    id: 50,
    name: "DOT",
    value: "BEP20DOT",
    order: null,
    img: "https://bscscan.com/token/images/polkadot_32.png",
    chain: "BSC",
    contractAddress: "bep20dot",
    isActive: true,
  },
  {
    id: 51,
    name: "LTC",
    value: "BEP20LTC",
    order: null,
    img: "https://bscscan.com/token/images/litecoin_32.png",
    chain: "BSC",
    contractAddress: "bep20ltc",
    isActive: true,
  },
  {
    id: 52,
    name: "XTZ",
    value: "BEP20XTZ",
    order: null,
    img: "https://bscscan.com/token/images/tezos_32.png",
    chain: "BSC",
    contractAddress: "bep20xtz",
    isActive: true,
  },
  {
    id: 53,
    name: "Vera",
    value: "BEP20VERA",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/12044.png",
    chain: "BSC",
    contractAddress: "bep20vera",
    isActive: true,
  },
  {
    id: 54,
    name: "POLYGON",
    value: "MATIC",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png",
    chain: "Polygon",
    contractAddress: "matic",
    isActive: true,
  },
  {
    id: 55,
    name: " ChainLink Token (LINK)",
    value: "ERC20POLYGONLINK",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/1975.png",
    chain: "Polygon",
    contractAddress: "erc20polygonlink",
    isActive: true,
  },
  {
    id: 56,
    name: "AAVE",
    value: "ERC20POLYGONAAVE",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/7278.png",
    chain: "Polygon",
    contractAddress: "erc20polygonaave",
    isActive: true,
  },
  {
    id: 57,
    name: "DAI",
    value: "ERC20POLYGONDAI",
    order: null,
    img: "https://polygonscan.com/token/images/mcdDai_32.png",
    chain: "Polygon",
    contractAddress: "erc20polygondai",
    isActive: true,
  },
  {
    id: 58,
    name: "MANA",
    value: "ERC20POLYGONMANA",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/1966.png",
    chain: "Polygon",
    contractAddress: "erc20polygonmana",
    isActive: true,
  },
  {
    id: 59,
    name: "Wrapped Ether",
    value: "ERC20POLYGONWETH",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/2396.png",
    chain: "Polygon",
    contractAddress: "ERC20POLYGONWETH",
    isActive: true,
  },
  {
    id: 60,
    name: "USD Coin (PoS)",
    value: "ERC20POLYGONUSDC",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png",
    chain: "Polygon",
    contractAddress: "ERC20POLYGONUSDC",
    isActive: true,
  },
  {
    id: 61,
    name: "ERC20POLYGONFCD",
    value: "ERC20POLYGONFCD",
    order: null,
    img: "https://polygonscan.com/token/images/freshcutdiamond_32.png",
    chain: "Polygon",
    contractAddress: "ERC20POLYGONFCD",
    isActive: true,
  },
  {
    id: 62,
    name: "(PoS) Tether USD",
    value: "ERC20POLYGONUSDT",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png",
    chain: "Polygon",
    contractAddress: "ERC20POLYGONUSDT",
    isActive: true,
  },
  {
    id: 63,
    name: "MetaSoccer Universe",
    value: "ERC20POLYGONMSU",
    order: null,
    img: "https://polygonscan.com/token/images/metasocceruniverse_32.png",
    chain: "Polygon",
    contractAddress: "ERC20POLYGONMSU",
    isActive: true,
  },
  {
    id: 64,
    name: "SOLANA",
    value: "SOL",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png",
    chain: "Solana",
    contractAddress: "sol",
    isActive: true,
  },
  {
    id: 65,
    name: "USDT",
    value: "SPLUSDT",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png",
    chain: "Solana",
    contractAddress: "splusdt",
    isActive: true,
  },
  {
    id: 66,
    name: "USDC",
    value: "SPLUSDC",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png",
    chain: "Solana",
    contractAddress: "splusdc",
    isActive: true,
  },
  {
    id: 67,
    name: "Serum",
    value: "SPLSRM",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/6187.png",
    chain: "Solana",
    contractAddress: "splsrm",
    isActive: true,
  },
  {
    id: 68,
    name: "Ether (Portal)",
    value: "SPLWETH",
    order: null,
    img: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs/logo.png",
    chain: "Solana",
    contractAddress: "spleth",
    isActive: true,
  },
  {
    id: 69,
    name: "WAGMI",
    value: "SPLWAGMI",
    order: null,
    img: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3m7A2A8HHdqmiDrjAfaddj7Hxd88FrBHA1KSoqjoELtu/logo.png",
    chain: "Solana",
    contractAddress: "splwagmi",
    isActive: true,
  },
  {
    id: 70,
    name: "MatrixETF DAO Finance",
    value: "SPLMDF",
    order: null,
    img: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ALQ9KMWjFmxVbew3vMkJj3ypbAKuorSgGst6svCHEe2z/logo.png",
    chain: "Solana",
    contractAddress: "splmdf",
    isActive: true,
  },
  {
    id: 71,
    name: "Port Finance Token",
    value: "SPLPORT",
    order: null,
    img: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PoRTjZMPXb9T7dyU7tpLEZRQj7e6ssfAE62j2oQuc6y/PORT.png",
    chain: "Solana",
    contractAddress: "splport",
    isActive: true,
  },
  {
    id: 72,
    name: "Waggle Network",
    value: "SPLWAG",
    order: null,
    img: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5tN42n9vMi6ubp67Uy4NnmM5DMZYN8aS8GeB3bEDHr6E/logo.png",
    chain: "Solana",
    contractAddress: "splwag",
    isActive: true,
  },
  {
    id: 73,
    name: "NOVA FINANCE",
    value: "SPLNOVA",
    order: null,
    img: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BDrL8huis6S5tpmozaAaT5zhE5A7ZBAB2jMMvpKEeF8A/logo.svg",
    chain: "Solana",
    contractAddress: "splnova",
    isActive: true,
  },
  {
    id: 74,
    name: "Wrapped Bitcoin (Sollet)",
    value: "SPLBTC",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/1.png",
    chain: "Solana",
    contractAddress: "splbtc",
    isActive: true,
  },
  {
    id: 75,
    name: "LUNA (Portal)",
    value: "SPLLUNA",
    order: null,
    img: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F6v4wfAdJB8D8p77bMXZgYt8TDKsYxLYxH5AFhUkYx9W/logo.png",
    chain: "Solana",
    contractAddress: "splluna",
    isActive: true,
  },
  {
    id: 76,
    name: "Cyclos",
    value: "SPLCYS",
    order: null,
    img: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BRLsMczKuaR5w9vSubF4j8HwEGGprVAyyVgS4EX7DKEg/logo.svg",
    chain: "Solana",
    contractAddress: "splcys",
    isActive: true,
  },
  {
    id: 77,
    name: "SolChicks",
    value: "SPLCHICKS",
    order: null,
    img: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/cxxShYRVcepDudXhe7U62QHvw8uBJoKFifmzggGKVC2/logo.png",
    chain: "Solana",
    contractAddress: "SPLCHICKS",
    isActive: true,
  },
  {
    id: 78,
    name: "Solana INU",
    value: "SPLINU",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/14398.png",
    chain: "Solana",
    contractAddress: "spllinu",
    isActive: true,
  },
  {
    id: 79,
    name: "Phantasia",
    value: "SPLFANT",
    order: null,
    img: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FANTafPFBAt93BNJVpdu25pGPmca3RfwdsDsRrT3LX1r/logo.png",
    chain: "Solana",
    contractAddress: "splfant",
    isActive: true,
  },
  {
    id: 80,
    name: "CropperFinance",
    value: "SPLCRP",
    order: null,
    img: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DubwWZNWiNGMMeeQHPnMATNj77YZPZSAz2WVR5WjLJqz/logo.png",
    chain: "Solana",
    contractAddress: "splcrp",
    isActive: true,
  },
  {
    id: 81,
    name: "Apricot",
    value: "SPLAPT",
    order: null,
    img: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/APTtJyaRX5yGTsJU522N4VYWg3vCvSb65eam5GrPT5Rt/logo.svg",
    chain: "Solana",
    contractAddress: "splapt",
    isActive: true,
  },
  {
    id: 82,
    name: "Sonar Watch",
    value: "SPLSONAR",
    order: null,
    img: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/sonarX4VtVkQemriJeLm6CKeW3GDMyiBnnAEMw1MRAE/logo.png",
    chain: "Solana",
    contractAddress: "splsonar",
    isActive: true,
  },
  {
    id: 83,
    name: "FANTOM",
    value: "FTM",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/3513.png",
    chain: "Fantom",
    contractAddress: "ftm",
    isActive: true,
  },
  {
    id: 84,
    name: "USDC",
    value: "ERC20FANTOMUSDC",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png",
    chain: "Fantom",
    contractAddress: "erc20fantomusdc",
    isActive: true,
  },
  {
    id: 85,
    name: "TRON",
    value: "TRX",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/1958.png",
    chain: "Tron",
    contractAddress: "trx",
    isActive: true,
  },
  {
    id: 86,
    name: "USDT",
    value: "TRC20USDT",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png",
    chain: "Tron",
    contractAddress: "trc20usdt",
    isActive: true,
  },
  {
    id: 87,
    name: "USDC",
    value: "TRC20USDC",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png",
    chain: "Tron",
    contractAddress: "trc20usdc",
    isActive: true,
  },
  {
    id: 88,
    name: "XRP",
    value: "XRP",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/52.png",
    chain: "XRP",
    contractAddress: "xrp",
    isActive: true,
  },
  {
    id: 91,
    name: "Polkadot",
    value: "DOT",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/6636.png",
    chain: "Subscan",
    contractAddress: "1",
    isActive: true,
  },
  {
    id: 92,
    name: "Kusama",
    value: "KSM",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/5034.png",
    chain: "Subscan",
    contractAddress: "1",
    isActive: true,
  },
  {
    id: 93,
    name: "Symbol",
    value: "XYM",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/8677.png",
    chain: "Symbol",
    contractAddress: "xym",
    isActive: true,
  },
  {
    id: 94,
    name: "Astar",
    value: "ASTR",
    order: null,
    img: "https://s2.coinmarketcap.com/static/img/coins/200x200/12885.png",
    chain: "Subscan",
    contractAddress: "1",
    isActive: true,
  },
  {
    "id": 95,
    "name": "Aragon",
    "value": "ERC20ANT",
    "order": null,
    "img": "https://s2.coinmarketcap.com/static/img/coins/64x64/1680.png",
    "chain": "Ethereum",
    "contractAddress": "erc20ant",
    "isActive": true
  },
  {
    "id": 96,
    "name": "Gitcoin",
    "value": "ERC20GTC",
    "order": null,
    "img": "https://s2.coinmarketcap.com/static/img/coins/64x64/10052.png",
    "chain": "Ethereum",
    "contractAddress": "erc20gtc",
    "isActive": true
  }
];
