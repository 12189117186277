import React from 'react';
import HeaderImg from '../../assets/journaler/journalerLogoWhite.svg'

const ArticleHeader = () => {
  return (
    <>
      <div className='globalHeaderBg helpArticleHeader text-center'>
        <img src={HeaderImg} alt='header image' width={250}/>
      </div>
    </>
  );
};

export default ArticleHeader;
