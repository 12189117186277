// styles
import { profile, Advisors } from "./Profile";
import "./Team.css";
import Open from "../../../assets/Team/Open.png";


const TeamLandingPage = () => {

  const TeamMembers = ({ photo, name, title, social, des }) => (
    <div className="flip-box">
      <div className="flip-box-inner">
        <div className="flip-box-front">
          <img class='img-fluid w-100'
            src={photo}
            alt={name}

          />
        </div>
        <div className="flip-box-back">
          <div className="containAll">
            <img class='img-fluid w-100 brightness'
              src={photo}
              alt={name}
              style={{ filter: 'brightness' }}
            />
            <div class="bottom-left">
              <div className="container-name">
                <div className="nameCard">
                  {name}
                </div>
                <div className="titleCard">
                  {title}
                </div>
              </div>
            </div>
            <div class="top-right">
              {social &&
                (<a href={social} target="_blank" rel="noreferrer">
                  <img
                    style={{ cursor: 'pointer' }}
                    src={Open}
                    alt={name}
                  />
                </a>)
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="container-fluid landingPageContainer">
      <div className="container pb-5">
        <div className="team-page-title">
          MEET THE TEAM
        </div>
      </div>
      <div>
        <div className='row '>
          {profile.map((profile) => (
            <div className='col-3 nopadding'>
              <TeamMembers
                photo={profile.photo}
                name={profile.name}
                title={profile.title}
                social={profile.linkedin && profile.linkedin}
                des={profile.des} />
            </div>
          ))}
        </div>
      </div>
      <div className="team-page-title_2">
        Advisors
      </div>
      <div>
        <div className='row '>
          {Advisors.map((profile) => (
            <div className='col-3 nopadding'>
              <TeamMembers
                photo={profile.photo}
                name={profile.name}
                title={profile.title}
                social={profile.linkedin || profile.twitter}
                des={profile.des} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default TeamLandingPage
