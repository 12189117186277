import React from 'react';
import { Link } from 'react-router-dom';
import { MdKeyboardArrowRight } from 'react-icons/md';

import TwoBtns from './TwoBtns';

const stepsData = {
  des: 'Easy 4 step process',
  title: 'How to get started with your Xero integration',
  contents: [
    {
      id: 1,
      title: '01',
      highlight: 'Signup ',
      text: 'to Journaler',
    },
    {
      id: 2,
      title: '02',
      highlight: 'Add ',
      text: ' your wallets and exchanges',
    },
    {
      id: 3,
      title: '03',
      highlight: 'Enable ',
      text: ' Xero integration',
    },
    {
      id: 4,
      title: '04',
      highlight: 'Connect ',
      text: ' Xero organisation',
    },
    {
      id: 5,
      title: 'Enjoy!',
      highlight: ' ',
      text: 'Let Journaler track & reconcile your crypto transactions!',
    },
  ],
  helpLink: 'Learn more',
};

const Step = (props) => (
  <div className='card border-0' style={{ background: '#F7F7F7' }}>
    <div className='card-body'>
      <h5 className='card-title card_title'>
        <u className='border_bottom pb-1'>{props.title}</u>
      </h5>
      <p className='card-text text'>
        <b>{props.highlight}</b>
        {props.text}
      </p>
    </div>
  </div>
);

const IntegrateSteps = () => {
  return (
    <div style={{ background: '#F7F7F7' }} className='py-5 '>
      <p className='text-center text lightText'>{stepsData.des} </p>
      <h2 className='text-center title titlestepsData'>{stepsData.title} </h2>

      <div className='container text-center py-4'>
        <div className='row py-4'>
          {stepsData.contents.map((step) => (
            <div className='col' key={step.id}>
              <Step title={step.title} highlight={step.highlight} text={step.text} />
            </div>
          ))}
        </div>
        {/* <div style={{ textAlign: 'center' }}>
          <Link to='/journaler/help' className=' text-color text'>
            {stepsData.helpLink} <MdKeyboardArrowRight />
          </Link>
        </div> */}
        <TwoBtns />
      </div>
    </div>
  );
};

export default IntegrateSteps;
