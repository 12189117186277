/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import './ComparePlans.css';
import up from '../../../../assets/pricing/up.png';
import checkPlan from '../../../../assets/pricing/checkPlan.png';
import down from '../../../../assets/pricing/down.png'

const ComparePlans = ({ durationMode, currency }) => {
    const [expandedRows, setExpandedRows] = useState([]);
    const dataHeaderMonth = [
        {
            key: 0,
            plan: 'Monthly pricing',
            pricing: '',
            duration: ''
        },
        {
            key: 1,
            plan: 'Compact',
            pricing: currency === 'usd' ? '$9' : '$15',
            duration: '/monthly'
        },
        {
            key: 2,
            plan: 'Starter',
            pricing: currency === 'usd' ? '$49' : '$79',
            duration: '/monthly'
        },
        {
            key: 3,
            plan: 'Premium',
            pricing: currency === 'usd' ? '$99' : '$159',
            duration: '/monthly'
        },
        {
            key: 4,
            plan: 'Pro',
            pricing: currency === 'usd' ? '$199' : '$320',
            duration: '/monthly'
        },
        {
            key: 5,
            plan: 'Enterprise',
            pricing: 'Contact Us',
            duration: ''
        },
    ]
    const dataHeaderYear = [
        {
            key: 0,
            plan: 'Yearly pricing',
            pricing: '',
            duration: ''
        },
        {
            key: 1,
            plan: 'Compact',
            pricing: currency === 'usd' ? '$69' : '$109',
            duration: '/yearly'
        },
        {
            key: 2,
            plan: 'Starter',
            pricing: currency === 'usd' ? '$389' : '$629',
            duration: '/yearly'
        },
        {
            key: 3,
            plan: 'Premium',
            pricing: currency === 'usd' ? '$769' : '$1239',
            duration: '/yearly'
        },
        {
            key: 4,
            plan: 'Pro',
            pricing: currency === 'usd' ? '$1539' : '$2479',
            duration: '/yearly'
        },
        {
            key: 5,
            plan: 'Enterprise',
            pricing: 'Contact Us',
            duration: ''
        },
    ]

    const data = [
        {
            key: 1,
            pricing: 'Blockchain Wallets',
            compact: durationMode === 'monthly' ? '2' : '2 ',
            starter: durationMode === 'monthly' ? '5' : '5',
            premium: durationMode === 'monthly' ? '10' : '10',
            pro: durationMode === 'monthly' ? '20' : '20',
            enterprise: 'Customisable',
            children: [
                {
                    key: 10,
                    pricing: 'Sub-Wallets',
                    compact: 'Unlimited',
                    starter: 'Unlimited',
                    premium: 'Unlimited',
                    pro: 'Unlimited',
                    enterprise: 'Unlimited',
                },
                {
                    key: 11,
                    pricing: 'Transactions',
                    compact: durationMode === 'monthly' ? '100' : '100 /month',
                    starter: durationMode === 'monthly' ? '500' : '500 /month',
                    premium: durationMode === 'monthly' ? '5,000' : '5,000 /month',
                    pro: durationMode === 'monthly' ? '10,000' : '10,000 /month',
                    enterprise: 'Customisable',
                },
                {
                    key: 12,
                    pricing: 'Transactions Exports',
                    compact: durationMode === 'monthly' ? '10' : '10 /month',
                    starter: durationMode === 'monthly' ? '10' : '10 /month',
                    premium: durationMode === 'monthly' ? '100' : '100 /month',
                    pro: durationMode === 'monthly' ? '1,000' : '1,000 /month',
                    enterprise: 'Unlimited',
                },
            ]
        },
        {
            key: 2,
            pricing: 'Imported Wallets',
            compact: 'Unlimited',
            starter: 'Unlimited',
            premium: 'Unlimited',
            pro: 'Unlimited',
            enterprise: 'Unlimited',
            children: [
                {
                    key: 13,
                    pricing: 'Transactions',
                    compact: durationMode === 'monthly' ? '100' : '100 /month',
                    starter: durationMode === 'monthly' ? '500' : '500 /month',
                    premium: durationMode === 'monthly' ? '5,000' : '5,000 /month',
                    pro: durationMode === 'monthly' ? '10,000' : '10,000 /month',
                    enterprise: 'Customisable',
                },

            ]
        },
        {
            key: 3,
            pricing: 'Tax and portfolio',
            compact: true,
            starter: true,
            premium: true,
            pro: true,
            enterprise: true,
            children: [
                {
                    key: 16,
                    pricing: 'Tax Calculator (FIFO, LIFO, HIFO, ACB)',
                    compact: true,
                    starter: true,
                    premium: true,
                    pro: true,
                    enterprise: true,
                },

            ]
        },
        {
            key: 4,
            pricing: 'Xero and QuickBooks Integration',
            compact: false,
            starter: true,
            premium: true,
            pro: true,
            enterprise: true,
            children: [
                {
                    key: 18,
                    pricing: 'Transaction/ Bank Feed',
                    compact: false,
                    starter: true,
                    premium: true,
                    pro: true,
                    enterprise: true,
                },
                {
                    key: 19,
                    pricing: 'Invoicing (Accounts Receivable)',
                    compact: false,
                    starter: true,
                    premium: true,
                    pro: true,
                    enterprise: true,
                },
                {
                    key: 20,
                    pricing: 'Bills (Accounts Payables)',
                    compact: false,
                    starter: true,
                    premium: true,
                    pro: true,
                    enterprise: true,
                },
                {
                    key: 21,
                    pricing: 'Journals (Capital Gains Tax)',
                    compact: false,
                    starter: true,
                    premium: true,
                    pro: true,
                    enterprise: true,
                },
                {
                    key: 22,
                    pricing: 'Journals (Wallet Balance)',
                    compact: false,
                    starter: true,
                    premium: true,
                    pro: true,
                    enterprise: true,
                },
            ]
        },
        {
            key: 5,
            pricing: 'Collaboration settings',
            compact: false,
            starter: true,
            premium: true,
            pro: true,
            enterprise: true,
            children: [
                {
                    key: 18,
                    pricing: 'Additional company',
                    compact: false,
                    starter: false,
                    premium: (<>
                        <div style={{ fontWeight: 'bold' }}>2 </div> <div style={{ color: 'gray', paddingTop: '5px', fontSize: 14 }}> {durationMode === 'monthly' ? '$79' : '$79 /month'} for each additional company profile</div>
                    </>),
                    pro: (<>
                        <div style={{ fontWeight: 'bold' }}>4 </div><div style={{ color: 'gray', paddingTop: '5px', fontSize: 14 }}>{durationMode === 'monthly' ? '$79' : '$79 /month'} for each additional company profile</div>
                    </>),
                    enterprise: 'Customisable',
                },
                {
                    key: 19,
                    pricing: 'Additional user',
                    compact: false,
                    starter: (<>
                        <span style={{ fontWeight: 'bold' }}>1 </span> <span style={{ color: 'gray', paddingTop: '5px', fontSize: 14 }}>Users/ company</span>
                    </>),
                    premium: (<>
                        <span style={{ fontWeight: 'bold' }}>2 </span> <span style={{ color: 'gray', paddingTop: '5px', fontSize: 14 }}>Users/ company</span>
                    </>),
                    pro: (<>
                        <span style={{ fontWeight: 'bold' }}>3 </span> <span style={{ color: 'gray', paddingTop: '5px', fontSize: 14 }}>Users/ company</span>
                    </>),
                    enterprise: 'Customisable',
                },


            ]
        },
        {
            key: 6,
            pricing: 'Support',
            compact: true,
            starter: true,
            premium: true,
            pro: true,
            enterprise: true,
            children: [],
        },
        {
            key: 7,
            pricing: 'Live Training',
            compact: false,
            starter: false,
            premium: true,
            pro: true,
            enterprise: true,
            children: [],
        },
    ];
    const toggleRow = (index) => {
        const isRowExpanded = expandedRows.includes(index);
        if (isRowExpanded) {
            setExpandedRows(expandedRows.filter((rowIndex) => rowIndex !== index));
        } else {
            setExpandedRows([...expandedRows, index]);
        }
    };
    let dataHeader = dataHeaderMonth;
    if (durationMode !== 'monthly') {
        dataHeader = dataHeaderYear;
    }

    return (
        <div className="pt-5">
            <div className="title-plan">Compare plans</div>
            <div className="mt-5 mobile-table" >
                <table className="table table-bordered">
                    <thead className='tableContainer'>
                        <tr >
                            {dataHeader.map((data, index) => (
                                data.key === 0 ? (
                                    <th scope="col" style={{ width: '25%' }} className='th-header '>
                                        <div className='planName pt-3 pl-5'>{data.plan}</div>

                                    </th>
                                ) : (
                                    <th scope="col" style={{ width: '15%' }} className='pt-3 pl-3'>
                                        <div className='planName'>{data.plan}</div>
                                        <div className='plan-pricing pt-3'>{data.key == 5 ? (<span className='planNamect'>Contact us</span>) :
                                            (<>{data.pricing} <span className='plan-duration'>{data.duration}</span></>)
                                        }

                                        </div>
                                        <div className='pt-3 d-flex justify-content-center' >
                                            {data.key === 5 ? (<button className='btn-start-now' onClick={(e) => {
                                                e.preventDefault();
                                                window.location.href = '/contact';
                                            }}>Contact us</button>) :
                                                (
                                                    <button className='btn-start-now' onClick={(e) => {
                                                        e.preventDefault();
                                                        window.location.href = 'https://journaler.aem.ac/user/register';
                                                    }}>Start now for free</button>

                                                )}
                                        </div>
                                    </th>

                                )
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((data, index) => (
                            <React.Fragment key={index}>
                                <tr
                                    data-toggle="collapse"
                                    data-target={`#row${index}`}
                                    className="clickable"
                                    onClick={() => toggleRow(index)}
                                >
                                    <th scope="row" style={{ padding: '22px 22px 22px 60px' }}>
                                        <div className='d-flex'>
                                            <div>
                                                {data.children.length > 0 && (
                                                    <img className='pr-3' src={expandedRows.includes(index) ? up : down} alt="arrow" />
                                                )}{' '}
                                            </div>

                                            <div>
                                                {data.pricing}
                                            </div>
                                        </div>

                                    </th>
                                    <th scope="row ">{data.compact === true ? (<img className='check-plan' src={checkPlan}></img>) :
                                        (<div className='text-detail'>{data.compact}</div>)} </th>
                                    <th scope="row">{data.starter === true ? (<img className='check-plan' src={checkPlan}></img>) : (<div className='text-detail'>{data.starter}</div>)}</th>
                                    <th scope="row">{data.premium === true ? (<img className='check-plan' src={checkPlan}></img>) : (<div className='text-detail'>{data.premium}</div>)}</th>
                                    <th scope="row">{data.pro === true ? (<img className='check-plan' src={checkPlan}></img>) : (<div className='text-detail'>{data.pro}</div>)}</th>
                                    <th scope="row">{data.enterprise === true ? (<img className='check-plan-ent' src={checkPlan}></img>) : (<div className='text-detail-ent'>{data.enterprise} </div>)}</th>

                                </tr>
                                {
                                    data.children.map((dataExpand, indexExpand) => (
                                        <tr id={`row${index}`} className="collapse" key={indexExpand}>
                                            <td className='pr-3' style={{ padding: '22px 22px 22px 100px' }}>{dataExpand.pricing}</td>
                                            <td ><div className='text-detail'>{dataExpand.compact === true ? (<img src={checkPlan}></img>) : (dataExpand.compact)}</div></td>
                                            <td ><div className='text-detail'> {dataExpand.starter === true ? (<img src={checkPlan}></img>) : (dataExpand.starter)} </div></td>
                                            <td ><div className='text-detail'>{dataExpand.premium === true ? (<img src={checkPlan}></img>) : dataExpand.premium} </div></td>
                                            <td ><div className='text-detail'>{dataExpand.pro === true ? (<img src={checkPlan}></img>) : dataExpand.pro} </div></td>
                                            <td ><div className='text-detail-ent'>{dataExpand.enterprise === true ? (<img src={checkPlan}></img>) : dataExpand.enterprise} </div></td>
                                        </tr>
                                    ))

                                }

                            </React.Fragment>
                        ))}
                        <td> </td>
                        <td> <div className='pt-3 paddingBtn'>
                            <button className='btn-start-now' onClick={(e) => {
                                e.preventDefault();
                                window.location.href = 'https://journaler.aem.ac/user/register';
                            }}>Start now for free</button>
                        </div></td>
                        <td> <div className='pt-3 paddingBtn'>
                            <button className='btn-start-now' onClick={(e) => {
                                e.preventDefault();
                                window.location.href = 'https://journaler.aem.ac/user/register';
                            }}>Start now for free</button>
                        </div></td>
                        <td> <div className='pt-3 paddingBtn'>
                            <button className='btn-start-now' onClick={(e) => {
                                e.preventDefault();
                                window.location.href = 'https://journaler.aem.ac/user/register';
                            }}>Start now for free</button>
                        </div></td>
                        <td> <div className='pt-3 paddingBtn'>
                            <button className='btn-start-now' onClick={(e) => {
                                e.preventDefault();
                                window.location.href = 'https://journaler.aem.ac/user/register';
                            }}>Start now for free</button>
                        </div></td>
                        <td> <div className='pt-3 paddingBtn'>
                            <button className='btn-start-now' onClick={(e) => {
                                e.preventDefault();
                                window.location.href = 'https://journaler.aem.ac/user/register';
                            }}>Contact us</button>
                        </div></td>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ComparePlans;
