import './ListNetWork.css';
const handleClick = (url) => {
    if (url) {
        const newTab = window.open(url, '_blank');
        if (newTab) {
            newTab.focus();
        }

    }
};
const ListNetwork = ({ dataNetWork, headerTitle, class_col }) => {
    return (
        <div className="container" >
            <div className='headerPartner pb-5'>
                {headerTitle}
            </div>
            <div className='d-flex justify-content-center' onClick={() => handleClick('https://example.com/new-page')}>

                <div className={class_col === 3 ? `row row-card_3 justify-content-center` : 'row row-card_4 justify-content-center'}>
                    {dataNetWork.map((element, index) => (
                        <div key={index} style={{ cursor: 'pointer' }} onClick={() => handleClick(element.url)}
                            className={class_col === 3 ? "col-6 col-contain col-md-4 col-lg-4 col-xl-4" : "col-6 col-contain col-md-3 col-lg-3 col-xl-3"}>
                            <div className='colData '>
                                <img className='d-flex justify-content-center img-col' src={element.img} alt='demo' />
                                <div className='name'>{element.name}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ListNetwork;