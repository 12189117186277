import React from 'react';
import '../style.css'


const Simplify = ({ content }) => (

  <div className='simplifyUseCase pt-5 pb-5'>
    <div className='titleSimplify'>
      {content.header1} <span className='titleSimplifyGradient'>{content.subHeader}</span> {content.header2}
    </div>
    <div className='d-flex justify-content-center pt-5 '>
      <a
        style={{ color: '#ffffff' }}
        className='btn btnGroup_greenBgBtn_simplify'
        href='https://journaler.aem.ac/'
        rel='noreferrer'
        target='_blank'
      >
        Start 30 Day Free Trial
      </a>
    </div>
  </div>

)

export default Simplify