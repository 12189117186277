import Donna from "../../../assets/Team/Donna.png";
import Jakub from "../../../assets/Team/Jakub.png";
import LeanneStaAna from "../../../assets/Team/Leanne.png";
import Rutger from "../../../assets/Team/Rutger.png";
import Serin from "../../../assets/Team/Serin.png";
import fds from "../../../assets/Team/fds.png";
import JasonHuynh from "../../../assets/Team/JasonHuynhCap.png";
import Naushad from "../../../assets/Team/Naushad.png";
import Abhi from "../../../assets/Team/Abhi.png";
import John from "../../../assets/Team/John Bassilios.png";

import Leanne_mobile from "../../../assets/Team/mobile/Leanne.png";
import Rutger_mobile from "../../../assets/Team/mobile/Rutger.png";
import donna_mobile from "../../../assets/Team/mobile/donna.png";
import fds_mobile from "../../../assets/Team/mobile/fds.png";
import Jakub_mobile from "../../../assets/Team/mobile/jakub.png";
import serin_mobile from "../../../assets/Team/mobile/serin.png";

const profile = [
  {
    photo: Jakub,
    name: "Jakub Sawczuk",
    title: "Founder / CEO / CFO",
    linkedin: "https://twitter.com/jakub_l_sawczuk",
    twitter: "",
    des: "Jakub is a certified accountant with over a decade of experience in Financial Management and Systems Integration. Before that Jakub has spent another decade as a Product Designer Engineer working for companies such as GE across Europe and the US and completing high end technical and corporate courses in collaboration with Georgia Tech in the US. Jakub holds undergraduate degrees acquired at universities in Australia (Swinburne, Melbourne), Holland (Hanzehogeschool, Groningen) and Spain (CPS, Zaragoza), as well as postgraduate certification in Accounting (Swinburne). Jakub holds provisional membership of the Chartered Accountants (Deakin), membership of the Institute of Certified Bookkeepers and a membership of Tax Practitioners Board of Australia as a BAS Agent. Jakub’s role is to manage the team and the operations, but at the same time to lead and verify lot of the conceptualisation of the systems developed by AEM.",
  },

  {
    photo: Serin,
    name: "Serin Park",
    title: "Operations/ Design",
    role: "UI/UX",
    linkedin: "https://www.linkedin.com/in/serin-park/?originalSubdomain=au",
    twitter: "",
    des: "Serin Park is a multi-disciplinary designer with a passion for User Experience and Interface(UX/UI) design. Her background is in Communication Design and Visual art with 2 years experience in a diverse range of industries. She uses visual communications as a way of exploring and understanding human experience.",
  },
  {
    photo: LeanneStaAna,
    name: "Leanne Sta Ana",
    title: "Marketing team leader",
    linkedin: "https://www.linkedin.com/in/leanne-sta-ana/",
    role: "Marketing",
    twitter: "",
    des: "Leanne is a Campaign Strategist at AEM Algorithm, focusing on the company blog and social media campaigns. With a strong background in copywriting and content creation, Leanne specialises in combining creativity and innovative marketing with strategic research and methodical thinking. She implements a unique human-centred approach to marketing by helping B2B and B2C brands connect authentically and empathetically with their desired audiences. Leanne graduated with High Distinction in the Bachelor of Communications (Professional Communication) at RMIT University with a specialisation in Media.",
  },
  {
    photo: Abhi,
    name: "Abhi",
    title: "Tech team leader",
    role: "",
    linkedin: "https://www.linkedin.com/in/abhisaran/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    twitter: "",
  },
  {
    photo: fds,
    name: "FDS Software",
    title: "Tech Support",
    linkedin: "https://www.linkedin.com/company/fdssoft",
    twitter: "",
    des: `One of the leading companies in South East Asia for blockchain and software full services consulting,
    FDSSoft works closely with AEM Algorithm to build the back-end infrastructure of AEM Journaler and AEM+.
    The FDS team is responsible for refining our mobile and web applications to a high level industry standard,
    specialising in startup solution consulting and support. With the ongoing assistance of FDS,
    AEM has successfully delivered innovative crypto accounting solutions for B2B and Web3.

    FDS also provides cutting-edge software consulting services to clients worldwide, including in Europe, US, Australia and South East Asia. Their client portfolio features Roche, Hyosung, NEM, dHealth, Symbol and more. They've built an outstanding legacy in  Business Analysis, UI/UX design, Data science, Blockchain, AI to mobile, cloud, web app development and deployment.
     `,
  },
  {
    photo: Naushad,
    name: "Naushad",
    title: "Mentor",
    linkedin: "https://www.linkedin.com/in/naushad-azim-93a64b242/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    twitter: "",
    des: "",
  },
  {
    photo: Donna,
    name: "Donna McConnell",
    title: "Head of Community",
    linkedin: "https://www.linkedin.com/in/iswys-donnamcconnell",
    twitter: "",
    des: "",
  },

];

const Advisors = [
  {
    photo: JasonHuynh,
    name: "Jason Huynh",
    title: "BD Advisor",
    linkedin: "https://www.linkedin.com/in/jason-nghihuynh/",
    twitter: "",
    des: "Jakub is a certified accountant with over a decade of experience in Financial Management and Systems Integration. Before that Jakub has spent another decade as a Product Designer Engineer working for companies such as GE across Europe and the US and completing high end technical and corporate courses in collaboration with Georgia Tech in the US. Jakub holds undergraduate degrees acquired at universities in Australia (Swinburne, Melbourne), Holland (Hanzehogeschool, Groningen) and Spain (CPS, Zaragoza), as well as postgraduate certification in Accounting (Swinburne). Jakub holds provisional membership of the Chartered Accountants (Deakin), membership of the Institute of Certified Bookkeepers and a membership of Tax Practitioners Board of Australia as a BAS Agent. Jakub’s role is to manage the team and the operations, but at the same time to lead and verify lot of the conceptualisation of the systems developed by AEM.",
  },
  {
    photo: Rutger,
    name: "Rutger Uittenbogaard",
    title: "Advisor",
    linkedin: "https://www.linkedin.com/in/rutger-uittenbogaard/",
    twitter: "",
    des: "",
  },
  {
    photo: John,
    name: "John Bassilios ",
    title: "Advisor",
    linkedin: "https://www.linkedin.com/in/john-bassilios-b0a2802/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    role: "Marketing",
    twitter: "",
    des: "Leanne is a Campaign Strategist at AEM Algorithm, focusing on the company blog and social media campaigns. With a strong background in copywriting and content creation, Leanne specialises in combining creativity and innovative marketing with strategic research and methodical thinking. She implements a unique human-centred approach to marketing by helping B2B and B2C brands connect authentically and empathetically with their desired audiences. Leanne graduated with High Distinction in the Bachelor of Communications (Professional Communication) at RMIT University with a specialisation in Media.",
  },


];

const mobile_profile = [
  {
    photo: Jakub_mobile,
    name: "Jakub Sawczuk",
    title: "Founder / CEO / CFO",
    linkedin: "",
    twitter: "https://twitter.com/jakub_l_sawczuk",
    des: `Jakub is a certified accountant with over a decade of experience in Financial Management and Systems Integration. 
    Before that Jakub has spent another decade as a Product Designer Engineer working for companies such as
     GE across Europe and the US and completing high end technical and corporate courses in collaboration with Georgia Tech in the US.
      Jakub holds undergraduate degrees acquired at universities in Australia (Swinburne, Melbourne), 
      Holland (Hanzehogeschool, Groningen) and Spain (CPS, Zaragoza), as well as postgraduate certification in Accounting (Swinburne). 
      Jakub holds provisional membership of the Chartered Accountants (Deakin), membership of the Institute of Certified
       Bookkeepers and a membership of Tax Practitioners Board of Australia as a BAS Agent. Jakub’s role is to manage the team and the operations,
        but at the same time to lead and verify lot of the conceptualisation of the systems developed by AEM.`,
  },
  {
    photo: donna_mobile,
    name: "Donna McConnell",
    title: "Head of Community",
    linkedin: "https://www.linkedin.com/in/iswys-donnamcconnell",
    twitter: "",
    des: "",
  },
  {
    photo: serin_mobile,
    name: "Serin",
    title: "Operations/ Design",
    role: "UI/UX",
    linkedin: "https://www.linkedin.com/in/serin-park/?originalSubdomain=au",
    twitter: "",
    des: "Serin Park is a multi-disciplinary designer with a passion for User Experience and Interface(UX/UI) design. Her background is in Communication Design and Visual art with 2 years experience in a diverse range of industries. She uses visual communications as a way of exploring and understanding human experience.",
  },
  {
    photo: Leanne_mobile,
    name: "Leanne Sta Ana",
    title: "Marketing team leader",
    linkedin: "https://www.linkedin.com/in/leanne-sta-ana/",
    role: "Marketing",
    twitter: "",
    des: "Leanne is a Campaign Strategist at AEM Algorithm, focusing on the company blog and social media campaigns. With a strong background in copywriting and content creation, Leanne specialises in combining creativity and innovative marketing with strategic research and methodical thinking. She implements a unique human-centred approach to marketing by helping B2B and B2C brands connect authentically and empathetically with their desired audiences. Leanne graduated with High Distinction in the Bachelor of Communications (Professional Communication) at RMIT University with a specialisation in Media.",
  },
  {
    photo: Abhi,
    name: "Abhi",
    title: "Tech team leader",
    linkedin: "https://www.linkedin.com/in/abhisaran/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    role: "Marketing",
    twitter: "",
    des: "Leanne is a Campaign Strategist at AEM Algorithm, focusing on the company blog and social media campaigns. With a strong background in copywriting and content creation, Leanne specialises in combining creativity and innovative marketing with strategic research and methodical thinking. She implements a unique human-centred approach to marketing by helping B2B and B2C brands connect authentically and empathetically with their desired audiences. Leanne graduated with High Distinction in the Bachelor of Communications (Professional Communication) at RMIT University with a specialisation in Media.",
  },
  {
    photo: fds_mobile,
    name: "FDS Soft",
    title: "Solution Developer",
    linkedin: "https://www.linkedin.com/company/fdssoft",
    twitter: "",
    des: `One of the leading companies in South East Asia for blockchain and software full services consulting,
    FDSSoft works closely with AEM Algorithm to build the back-end infrastructure of AEM Journaler and AEM+.
    The FDS team is responsible for refining our mobile and web applications to a high level industry standard,
    specialising in startup solution consulting and support. With the ongoing assistance of FDS,
    AEM has successfully delivered innovative crypto accounting solutions for B2B and Web3.

    FDS also provides cutting-edge software consulting services to clients worldwide, including in Europe, US, Australia and South East Asia. Their client portfolio features Roche, Hyosung, NEM, dHealth, Symbol and more. They've built an outstanding legacy in  Business Analysis, UI/UX design, Data science, Blockchain, AI to mobile, cloud, web app development and deployment.
     `,
  },
  {
    photo: Naushad,
    name: "Naushad",
    title: "Events / Game Design",
    linkedin: "https://www.linkedin.com/in/naushad-azim-93a64b242/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    twitter: "",
    des: "I have a broad range of corporate commercial, financial services, funds management and banking and finance experience with a particular emphasis on financial services and funds management. I have taken a keen interest in Blockchain and crypto currencies having advised clients on the establishment of crypto currency funds, establishing crypto currency exchanges, initial coin offerings (ICOs), operating ICO marketing platforms and operating crypto wallets. I am also a mentor for the RMIT Blockchain Strategy Course. I also have a keen interest in startups and fintech companies. I have acted for a wide range of financial services providers including retail and wholesale fund managers, investment advisers, financial planners, stock brokers, IDPS operators and managed discretionary account providers. I have advised financial service providers on all aspects of managed investments schemes, AFSL and ACL licensing, disclosure requirements and ongoing compliance with regulatory requirements.",
  },
  {
    photo: JasonHuynh,
    name: "Jason Huynh",
    title: "BD Advisor",
    linkedin: "https://www.linkedin.com/in/jason-nghihuynh/",
    twitter: "",
    des: `Strong interest in coordinating international trade. 
    Spent over 10 years in China teaching economics at prestigious organisations.High interpersonal 
    skills with natural inclination to network and source leads. Regularly use online communication platforms and social media channels`,
  },
  {
    photo: Rutger_mobile,
    name: "Rutger Uittenbogaard",
    title: "Advisor",
    linkedin: "https://www.linkedin.com/in/rutger-uittenbogaard/",
    twitter: "",
    role: "Marketing",
    des: "Rutger has over a decade of experience in online marketing. As a Digital Marketer, SEO copywriter and Creative Content Editor with experience in both the B2B and B2C sector, he has created successful ad campaigns, and written compelling and SEO strong content in order to generate more traffic and a higher conversion rate. He has covered a wide range of topics such as astronomy, video games, the best sights to see in European capitals, and even avid hikers found their perfect holiday location thanks to a successful ad campaign for a British booking company. Back in 2017, he was part of an ambitious cryptocurrency news outlet, which gave birth to his interest in everything blockchain related.",
  },
  {
    photo: John,
    name: "John Bassilios ",
    title: "Advisor",
    linkedin: "https://www.linkedin.com/in/john-bassilios-b0a2802/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    twitter: "",
    des: "Matthew VanMullem is an ICT systems architect with 15 years of technical expertise working with technologies from VMware, Microsoft, Apple, Cisco, and HPE. His broad base of expertise has lead to work with a diverse set of past projects, enabling him to act as a trusted consultant with a holistic view of client operations. His current focus is providing business automation solutions",
  },
]
export { mobile_profile, profile,Advisors };

