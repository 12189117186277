import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';

// services
import {helpCenterServiceInstance} from "../../services";

const HelpCenterContext = createContext();

export const useHelpCenterContext = () => useContext(HelpCenterContext);

export const HelpCenterProvider = ({children}) => {
    const [data, setData] = useState(null)

    const onGetHelpData = useCallback(async () => {
        const data = await helpCenterServiceInstance.getHelpCenter()
        setData(data)
    }, [])

    useEffect(() => {
        onGetHelpData()
    }, []);

    return (
        <HelpCenterContext.Provider
            value={{data}}>
            {children}
        </HelpCenterContext.Provider>
    );
};
