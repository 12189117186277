import React, { Component } from 'react';
import PlanCards from './components/PlanCards';
import plans from './plan';
import pricingPlan from './pricingPlan';
import Screenshot from '../../../assets/pricing/wallet_page.png'
import usd from '../../../assets/pricing/usd.png'
import aud from '../../../assets/pricing/aud.png'
import check from '../../../assets/pricing/check.png'
import usaSelected from '../../../assets/pricing/united-states-selected.png'
import ausSelected from '../../../assets/pricing/australia-selected.png'
import './Pricing.css';
import SignupSelectionPricing from './components/SignupSelectionPricing';
import PlanView from './components/PlanView';
import ComparePlans from './components/ComparePlans';
import SelectOptionCurrency from './components/SelectOptionCurrency';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { Button } from 'react-bootstrap';

let accountant = {
  header: 'Are you an accountant?',
  subHeader: 'Contact us to be a part of our Accountant Partner Program and receive member discounts'
}

const subscriptionCardsInfo = [
  {
    type: 'Compact',
    price: '$9',
    details: [
      {
        id: 1,
        value: 'Unlimited Wallets'
      },
      {
        id: 2,
        value: '100 Transactions'
      },
      {
        id: 3,
        value: 'Unlimited Wallets'
      },
    ]
  },
  {
    type: 'Starter',
    price: '$79',
    details: [
      {
        id: 1,
        value: 'Unlimited Wallets'
      },
      {
        id: 2,
        value: '500 Transactions'
      },
      {
        id: 3,
        value: 'CGT Tax Calculator (Exchange Wallets)'
      },
      {
        id: 4,
        value: 'Xero and QuickBooks Integration'
      },
    ]
  },
  {
    type: 'Premium',
    price: '$159',
    details: [
      {
        id: 1,
        value: 'Unlimited Wallets'
      },
      {
        id: 2,
        value: '5000 Transactions'
      },
      {
        id: 3,
        value: 'CGT Tax Calculator (Exchange Wallets)'
      },
      {
        id: 4,
        value: 'Xero and QuickBooks Integration'
      },
    ]
  },
  {
    type: 'Pro',
    price: '$320',
    details: [
      {
        id: 1,
        value: 'Unlimited Wallets'
      },
      {
        id: 2,
        value: '10,000 Transactions'
      },
      {
        id: 3,
        value: 'CGT Tax Calculator (Exchange Wallets)'
      },
      {
        id: 4,
        value: 'Xero and QuickBooks Integration'
      },
    ]
  },
]

class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      price: 'monthly',
      currency: 'aud',
      countryName: '',
      companyProfile: 79
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    var url = 'https://api.ipregistry.co/?key=vav52tym9lvtoa89';
    let country = await fetch(url)
      .then(function (response) {
        return response.json();
      })
      .then(function (payload) {

        return payload.location.country.name

      });

    this.setState({ countryName: country });
    if (country === 'Australia') {
      this.setState(
        {
          currency: 'aud',
          companyProfile: 79
        });
    }
    else {
      this.setState(
        {
          currency: 'usd',
          companyProfile: 49
        });
    }
  }

  onHandlePrice = (e) => {
    this.setState({ price: e });
  };

  showYearlyPrice = (e) => {
    this.setState({ price: 'yearly' });
  };
  checkicon = (e) => {
    if (e === true) {
      return <FaCheck style={{ color: "#226E9D" }} />
    }
    else if (e === false) {
      return <FaTimes />
    }
    else {
      return e
    }
  }


  fnGetPriceFrequency = () => {
    if (this.state.price === 'monthly') {
      return (
        <th scope="col" class='align-middle'>
          <div className='monthly-mobile' style={{ paddingTop: '15px' }}>Monthly Subscription</div>
          <div className='yearly-mobile'>Yearly Subscription</div>
          <p className='italictextCardbody-mobile' >Subscribe & Save 10%</p>
        </th>
      )
    }
    else {
      return (
        <th scope="col" class='align-middle'>
          <div className='monthly-mobile' style={{ paddingTop: '15px' }}>Yearly Subscription</div>
          <div className='yearly-mobile'>Monthly Subscription</div>
          <p className='italictextCardbody-mobile' >Subscribe & Save 10%</p>
        </th>
      )
    }
  }

  handleOnChangeCurrency = (item) => {

    this.setState(
      {
        currency: item,
        companyProfile: item === 'aud' ? 79 : 49
      });
  }


  render() {
    return (
      <div>
        <div className='pricing'>
          pricing
        </div>
        <div className='d-flex  justify-content-center pt-3 pt-sm-5 pt-lg-5 pt-xl-5'>
          <div className='header-journaler '>
            Find the right <span className='text-journaler'>Journaler </span> plan for you
          </div>
        </div>
        <div className='justify-content-center pt-3 pt-sm-5 pt-lg-5 pt-xl-5'>
          <div className='sub-header-journaler pb-1'>
            30 day free trial available for all plans
          </div>
          <div className='sub-header-journaler'>
            Change/cancel your subscription anytime
          </div>
        </div>
        <div  >
          <div className='desktop container d-flex justify-content-between pt-5'>
            <div>
              <SelectOptionCurrency currency={this.handleOnChangeCurrency} valuecurrency={this.state.currency} />
            </div>
            <div className='containerSave'>
              <div className='save'>You can save 10% by subscribing yearly!</div>
              <div >
                <div className='swithcherDiv'>

                  <button
                    type='button'
                    className={this.state.price === 'monthly' ? 'monthBtnChoosed' : 'monthBtn'}
                    onClick={() => this.onHandlePrice('monthly')}>
                    Monthly
                  </button>
                  <button
                    type='button'
                    className={this.state.price === 'yearly' ? 'yearBtnChoosed' : 'yearBtn'}
                    onClick={() => this.onHandlePrice('yearly')}>
                    Yearly
                  </button>
                </div>
              </div>
            </div>

          </div>
          <div className='mobile container '>

            <div className='pt-3'>
              <SelectOptionCurrency currency={this.handleOnChangeCurrency} valuecurrency={this.state.currency} />
            </div>

            <div className='btn-switch'>
              <p>
                You can save 10% by subscribing yearly!
              </p>
            </div>


            <div className='pb-5'>
              <button
                type='button'
                className={this.state.price === 'monthly' ? 'monthBtnChoosed' : 'monthBtn'}
                onClick={() => this.onHandlePrice('monthly')}>
                Monthly
              </button>
              <button
                type='button'
                className={this.state.price === 'yearly' ? 'yearBtnChoosed' : 'yearBtn'}
                onClick={() => this.onHandlePrice('yearly')}>
                Yearly
              </button>
            </div>
          </div>
        </div>
        {
          this.state.currency == 'usd' && <div className='text-center pb-3'>USD prices are based on exchange rate as billing is in AUD</div>
        }
        <div className='container'>
          <PlanView durationMode={this.state.price} currency={this.state.currency} />
        </div>
        <div className='container pb-5'>
          <div className='row containerEnterprise'>
            <div className='col-12 col-lg-8'>
              <div className='enterprise'>
                Enterprise
              </div>
              <div className='subEnterprise'>
                Gain access to unlimited transactions, customizable features, API access, and more
              </div>
            </div>
            <div className='col-12 col-lg-4'>
              <div class="d-flex justify-content-center">
                <a href='/contact' className='btcContactUs' >
                  Contact us
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className='support pt-3'>
          <div className='container'>
            <h2 className='text-center title-ournetwork pt-5'>Support for</h2>
            <div className='text-center title-ournetwork' ><span className='currency20'>20,000+</span> cryptocurrencies</div>
            <div class="d-flex justify-content-center pt-3">
              <button href='https://journaler.aem.ac/user/register' className='clickbBtnSimplify' >
                Request demo
              </button>
            </div>
          </div>
        </div>
        <ComparePlans className="pb-5" durationMode={this.state.price} currency={this.state.currency} />
        <div className='container pb-5 pt-5'>
          <div className='row containerEnterprise'>
            <div className='col-12 col-lg-8'>
              <div className='enterprise'>
                Adding company profile
              </div>
              <div className='subEnterprise pt-3'>
                Add a company profile for ${this.state.companyProfile} /month and invite multi-users from the same organization
              </div>
            </div>
            <div className='col-12 col-lg-4'>
              <div class="d-flex justify-content-center" style={{ fontSize: 30, color: '#000', fontWeight: 'bold' }}>
                ${this.state.companyProfile}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Pricing;
