import React from 'react'

import manageAccount from '../../../../assets/FAQ/icon-Applications.svg'
import integration from '../../../../assets/FAQ/icon-Integration.svg'
import applications from '../../../../assets/FAQ/icon-ManageAccount.svg'
import subscriptions from '../../../../assets/FAQ/icon-Subscriptions.svg'
import privacyAndSecurity from '../../../../assets/FAQ/icon-pp.svg'

import '../JournalerHelp.css'

const topics = [
  {
    img: manageAccount,
    alt: 'Using Journaler',
    description: 'Using Journaler'
  },
  {
    img: integration,
    alt: 'Integration',
    description: 'Integration'
  },
  {
    img: applications,
    alt: 'Manage My Account',
    description: 'Manage My Account'
  },
  {
    img: subscriptions,
    alt: 'Subscriptions',
    description: 'Subscriptions'
  },
  {
    img: privacyAndSecurity,
    alt: 'Privacy and Security',
    description: 'Privacy and Security'
  }
]

const Topic = ({img, alt, description}) => (
  <div className='card p-2 border-0 topicCard' style={{height: '150px', width: '171px'}}>
    <div style={{width: '58px'}} className='d-flex justify-content-center align-self-center pt-2'>
      <img src={img} className='card-img-top' alt={alt}/>
    </div>

    <div className='card-body text'>
      <p className='card-text text-center' style={{color: 'black', fontSize: '14px'}}>{description}</p>
    </div>
  </div>
)

const Header = () => {
  return (
    <div className='container-fluid helpHeader globalHeaderBg'>
      {/* <div className='searchBar'>
          <input className='searchInput'/>
        </div> */}
      <h1 className='text-center h1Bold'>Articles by Topic</h1>
      <div className='container justify-content-center cardContainer p-5'>
        <div className='row justify-content-center'>
          {topics.map((topics) => (
            <div key={topics.description} className='col-lg-2 col-md-4 pb-2'>
              <Topic img={topics.img} alt={topics.alt} description={topics.description}/>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Header
