import React from 'react';
import GooglePlay from '../../../assets/aemPlus/googleplay.png';
import AppStore from '../../../assets/home/simplify/Appstore.png';
import aemPlus from '../../../assets/home/simplify/aem_plus.png';
import '../Home.css';

const JournalerAEM = () => {
    return (
        <div className='journalerFeature container'>
            <div className='row pt-5'>
                <div className='col-12 col-md-5 pl-md-5 d-flex justify-content-center' >
                    <img className='img-fluid' src={aemPlus} />
                </div>
                <div className='col-12 col-md-7'>
                    <div className='contain-wallet'>
                        <div className='manage-crypto pt-sm-5'>
                            Manage your crypto transactions on any device, anywhere, anytime!
                        </div>
                        <div className='manage-crypto-sub pt-3'>
                            Download AEM+ mobile app
                        </div>
                        <div className='d-flex justify-content-center justify-content-md-start pt-3 pb-5'>
                            <a href='https://apps.apple.com/au/app/aem/id1607457067' rel='noreferrer' target='_blank'>
                                <img src={AppStore} alt='app store' className='linkStoreMain mr-4 mt-2' />
                            </a>
                            <a href='https://play.google.com/store/apps/details?id=com.aem.aemplus' rel='noreferrer' target='_blank'>
                                <img src={GooglePlay} alt='google play' className='linkStoreMain mt-2' />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};


export default JournalerAEM;
