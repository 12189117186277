import React from 'react';
import '../TermsConditions.css';
// -----  intro & overall wrapper:
export const MainSectionWrapper = ({ id, title, contents }) => (
  <div id={id} style={{ marginBottom: 10 }} className='pb-3'>
    <h4 className='pb-2'> {title}</h4>
    {/* <p className='text-justify'>{contents}</p> */}
    {contents.map((content) => (
      <p className='text-justify'>{content}</p>
    ))}
  </div>
);

// ------ Each section wrapper
export const SectionWrapper = ({ contents, children }) => (
  <div id={contents.id} className='w-100 h-100 p-3 pb-4'>
    <h4 className='w-100 h-100 pb-2'>{contents.title}</h4>
    <div className='w-100 h-100 pt-4'>{children}</div>
  </div>
);

export const BulletListContents = ({ contents }) => (
  <>
    {contents.desc.map((description, index) => (
      <React.Fragment key={index}>
        <h5>{description.subTitle}</h5>
        <ul className='w-100 p-3' style={{ listStyleType: 'disc' }}>
          {description.details.map((detail, index) => (
            <li key={index} className='h-100 d-inline-block text-justify'>
              {detail}
            </li>
          ))}
        </ul>
      </React.Fragment>
    ))}
  </>
);

export const MulitiList = ({ contents }) => (
  <>
    {contents.desc.map((description, index) => (
      <React.Fragment key={index}>
        <h5>{description.subTitle}</h5>
        {description.details &&
          description.details.map((detail) => (
            <ul key={detail.topic}>
              {detail.hasOwnProperty('extraInfo') ? (
                <li className='text-justify'>
                  {detail.topic}
                  <ul>
                    {detail.extraInfo.map((info) => (
                      <li key={info} className='text-justify'>
                        {info}
                      </li>
                    ))}
                  </ul>
                </li>
              ) : (
                <li className='text-justify'>{detail.topic}</li>
              )}
            </ul>
          ))}
      </React.Fragment>
    ))}
  </>
);


export const ListData = ({dataCollection}) => (
 
  <div className='container'>
    <div className='titlePolicy'>
      {dataCollection.title}
    </div>
    <div >
      {dataCollection.desc.map((description, index) => (
        <div>
          <div className='subtitlePolicy'>{description.subTitle}</div>
          <ul className='w-100 p-3' style={{ listStyleType: 'disc' }}>
            {description.details.map((detail, index) => (
              <li key={index} className='h-100 d-inline-block text-justify'>
                - {detail}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  </div>

)