import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import imageAppStore from '../../assets/aemPlus/imageAppStore.png';
import imageGooglePlay from '../../assets/aemPlus/imageGooglePlay.png';
import {
  JournalerAi,
  MJ,
  MJTitleBelow,
  Metaverse,
  MrJ2,
  MrJOffice,
  telegramImg,
  twitter,
  waving
} from '../../assets/mrJournaler/imageMJ.jsx';
import SignUpForm from '../home/components/FormSignUp.jsx';
import './mrJournaler.css';
import RoadMap from './roadMap/RoadMap.jsx';

const MrJournaler = () => {
  const [openSocialConnection, setOpenSocialConnection] = useState(false);
  const [isDaoOpen, setIsDaoOpen] = useState(false);
  const [isMetaverseOpen, setIsMetaverseOpen] = useState(false);
  const [isAiOpen, setIsAiOpen] = useState(false);
  const [isReadMore, setIsReadMore] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 700 });
  const isMobileWidth = useMediaQuery({ maxWidth: 817 });
  const roadmapQ1Position = useRef(null);
  const roadmapQ1OrdinalPosition = useRef(null);
  const roadmapQ2Position = useRef(null);
  const roadmapQ3Position = useRef(null);
  const roadmapQ4Position = useRef(null);
  const roadmap25Position = useRef(null);
  const [roadmapQ1Height, setRoadmapQ1Height] = useState(0);
  const [roadmapQ1OrdinalHeight, setRoadmapQ1OrdinalHeight] = useState(0);
  const [roadmapQ2Height, setRoadmapQ2Height] = useState(0);
  const [roadmapQ3Height, setRoadmapQ3Height] = useState(0);
  const [roadmapQ4Height, setRoadmapQ4Height] = useState(0);
  const [roadmap25Height, setRoadmap25Height] = useState(0);
  const updateOffsets = () => {
    if (roadmapQ1Position.current) {
      setRoadmapQ1Height(roadmapQ1Position.current.offsetTop);
    }
    if (roadmapQ1OrdinalPosition.current) {
      setRoadmapQ1OrdinalHeight(roadmapQ1OrdinalPosition.current.offsetTop);
    }
    if (roadmapQ2Position.current) {
      setRoadmapQ2Height(roadmapQ2Position.current.offsetTop);
    }
    if (roadmapQ3Position.current) {
      setRoadmapQ3Height(roadmapQ3Position.current.offsetTop);
    }
    if (roadmapQ4Position.current) {
      setRoadmapQ4Height(roadmapQ4Position.current.offsetTop);
    }
    if (roadmap25Position.current) {
      setRoadmap25Height(roadmap25Position.current.offsetTop);
    }
  }

  useEffect(() => {
    updateOffsets();
    roadmapQ1Position.current.addEventListener('transitionend', updateOffsets);
    roadmapQ1OrdinalPosition.current.addEventListener('transitionend', updateOffsets);
    roadmapQ2Position.current.addEventListener('transitionend', updateOffsets);
    roadmapQ3Position.current.addEventListener('transitionend', updateOffsets);
    roadmapQ4Position.current.addEventListener('transitionend', updateOffsets);
    roadmap25Position.current.addEventListener('transitionend', updateOffsets);
    return () => {
      roadmapQ1Position.current.removeEventListener('transitionend', updateOffsets);
      roadmapQ1OrdinalPosition.current.removeEventListener('transitionend', updateOffsets);
      roadmapQ2Position.current.removeEventListener('transitionend', updateOffsets);
      roadmapQ3Position.current.removeEventListener('transitionend', updateOffsets);
      roadmapQ4Position.current.removeEventListener('transitionend', updateOffsets);
      roadmap25Position.current.removeEventListener('transitionend', updateOffsets);
    };
  }, [
    roadmapQ1Position,
    roadmapQ1OrdinalPosition,
    roadmapQ2Position,
    roadmapQ3Position,
    roadmapQ4Position,
    roadmap25Position,
    isAiOpen,
    isMetaverseOpen,
    isDaoOpen,
    openSocialConnection,
    window.innerWidth
  ]);

  return (
    <div className='MJcontainer'>
      <div class="whoMJ">
        <img src={MrJ2} alt='MrJ2Gif' className='gifImg' />
      </div>
      <div class="MrJInfo">
        <div className='MJInfoInner'>
          <div className='MJTitle'>
            <img src={MJTitleBelow} alt='MJTitleBelow' className='MJTitleBelow' />
          </div>
          <a className='MJTitleDis' href='https://www.tiertop.io/mint/1768108981905997824?collection_type=normal'>
            An exclusive Bitcoin NFT Ordinals collection
          </a>
          <div className='MJInfoContainer'>
            <div className='MJInfoLeft'>
              <div className='MJTitleLeft'>
                Only 10,000 unique pieces available for mint!
              </div>

              {!isMobile ? (
                <>
                  <div className='MJContentLeft'>
                    Introducing Mr. Journaler – the innovative NFT based virtual accountant revolutionising
                    the world of crypto accounting. Leveraging Bitcoin Ordinals, Mr. Journaler seamlessly
                    integrates with AEM Journaler and unlocks exclusive accounting features for holders.
                  </div>
                  <div className='MJContentLeft'>
                    Secure your own NFT now and become a part of Web3 accounting history!
                  </div></>
              ) : <div className='MJContentCenter'>
                {isReadMore ? <>
                  Introducing Mr. Journaler – the innovative NFT based virtual accountant revolutionising
                  the world of crypto accounting. Leveraging Bitcoin Ordinals, Mr. Journaler seamlessly
                  integrates with AEM Journaler and unlocks exclusive accounting features for holders.  <span className='readmore' onClick={() => setIsReadMore(false)}>Read less</span>
                </>
                  : <>Introducing Mr. Journaler – the innovative NFT based virtual
                    accountant revolutionising the world of crypto accounting. <span className='readmore' onClick={() => setIsReadMore(true)}>Read more</span></>}
              </div>}
            </div>
            {
              !isMobile && (
                <div className='MJInfoRight'>
                  <img src={MJ} alt='MJ' className='MJLogo' />
                </div>
              )
            }
          </div>
          <div className='d-flex justify-content-center'>
            <button
              className='MJInfoBtn'
              onClick={() => window.scrollTo({
                top: document.getElementById('q1mint').offsetTop,
                behavior: 'smooth'
              })}
            >
              Mint Mr. Journaler now
            </button>
          </div>
          {
            isMobile && (
              <div className='MJInfoRight pb-5 pt-2'>
                <img src={MJ} alt='MJ' className='MJLogo' />
              </div>
            )
          }
        </div>
      </div>
      <div class="MJColla">
        {!isMobile ? (<>
          {isMobile && (
            <img src={MJTitleBelow} alt='MJTitleBelow'
              style={{
                paddingBottom: '12px',
                width: '90%'
              }}
            />
          )
          }
          <div class="MJCollaTitle">
            {!isMobile && (
              <img src={MJTitleBelow} alt='MJTitleBelow' />
            )
            }
            is the result of {isMobileWidth && (<>a</>)}
          </div>
          <div
            class="MJCollaTitle"
            style={{
              paddingBottom: '27px',
            }}>
            {!isMobileWidth && (<>a</>)} groundbreaking collaboration
          </div>

        </>) :
          <div class="MJCollaTitleMobile">Mr.Journaler is the result of<br></br> a groundbreaking<br></br> collaboration</div>
        }
        <div className='MJCollaContent'>
          We're working with a stellar lineup of Bitcoin Ordinals connoisseurs to build a one-of-a-kind Utility NFT
          collection, expanding our solutions in the world of blockchain, EVM, and Web3.
        </div>
        <div style={{
          paddingTop: '27px',
        }}>
          <a
            href='https://www.aemalgorithm.io/networks'
            target='_blank'
            rel='noreferrer'
            style={{
              fontSize: '21px',
              fontWeight: '400',
              color: 'rgba(4, 141, 193, 1)'
            }}>
            See our network
          </a>
        </div>
      </div>
      <div className='MJNft'>
        <div className='MJNftLeft'>
          <div className='MJOfficeNameGradient'>
            Unlock a free subscription and exclusive feature
          </div>
          <div className='MJNftContent'>
            Mr. Journaler NFT holders will gain access to a complimentary annual subscription to our crypto accounting software, including exclusive features that only the virtual accountant can unlock.
          </div>
          <div className='MJNftButtonsContainer'>
            <button className='MJNftButtons' onClick={() => window.scrollTo({
              top: document.getElementById('q1mint').offsetTop,
              behavior: 'smooth'
            })}
              style={{
                marginRight: '8px'
              }}
            >
              Mint Mr. Journaler
            </button>
            <button className='MJNftButtons' onClick={() => window.open('https://journaler.aem.ac/user/register', '_blank')}>
              Sign up to Journaler
            </button>
          </div>
        </div>
        <div className='MJNftRight'>
          <img src={MrJOffice}
            className='MrJOffice'
            alt='MrJOffice'

          />
        </div>
      </div>
      <div className='container MJRoadMap'>
        <div className='MJRoadMapTitle'>
          Roadmap
        </div>
        <div className='MJRoadMapContainer'>
          <div className='MJRoadMapLeft'>
            <RoadMap
              isMobile={isMobile}
              isSocialOpen={openSocialConnection}
              isDaoOpen={isDaoOpen}
              isMetaverseOpen={isMetaverseOpen}
              isAiOpen={isAiOpen}
              roadmapQ1Position={roadmapQ1Height}
              roadmapQ1OrdinalPosition={roadmapQ1OrdinalHeight}
              roadmapQ2Position={roadmapQ2Height}
              roadmapQ3Position={roadmapQ3Height}
              roadmapQ4Position={roadmapQ4Height}
              roadmap25Position={roadmap25Height}
            />
          </div>
          <div className='MJRoadMapRight'>
            <div className='MJRoadMapRightInner'>
              <div
                className='MJRoadMapQ1'
                ref={roadmapQ1Position}
              >
                <div className='roadMapTime'>
                  2024 Q1
                </div>
                <div className='rowContainer'>
                  <div className='roadMapTitle'>
                    Mr. Journaler Ordinals Launch
                  </div>
                  <div className='linkTextAccess'>
                    Sign up for early access!
                  </div>
                </div>
                <hr style={{
                  width: '100%',
                  borderTop: '1px solid rgba(237, 237, 237, 1)',
                  margin: '0px'
                }}
                />
                <div className='rowContainer'>
                  <div className='textRM'>
                    Get started with Journaler
                  </div>
                  <button className='linkButton' onClick={() => window.open('https://journaler.aem.ac/user/register', '_blank')}>
                    Sign up to Journaler
                  </button>
                </div>
                <hr style={{
                  width: '100%',
                  borderTop: '1px solid rgba(237, 237, 237, 1)',
                  margin: '0px'
                }}
                />
                <div className='rowContainer2' id='q1mint'>
                  <div className='textRM'>
                    Download AEM+ multi-crypto<br></br> wallet for free
                  </div>
                  <div className='socialGroup'>
                    <img
                      onClick={() => window.open('https://apps.apple.com/au/app/aem/id1607457067', '_blank')}
                      src={imageAppStore}
                      alt='app store'
                      style={{
                        marginRight: '12px',
                        cursor: 'pointer'
                      }}
                      width={192}
                      height={65}
                    />
                    <img
                      onClick={() => window.open('https://play.google.com/store/apps/details?id=com.aem.aemplus', '_blank')}
                      src={imageGooglePlay}
                      width={192}
                      height={65}
                      style={{
                        cursor: 'pointer'
                      }}
                      alt='google play'
                    />
                  </div>
                </div>
              </div>
              <div
                className='MJRoadMapQ1Ordinals'
              >
                <div className='MJOrdinals'>
                  2024 Q1
                </div>
                <div>
                  <div
                    className='roadMapTitleOrdinal'
                  >
                    Mr. Journaler BTC20 Ordinals Mint
                  </div>
                </div>
                <div className='rowContainerMint'>
                  <div
                    className='roadMapOptionsOrdinal'
                  >
                    Gamma
                  </div>
                  <div>
                    <div className='ordinalsBtns' onClick={() => window.open('https://gamma.io/ordinals/collections/mrjournaler', '_blank')}>
                      Mint
                    </div>
                  </div>
                </div>
                <hr style={{
                  width: '100%',
                  borderTop: '1px solid rgba(237, 237, 237, 1)',
                  margin: '0px'
                }}
                />
                {/* <div className='rowContainerMint'>
                  <div
                    className='roadMapOptionsOrdinal'
                  >
                    TierTop
                  </div>
                  <div>
                    <div className='ordinalsBtns' onClick={() => window.open('https://www.tiertop.io/mint/1768108981905997824?collection_type=normal0', '_blank')}>
                      Mint
                    </div>
                  </div>
                </div> */}
                {/* <hr style={{
                  width: '100%',
                  borderTop: '1px solid rgba(237, 237, 237, 1)',
                  margin: '0px'
                }}
                /> */}
                <div className='rowContainerMint'>
                  <div
                    className='roadMapOptionsOrdinal'
                  >
                    Ordinals bot
                  </div>
                  <div>
                    <div style={{
                      color: 'rgba(222, 222, 222, 0.8)',
                      fontSize: '18px',
                      fontWeight: '700',
                      paddingRight: '25px'
                    }}>
                      Pending
                    </div>
                  </div>
                </div>
                <hr style={{
                  width: '100%',
                  borderTop: '1px solid rgba(237, 237, 237, 1)',
                  margin: '0px'
                }}
                />
                <div className='rowContainerMint'>
                  <div
                    className='roadMapOptionsOrdinal'
                  >
                    Magic Eden
                  </div>
                  <div>
                    <div style={{
                      color: 'rgba(222, 222, 222, 0.8)',
                      fontSize: '18px',
                      fontWeight: '700',
                      paddingRight: '25px'
                    }}>
                      Pending
                    </div>
                  </div>
                </div>
              </div>
              <div
                ref={roadmapQ1OrdinalPosition}
                className={!openSocialConnection ? 'MJRoadMapTwitterClosed' : 'MJRoadMapTwitter'}
                onMouseOver={() => !isMobileWidth ? setOpenSocialConnection(true) : null}
                onMouseLeave={() => !isMobileWidth ? setOpenSocialConnection(false) : null}
              >
                <div className='stayConnected'>
                  <div className='stayStyle'>
                    Stay connected for more news!
                  </div>
                  <FontAwesomeIcon
                    width={26}
                    height={26}
                    style={{
                      border: '1px solid rgba(222, 222, 222, 0.8)',
                      borderRadius: '50%',
                      display: 'flex',
                      justifyContent: 'center',
                      padding: '4px',
                      alignItems: 'center',
                      color: openSocialConnection ? 'rgba(158, 158, 158, 1)' : 'rgba(4, 141, 193, 1)',
                      transition: '0.4s ease-in-out',
                      transform: `rotate(${!openSocialConnection ? '0deg' : '180deg'})`
                    }}
                    onClick={() => isMobileWidth ? setOpenSocialConnection(!openSocialConnection) : null}
                    icon={faCaretDown}
                    class='fa-sharp fa-solid fa-caret-down'
                  />
                </div>
                {
                  openSocialConnection && (
                    <>
                      <div
                        className='rowContainerSocial'
                      >
                        <div className='text2'>
                          Follow us on Telegram
                        </div>
                        <div className='textLink' onClick={() => window.open('https://t.me/MrJournaler', '_blank')}>
                          Go to Telegram <img src={telegramImg} width={40} height={40} alt='telegram'
                            style={{
                              marginLeft: '12px',
                            }}
                          />
                        </div>
                      </div>
                      <div className='rowContainerSocial'>
                        <div className='text2'>
                          Follow us on Twitter
                        </div>
                        <div className='textLink' onClick={() => window.open('https://twitter.com/AEM_Algorithm', '_blank')}>
                          Go to Twitter <img src={twitter} width={40} height={40} alt='twitter'
                            style={{
                              marginLeft: '12px'
                            }}
                          />
                        </div>
                      </div>
                      <div className='text2'>
                        Sign up to e-newsletter
                      </div>
                      <div className='signUpStyle'>
                        <SignUpForm />
                      </div>
                    </>
                  )
                }
              </div>
              <div className='MJRoadMapQ2' ref={roadmapQ2Position}>
                <div className='roadMapTime'>
                  2024 Q2
                </div>
                <div className='rowContainerClaim'>
                  <div className='text3'>
                    EVM and cross chain compatibility
                  </div>
                  <div className='linkTextAccess'>
                    Staking period
                  </div>
                </div>
                {/* <div className='rowContainerClaim2'>
                  <div className='text2'>
                    Astar RMRK
                  </div>
                  <div className='text4'>
                    Coming soon
                  </div>
                </div> */}
              </div>
              <div
                ref={roadmapQ3Position}
                className={isDaoOpen ? 'MJRoadMapQ3' : 'MJRoadMapQ3Closed'}
                onMouseOver={() => !isMobileWidth ? setIsDaoOpen(true) : null}
                onMouseLeave={() => !isMobileWidth ? setIsDaoOpen(false) : null}
              >
                <div className='roadMapTime'>
                  2024 Q3
                  <FontAwesomeIcon
                    width={26}
                    height={26}
                    style={{
                      border: '1px solid rgba(222, 222, 222, 0.8)',
                      borderRadius: '50%',
                      display: 'flex',
                      justifyContent: 'center',
                      padding: '4px',
                      alignItems: 'center',
                      color: isDaoOpen ? 'rgba(158, 158, 158, 1)' : 'rgba(4, 141, 193, 1)',
                      transition: '0.4s ease-in-out',
                      transform: `rotate(${!isDaoOpen ? '0deg' : '180deg'})`
                    }}
                    onClick={() => isMobileWidth ? setIsDaoOpen(!isDaoOpen) : null}
                    icon={faCaretDown}
                    class='fa-sharp fa-solid fa-caret-down'
                  />
                </div>
                <div className='rowContainerClaim'>
                  <div className='text3'>
                    More benefits to early holders
                  </div>
                  <div className='linkTextAccess'>
                    Under Development
                  </div>
                </div>
                {
                  isDaoOpen && (
                    <div className='rowContainerClaim'>
                      <div className='text4'
                        style={{
                          maxWidth: '403px'
                        }}
                      >
                        Mr. Journaler is just the beginning of our plans to
                        <b> build a community-driven Decentralised Autonomous Organisation (DAO). </b>
                        Early NFT holders will help shape the destiny of our funds, ushering in an exciting era of innovation and collaboration.
                      </div>
                      <div className='linkText'>
                        <img src={waving} alt='Waving' width={200} height={212.77} className='wavingMJ' />
                      </div>
                    </div>
                  )
                }
              </div>
              <div
                ref={roadmapQ4Position}
                className={isMetaverseOpen ? 'MJRoadMapQ3' : 'MJRoadMapQ3Closed'}
                onMouseOver={() => !isMobileWidth ? setIsMetaverseOpen(true) : null}
                onMouseLeave={() => !isMobileWidth ? setIsMetaverseOpen(false) : null}
              >
                <div className='roadMapTime'>
                  2024 Q4
                  <FontAwesomeIcon
                    width={26}
                    height={26}
                    style={{
                      border: '1px solid rgba(222, 222, 222, 0.8)',
                      borderRadius: '50%',
                      display: 'flex',
                      justifyContent: 'center',
                      padding: '4px',
                      alignItems: 'center',
                      color: isMetaverseOpen ? 'rgba(158, 158, 158, 1)' : 'rgba(4, 141, 193, 1)',
                      transition: '0.4s ease-in-out',
                      transform: `rotate(${!isMetaverseOpen ? '0deg' : '180deg'})`
                    }}
                    onClick={() => isMobileWidth ? setIsMetaverseOpen(!isMetaverseOpen) : null}

                    icon={faCaretDown}
                    class='fa-sharp fa-solid fa-caret-down'
                  />
                </div>
                <div className='rowContainerClaim' >
                  <div className='text3'>
                    Gamification and Quests
                  </div>
                  <div className='linkTextAccess'>
                    Under Development
                  </div>
                </div>
                {
                  isMetaverseOpen && (
                    <div className='rowContainerClaim'>
                      <div className='text4' style={{
                        maxWidth: '403px'
                      }}>
                        Mr. Journaler is set to venture into the Metaverse with CanvasLand. This will open new a horizon for gamification,
                        <b> enhancing Mr. Journaler's utility features. </b>
                      </div>
                      <div className='linkText'>
                        <img src={Metaverse} alt='Metaverse' width={238} height={183}
                          className='metaverseMJ' />
                      </div>
                    </div>
                  )
                }
              </div>
              <div
                ref={roadmap25Position}
                className={isAiOpen ? 'MJRoadMapQ3' : 'MJRoadMapQ3Closed'}
                onMouseOver={() => !isMobileWidth ? setIsAiOpen(true) : null}
                onMouseLeave={() => !isMobileWidth ? setIsAiOpen(false) : null}
              >
                <div className='roadMapTime'>
                  2025 Q1
                  <FontAwesomeIcon
                    width={26}
                    height={26}
                    style={{
                      border: '1px solid rgba(222, 222, 222, 0.8)',
                      borderRadius: '50%',
                      display: 'flex',
                      justifyContent: 'center',
                      padding: '4px',
                      alignItems: 'center',
                      color: isAiOpen ? 'rgba(158, 158, 158, 1)' : 'rgba(4, 141, 193, 1)',
                      transition: '0.4s ease-in-out',
                      transform: `rotate(${!isAiOpen ? '0deg' : '180deg'})`
                    }}
                    onClick={() => isMobileWidth ? setIsAiOpen(!isAiOpen) : null}
                    icon={faCaretDown}
                    class='fa-sharp fa-solid fa-caret-down'
                  />
                </div>
                <div className='rowContainerClaim'>
                  <div className='text3'>
                    Access AI Capabilities
                  </div>
                  <div className='linkTextAccess'>
                    Under Development
                  </div>
                </div>
                {
                  isAiOpen && (
                    <div className='rowContainerClaim'>
                      <div className='text4'
                        style={{
                          maxWidth: '403px'
                        }}>
                        We have exciting plans underway to further develop
                        Mr. Journaler and its long-term AI capabilities, serving as
                        <b> an AI-powered crypto accountant.</b>
                      </div>
                      <div className='linkText'>
                        <img src={JournalerAi} alt='JournalerAi' width={240} height={161.18} className='journalerAi' />
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default MrJournaler
