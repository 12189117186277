import React from 'react'
import SideBar from '../../assets/articles/AddWallet/sideBar.png'
import wallet1 from '../../assets/articles/AddWallet/wallet1.png'
import selector from '../../assets/articles/AddWallet/selector.png'
import wallet2 from '../../assets/articles/AddWallet/wallet2.png'

import ArticleHeader from './ArticleHeader'
import ChooseArticle from '../help/journaler/components/Body'
import './Articles.css'

const TroubleshootJournaler = () => {
  return (
    <>
      <ArticleHeader />

      <div className='container'>
        <div className='articleContainer'>
          <div className='border-bottom text-center'>
            <h3><strong>4 Steps to Troubleshooting Journaler</strong></h3>
            <p style={{ color: '#707070' }}>2021.07.17</p>
          </div>
          <div className='pt-5'>
            <p className='text-justify'>Having an issue refreshing your screen or logging in?</p>
            <p className='text-justify'>Try these steps to troubleshoot Journaler.</p>

            <h4 className='pt-3'><strong>Step 1 Clear your browser history</strong></h4>
            <p className='text-justify'>We are always trying to improve our service and adding new features, so that you have a better experience. To this end we may deploy changes once or several times a week, sometimes for the new function or feature, which requires the browser history to be manually cleared. Most updates don’t require this.</p>

            <h4 className='pt-3'><strong>Step 2 Try a new browser</strong></h4>
            <p className='text-justify'>If you are having trouble loading Journaler and have already followed Step #1, then try a different browser. Your current browser may be carrying a large load, and thus slowing Journaler down. Journaler can be opened on any browser, so you can try any other browser that you prefer!</p>

            <h4 className='pt-3'><strong>Step 3 Log Out</strong></h4>
            <p className='text-justify'>There can be many reasons why your account isn’t loading:</p>
            <strong><ul>
              <li>A new update</li>
              <li>You just updated your settings</li>
              <li>You just made your payment</li>
            </ul></strong>
            <p className='text-justify'>Try logging out and then log in again to reset your account.</p>

            <h4 className='pt-3'><strong>STEP 4 Late Payment</strong></h4>
            <p className='text-justify'>If you are unable to access your Journaler features it could be due to a late payment. Simply go to your <strong>Settings</strong> and select <strong>Pay Now</strong>.</p>
            <p className='text-justify'>Once you are done, it may take a couple minutes to reset your account.</p>
            <p className='text-justify'>Please note, we are currently working on improvements, but in order to upgrade your plan you will have to cancel and resubscribe to the higher level plan. You will continue to have access to your profile but until you make the payment, features will not be available. We expect to have the automatic upgrade feature added soon.</p>

          </div>
        </div>
      </div>

      <ChooseArticle />
    </>
  )
}

export default TroubleshootJournaler
