import './help-center.css';

import React, {useEffect, useRef, useState} from 'react';

// modules
import {Container, Row, Col, ListGroup, Collapse, Spinner} from 'react-bootstrap';
import { FaCaretRight, FaCaretDown } from 'react-icons/fa';
import {useHistory, useLocation} from "react-router-dom";
import Button from "react-bootstrap/Button";
import * as Icon from "react-bootstrap-icons";

// hooks
import {useHelpCenter} from './useHelpCenter'

// components
import {MarkdownView} from "../../components/atoms";

const HelpCenter = () => {
    const history = useHistory();
    const location = useLocation();
    const contentRef = useRef(null);

    const helpCenter = useHelpCenter()

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const onToggleMenu = () => setIsMenuOpen(!isMenuOpen);

    const toggleExpand = (id) => {
        helpCenter.setOpenItems(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    const handleItemClick = (item) => {
        if (!item) return
        helpCenter.setSelectedContent(item);
        if (item.id) history.push(`?page=${item.id}`);
    };

    const renderMenuItems = (items, isToggle) => {
        return items.map((item) => {
            return (
                <div key={item.id}>
                    <ListGroup.Item
                        variant='light'
                        className={`menu-item ${helpCenter.selectedContent?.id === item.id ? 'menu-item-selected' : ''}`}
                        title={item.name}
                        onClick={() => {
                            handleItemClick(item);
                            if (isToggle && !!item.content) {
                                onToggleMenu()
                            }
                            if (!helpCenter.openItems[item.id]) {
                                toggleExpand(item.id);
                            }
                        }}
                    >
                        {item.pages?.length > 0 && (
                            <Button
                                size='sm'
                                variant='outline-light'
                                onClick={(event) => {
                                    event.stopPropagation();
                                    toggleExpand(item.id);
                                }}
                                className='side-button'
                            >
                                {helpCenter.openItems[item.id] ? <FaCaretDown /> : <FaCaretRight />}
                            </Button>
                        )}
                        <Button
                            size='sm'
                            variant='link'
                            className='side-button'
                        >
                            {item.name}
                        </Button>
                    </ListGroup.Item>
                    <Collapse in={helpCenter.openItems[item.id]}>
                        <div>
                            {item.pages?.length > 0 && (
                                <ListGroup className="ml-3">
                                    {renderMenuItems(item.pages, isToggle)}
                                </ListGroup>
                            )}
                        </div>
                    </Collapse>
                </div>
            );
        });
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const pageId = params.get('page');

        if (pageId && helpCenter.data?.length > 0) {

            const onFindItemById = (items, id) => {
                for (let item of items) {
                    if (item.id === id) return item;
                    if (item.pages?.length > 0) {
                        const found = onFindItemById(item.pages, id);
                        if (found) return found;
                    }
                }
                return null;
            };

            const onExpandParents = (itemId) => {
                const item = onFindItemById(helpCenter.data, itemId);
                if (!item) return;

                const newOpenItems = { [item.id]: true };
                let parentId = item.parent_page_id;

                while (parentId) {
                    newOpenItems[parentId] = true;
                    const parentItem = onFindItemById(helpCenter.data, parentId);
                    parentId = parentItem ? parentItem.parent_page_id : null;
                }

                helpCenter.setOpenItems(newOpenItems);
            };

            const selectedItem = onFindItemById(helpCenter.data, pageId);
            if (selectedItem) {
                helpCenter.setSelectedContent(selectedItem);
                onExpandParents(pageId);
            }
        }
    }, [helpCenter.data?.length]);

    useEffect(() => {
        if (contentRef.current) {
            contentRef.current.scrollIntoView({behavior: "smooth"})
        }
    }, [helpCenter.selectedContent]);

    return (
        <Container fluid className="help-center-container pb-5">
            <Row>
                {/* Button to toggle the drawer on mobile */}
                <Row className='d-md-none justify-content-center align-items-center mb-3 pl-4'>
                    <Button
                        className="d-md-none menu-toggle-btn"
                        onClick={onToggleMenu}
                        variant='link'
                    >
                        <Icon.List color='black' size={28}/>
                    </Button>
                    <h4 className='p-0 m-0'>AEM Help Center</h4>
                </Row>

                {/* Left Side Menu for Mobile */}
                <Col xs={12} md={3}
                     className={`d-md-none border-right static-menu-mobile ${isMenuOpen ? 'open' : ''}`}>

                    <ListGroup className="menu-content">
                        <Row className='justify-content-between ml-3 mt-3 mb-3'>
                            <h4 className='m-0'>AEM Help Center</h4>

                            <Button className="d-md-none" onClick={onToggleMenu} variant='link'>
                                <Icon.XLg color='black'/>
                            </Button>
                        </Row>
                        {renderMenuItems(helpCenter.data, true)}
                    </ListGroup>
                </Col>

                {/* Left Side Menu */}
                <Col xs={12} md={3} className="border-right static-menu">
                    <ListGroup>
                        <h4 className='header-title'>AEM Help Center</h4>
                        {renderMenuItems(helpCenter.data)}
                    </ListGroup>
                </Col>

                {/* Right Side Content */}
                <Col xs={12} md={9} className='right-content'>
                    <div ref={contentRef} className='scroll-to-top-reference'/>
                    <h4 className='header-title'>{helpCenter.selectedContent?.name}</h4>
                    {helpCenter.selectedContent?.id ? (
                        <MarkdownView>
                            {helpCenter.selectedContent?.content}
                        </MarkdownView>
                    ) : (
                        <div className="p-3"></div>
                    )}
                </Col>
            </Row>

            {/* Overlay for mobile menu */}
            {isMenuOpen && <div className="menu-overlay" onClick={onToggleMenu}></div>}
            {helpCenter.isLoading && <Row className='justify-content-center mt-lg-5'>Loading...</Row>}
        </Container>
    );
};


export default HelpCenter;
