import React from 'react';
import './XeroIntegration.css';

// img:
import XeroAppImg from '../../../../assets/xeroIntegration/xero-connected-app-logo-hires-white-RGB.svg';
import JounalerHelp from './components/JounalerHelp';
import Features from './components/Features';
import Blockchains from './components/Blockchains'
import AboutXero from './components/AboutXero';
import IntegrateSteps from './components/IntegrateSteps';
import DemoSection from './components/DemoSection';
import CryptoSupport from './components/CryptoSupport';
import TwoBtns from './components/TwoBtns';
import { Helmet } from 'react-helmet';

const xeroIntegrationContents = {
  title: ['Blockchain Accounting Software', 'Made For Your Business'],
  des:
    ' AEM Journaler enables your business to make crypto transactions to pay bills and receive funds. You make the crypto payment, and we will track and keep a record of it for you.',
};

const XeroIntegration = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>AEM Algorithm | Easily Report Your Crypto Into Xero Accounting</title>
        <meta name="Integration" content="Sync crypto transactions, invoices, bills, tax 
        liabilities & wallet balances directly into your Xero accounting system with 
        AEM Journaler. Sign up now with a free trial." />
      </Helmet>
      <div className='container-fluid globalHeaderBgL xeroHeaderContainer text-center'>
        <div className='row'>
          <div className='col'>
            <h1 className='h1BoldJounaler'>{xeroIntegrationContents.title[0]}</h1>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <h1 className='h1BoldJounaler'>{xeroIntegrationContents.title[1]}</h1>
          </div>
        </div>

        <div className='row justify-content-center pt-4'>
          <div className='col col-sm-12 col-md-5'>
            <p className='desIntegrade text-center'>{xeroIntegrationContents.des}</p>
          </div>
        </div>

        {/* ----------- two btns section */}
        <div className='text-center py-3'>
          <a
            href='https://journaler.aem.ac/'
            target='_blank'
            className='btn btnGroup_whiteBgBtn'
            style={{ color: '#2B648D' }}
          >
            Get started with Journaler
          </a>
          <a
            href='https://www.xero.com/au/signup/?xtid=x30aemjournaler'
            target='_blank'
            className='btn btnGroup_whiteBorderBtn'
          >
            Try Xero for Free
          </a>
        </div>


        <div className='d-flex justify-content-center pt-4 pb-5'>
          <img src={XeroAppImg} alt='xero connected app logo' height='96' />
        </div>
      </div>

      <DemoSection />

      <JounalerHelp />

      <Features />

      {/* <Blockchains /> */}


      <CryptoSupport />
      <AboutXero />

      <IntegrateSteps />
    </>
  );
};

export default XeroIntegration;
