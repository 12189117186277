import "./Team.css";
import React from "react";
import TeamLandingPage from "./TeamLandingPage";
import TeamFlipCard from "./TeamFlipCard";

const Team = () => {
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>

      {
        screenWidth <= 1000 ? (
          <TeamFlipCard />
        ) : (
          <div className="containerTeam">
            <TeamLandingPage />
          </div >
        )
      }
    </>
  );
};

export default Team;
