import React from 'react';
import './ReturnPage.css';

import { Link } from 'react-router-dom';

import AemTextLogo from '../../assets/AEM_Algorithm_text_logo.png';

 const  ReturnSuccess = () => {
  return (
    <div className='notFoundContainer'>
      <div className='logoImgDiv'>
        <img src={AemTextLogo} alt='logo' style={{ width: '100%' }} />
      </div>

      <div className='p-4'>
        <h1 style={{ color: '#094674', fontWeight:'bold' }}>Payment Complete</h1>
        <p className='text-center'>Thank you, your payment has been successful.
A confirmation email has been sent to your email.</p>
        <Link to='/'>Go back home</Link>
      </div>
    </div>
  );
};
export default ReturnSuccess