import React, { Component } from 'react';
import btctothemoon from '../../assets/contactUs/btctothemoon.png'
import './Investor.css'
import AemNewLogo from '../../assets/logos/GroupAem.svg';
class Investor extends Component {
    render() {
        return (
            <>
                <div className='mobile'>
                    <div className='container-fluid contactUsHeader globalHeaderBgS'>
                        <div class="d-flex justify-content-center ">
                            <div className="container-ipad">
                                <img className='imgdiv2 img-fluid' src={AemNewLogo} />
                                <div className='titleAEM'>
                                    GROW WITH AEM
                                </div>
                                <div className='subtileAem'>
                                    AEM is always looking for help from investors to improve the accessibility of our platforms.
                                    <span style={{ fontWeight: 'bold' }}> Enquire below to get started</span>
                                </div>
                                <div className='btnipad'>

                                    <a href='/contact' className='btn btnGroup_whiteBorderBtn'>
                                        Submit your interest
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='ipad'>
                    <div className='container-fluid contactUsHeader globalHeaderBgS'>
                        <div class="d-flex justify-content-center ">
                            <div className="container-ipad">
                                <img className='imgdiv2 img-fluid' src={AemNewLogo} />
                                <div className='titleAEM'>
                                    GROW WITH AEM
                                </div>
                                <div className='subtileAem'>
                                    AEM is always looking for help from investors to improve the accessibility of our platforms.
                                    <span style={{ fontWeight: 'bold' }}> Enquire below to get started</span>
                                </div>
                                <div className='btnipad'>

                                    <a href='/contact' className='btn btnGroup_whiteBorderBtn'>
                                        Submit your interest
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='desktop'>
                    <div className='container-fluid contactUsHeader globalHeaderBgS'>
                        <div class="row">
                            <div className="col-sm-6">
                                <img className='imgBtc' src={btctothemoon} />
                            </div>
                            <div className="col-sm-6">
                                <img className='imgdiv2 img-fluid' src={AemNewLogo} />
                                <div className='titleAEM'>
                                    GROW WITH AEM
                                </div>
                                <div className='subtileAem'>
                                    The emergence of cryptocurrency continues to develop as we begin to navigate a
                                    new age of FinTech and finance. AEM Algorithm is always looking for help from investors
                                    to improve the accessibility of Journaler and AEM+. If you are passionate about the development of digital currencies
                                    and accessible crypto tech - consider investing in us, so that we can continue to be a part of the crypto revolution.
                                </div>
                                <div className='py-5'>

                                    <a href='/contact' className='btnGroup_Submit'>
                                        Submit your interest
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
export default Investor;