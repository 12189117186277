import React, { Component } from 'react';
import './newsList.css'
import HeaderImg from '../../../assets/news/Article2_3.svg'


const data = {
  title: 'AEM launches Journaler with support of RMIT!',
  date: '2021.05.17',
  content: [
    'AEM Algorithm has launched the new version of its Journaler package with the support of RMIT Blockchain Innovation Hub and School of Computer Science and IT. A pioneer in the world of accounting systems for Cryptocurrencies, AEM Journaler links accounting systems such as Xero with Blockchains and Trading Platforms in order to report and capture the transactions in real time.',
    'The Partnership with the RMIT Blockchain Innovation Hub has been critical to the development of the latest version of the AEM Journaler Package. RMIT has supported AEM Algorithm with access to research and academics, providing internships and industry experience for students and ultimately employment for a number of students in all areas of AEM – including information systems, software development, design, business and marketing, extending into 2021 and beyond.',
    'AEM Algorithm has worked closely with Michael Fairbairn of the RMIT Blockchain Hub who facilitated the development of their commercial offering, including the certification of the Journaler system by Xero in late 2020, and their approval to enter the Xero app marketplace as their first crypto app. Having already met Xero’s criteria, Journaler launch is set to occur once testing and scaling of the platform have been completed.',
    'CEO Jakub Sawczuk comments that “with the help of RMIT, AEM delivers cutting edge technology and bridges the gap between blockchain and the B2B/B2C, making it accessible and easy to operate with cryptocurrencies”.',
    'Further, AEM has built a strong relationship with the College of Business’s School of Accounting, Information Systems and Supply Chains. This relationship has been facilitated by Dr. Elizabeth Morton, with research pertaining to the resolution of current taxation issues regarding the treatment of digital assets to which AEM’s new system looks to provide sophisticated solutions.',
    'Going forward, AEM will continue to utilise the outstanding resources of RMIT including its academics and students to further its growth in the crypto economy and looks to collaborate on more projects. AEM will provide further opportunities for student interns to gain practical insight into the blockchain sector specific to accounting and finance through involvement in the technical work of the company. Through this partnership with RMIT, AEM aims to continue to flourish and to remain at the forefront of the evolution of Blockchain and Finance and provide practical solutions for Business looking to utilise Distributed Ledger Technology and digital currencies.'
  ]
}

const content = data.content.map((content) =>
  <p className='text-justify'>{content}</p>
)

class RmitSupportJournaler extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <div className='globalHeaderBgS newsListHeader text-center'>
          <div className='row'>
            <div className='col'>
              <h1 className='h1Bold'>News</h1>
            </div>
          </div>
        </div>

        <div className='newsContentBg'>
          <div className='container'>
            {/* <div className='newsHeaderImgBg'> */}
            <img className="card-img-top" src={HeaderImg} alt='header image' />
            {/* </div> */}
            <div className='newsContent'>
              <h3 className='font-weight-bold'>{data.title}</h3>
              <p style={{ color: '#707070' }}>{data.date}</p>
              <div>{content}</div>
            </div>
            {/* TODO: share to media function */}
            <div className='newsContentFooter'>
              {/* <p className="text-right">Share with</p> */}
            </div>
          </div>
        </div>
      </>
    );
  };
}

export default RmitSupportJournaler;
