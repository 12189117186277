import '../Main.css'

// constants
import {images} from "../../../../constants";

// Modules
import React from 'react';

const logo = [
  {
    img: images.xeroLogo,
    alt: 'XeroLogo',
    link: 'https://www.xero.com/au/',
    style: {
      height: 85,
    }
  },
  {
    img: images.binanceLogo,
    alt: 'binance',
    link: 'https://www.binance.com/en'
  },
  {
    img: images.coinbaseLogo,
    alt: 'CoinbaseLogo',
    link: 'https://www.coinbase.com/au'
  },
  {
    img: images.safeLogo,
    alt: 'Safe',
    link: 'https://www.gnosis.io/',
    style: {
      height: 30,
    }
  },
  {
    img: images.fireBlockLogo,
    alt: 'Fireblocks',
    link: 'https://www.fireblocks.com/'
  },
  {
    img: images.quickbooksLogo,
    alt: 'QuickBooksLogo',
    link: 'https://quickbooks.intuit.com/au/',
    style: {
      height: 25,
    }
  },
];

const IntegrationCard = ({ img, alt, link, style }) => (
  <div>
    <a href={link} target="_blank">
      <img src={img} alt={alt} className='logoIntegration' style={style} />
    </a>
  </div>
);

const Integrations = ({ isMobile }) => {
  return (
    <div
      className='row d-flex justify-content-center align-items-center'
      style={{
        fontSize: '21px',
        fontWeight: '500',
        LineHeight: '29.6px'
      }}>
      {
        !isMobile && (
          'Integrated software'
        )
      }
      {logo.map((logo) => (
        <div key={logo.alt} className='integratedPlatforms'>
          <IntegrationCard
              img={logo.img}
              alt={logo.alt}
              link={logo.link}
              style={logo.style}
          />
        </div>
      ))}
    </div>
  );
};

export default Integrations;
