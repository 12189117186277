import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import FacebookLogo from '../../assets/footer/Icon awesome-facebook.svg';
import LinkedInLogo from '../../assets/footer/Icon awesome-linkedin.svg';
import TelegramLogo from '../../assets/footer/Icon awesome-telegram.svg';
import TwitterLogo from '../../assets/footer/Icon awesome-twitter-square.svg';
import aemlogo from '../../assets/footer/aemlogo.png';
import YoutubeLogo from '../../assets/footer/youtube.svg';
import './Footer.css';

import { Link } from 'react-router-dom';

import { firstColLinks, footerCR, fourColLinks, locationAu, locationHK, secondColLinks, thirdColLinks } from './data';

const ListLinks = ({ data }) => (
  <div >
    <strong className='underlineLabel'>{data.label}</strong>
    <ul className='list-links'>
      {data.content.map((link) => {
        return (
          <React.Fragment key={link.name}>
            {link.type === 'relative' ? (
              <li className='footerLi'>
                <Link className='list-link linksStyle' to={link.path}>
                  {link.name}
                </Link>
              </li>
            ) : (
              <li className='footerLi'>
                <a className='list-link linksStyle' href={link.path} rel='noreferrer' target='_blank'>
                  {link.name}
                </a>
              </li>
            )}
          </React.Fragment>
        );
      })}
    </ul>
  </div>
);

const Footer = () => {
  const [isToggle, setIsToggle] = useState(false)
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const openToggle = () => {
    setIsToggle(!isToggle)

  }

  return (
    <div className='footerContainer'>
      {/* desktop view */}
      <div className='desktop'>
        <div className='row justify-content-center'>
          <div className='d-flex justify-content-center col-4 col-md-2'>
            <ListLinks data={firstColLinks} />
          </div>
          <div className='d-flex justify-content-center col-4 col-md-2 d'>
            <ListLinks data={secondColLinks} />
          </div>
          <div className='d-flex justify-content-center col-4 col-md-2'>
            <ListLinks data={thirdColLinks} />
          </div>
          <div className='col-6 col-md-2 d-flex justify-content-center pt-3 pt-md-0'>
            <ListLinks data={fourColLinks} />
          </div>
          <div className=' col-6 col-md-2 pt-3 pt-md-0'>
            <ListLinks data={locationAu} />
            <div className='pt-4'>
              <ListLinks data={locationHK} />

            </div>
          </div>

          <div className='col-6 col-md-2 pt-3 pt-md-0'>
            <div className='d-flex justify-content-center'>
              <img className='image-fluid rounded float-end' src={aemlogo}></img>
            </div>

            <div className='row pt-5 d-flex justify-content-center'>
              <a href='https://www.facebook.com/AEMalgorithm/' target='_blank' rel='noreferrer'>
                <img src={FacebookLogo} alt='facebook logo' className='mr-2' height='25px' />
              </a>
              <a href='https://twitter.com/AEM_Algorithm' target='_blank' rel='noreferrer'>
                <img src={TwitterLogo} alt='twitter logo' className='mr-2' height='25px' />
              </a>
              <a href='https://t.me/AEMalgorithm' target='_blank' rel='noreferrer'>
                <img src={TelegramLogo} alt='telegram logo' className='mr-2' height='25px' />
              </a>
              <a href='https://www.linkedin.com/company/aem-technologies-pty-ltd' target='_blank' rel='noreferrer'>
                <img src={LinkedInLogo} alt='linkedIn logo' className='mr-2' height='25px' />
              </a>
              <a href='https://www.youtube.com/channel/UCdAv05DQVYVj1ZIKjallrWg' target='_blank' rel='noreferrer'>
                <img src={YoutubeLogo} alt='youtube logo' width='25px' />
              </a>
            </div>
          </div>

        </div>
      </div>
      {/* mobile view */}

      <div className='mobile pt-3 pb-3 bgmobile' >
        <div>
          <div className='d-flex justify-content-around '>
            <div className='col-footer'>
              <ListLinks data={firstColLinks} />
            </div>
            <div className='col-footer'>
              <ListLinks data={secondColLinks} />
            </div>
          </div>

        </div>
        {!isToggle ?
          <div class="btn-group pt-3" style={{ paddingLeft: '7%' }}>
            <button class="dropdown-toggle underlineLabel" type="button" aria-haspopup="true" aria-expanded="false" onClick={openToggle}>
              Location
            </button>
          </div> :
          <div>
            <div class="btn-group dropup pt-3" style={{ paddingLeft: '7%' }}>
              <button class="dropdown-toggle underlineLabel" type="button" aria-haspopup="true" aria-expanded="false" onClick={openToggle}>
                Location
              </button>
            </div>
            <div style={{ paddingLeft: 30, paddingTop: 10 }}>
              <div class="row">
                <div class="col-5">
                  <a className='list-link linksStyle' href={'https://rmitblockchain.io/'} rel='noreferrer' target='_blank'>
                    RMIT Blockchain Innovation Hub
                  </a>
                </div>
                <div class="col">
                  <a className='list-link linksStyle' href={'https://www.stoneandchalk.com.au/'} rel='noreferrer' target='_blank'>
                    Stone & Chalk
                  </a>
                </div>
                <div class="col">
                  <a className='list-link linksStyle' href={'https://www.cyberport.hk/en'} rel='noreferrer' target='_blank'>
                    Cyberport
                  </a>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
      {isMobile ?
        <div className='row pt-3 footerMobile pb-3'>
          <div className='col-12 col-ml-6 col-lg-6 col-xl-6'>
            <p className='footerDropdownBtn '>{footerCR}</p>
          </div>
          <div className='pl-5'>
            <span><a class="footerTerm" href="/journaler/terms-conditions"> Journaler Term & Conditions </a></span> <span class="footerTermhr">|</span>
            <span><a class="footerTerm" href="/journaler/aem-plus/terms-conditions"> AEM+ Term & Conditions </a></span>
          </div>
          <div className='pl-5'>
            <span> <a class="footerTerm" href="/privacy-policy">Journaler Privacy Policy</a></span><span class="footerTermhr">|</span>
            <span> <a class="footerTerm" href="/journaler/aem-plus/privacy-policy">AEM+ Privacy Policy</a></span>
          </div>


        </div>
        : <div className='row pt-5'>
          <div className='col-12 col-ml-6 col-lg-6 col-xl-6'>
            <p className='footerDropdownBtn '>{footerCR}</p>
          </div>
          <div className='col-12 col-ml-6 col-lg-6  col-xl-6 footerTerm'>
            <div class="btn-group">
              <button class="btn btn-secondary btn-sm dropdown-toggle footerDropdownBtn" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Term & Conditions
              </button>
              <div class="dropdown-menu custom-dropdown">
                <a class="dropdown-item" href="/journaler/terms-conditions">Journaler</a>
                <a class="dropdown-item" href="/journaler/aem-plus/terms-conditions">AEM+</a>
              </div>
            </div>
            <div class="btn-group pl-3">
              <button class="btn btn-secondary btn-sm dropdown-toggle footerDropdownBtn" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Privacy Policy
              </button>
              <div class="dropdown-menu custom-dropdown">
                <a class="dropdown-item" href="/privacy-policy">Journaler</a>
                <a class="dropdown-item" href="/journaler/aem-plus/privacy-policy">AEM+</a>
              </div>
            </div>
          </div>
        </div>
      }


    </div >
  );
};

export default Footer;
