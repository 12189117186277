import {useEffect, useState} from "react";

// provider
import {useHelpCenterContext} from "../../provider/HelpCenter";

export const useHelpCenter = () => {
    const {data} = useHelpCenterContext()

    const [openItems, setOpenItems] = useState({});

    /** State to hold the selected content */
    const [selectedContent, setSelectedContent] = useState({});

    useEffect(() => {
        function onInitContent() {
            if (data && data.length > 0) {
                setOpenItems({[data[0]?.id]: true})
                setSelectedContent(data[0])
            }
        }
        onInitContent()
    }, [data?.length]);

    return {
        data: data || [],
        isLoading: data === null,
        openItems,
        setOpenItems,
        selectedContent,
        setSelectedContent
    }
}
