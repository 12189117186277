import React from 'react';
import './TermsConditions.css';
import { ListData } from './components/Wrappers';

import { BulletListContents, MainSectionWrapper, SectionWrapper, MulitiList } from './components/Wrappers';
import {
  intro_overall_contents,
  tableOfContents,
  acceptanceContents,
  susbscriptionContents,
  privacyPoliceContents,
  terminateAccount,
  rightCopyrightsContents,
  liabilityContents,
  changesContents,
} from './data/contents';

const JournalerTermsConditions = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='pageWrapper'>
      <div className='row justify-content-center'>
        <h1 className='titleBorder text-center'>AEM Algorithm’s ‘Journaler’ terms and conditions</h1>
      </div>

      <div className='container py-4'>
        {/*  Introduction & Overall Section */}
        {intro_overall_contents.map((eachSection) => (
          <MainSectionWrapper
            key={eachSection.id}
            id={eachSection.id}
            title={eachSection.title}
            contents={eachSection.contents}
          />
        ))}

        <ListData dataCollection={acceptanceContents}/>
        <ListData dataCollection={privacyPoliceContents}/>
        <ListData dataCollection={susbscriptionContents}/>
        <ListData dataCollection={terminateAccount}/>
        <ListData dataCollection={rightCopyrightsContents}/>
        <ListData dataCollection={liabilityContents}/>
        <ListData dataCollection={changesContents}/>


        {/*  Table of contents */}
        {/* <ul className='list-group tableContents'>
          {tableOfContents.map((content) => (
            <li className='list-group-item border-0 tableContent' key={content.id}>
              <a href={`#${content.id}`}>{content.title}</a>
            </li>
          ))}
        </ul> */}

        {/*  1. Acceptance of terms*/}
        {/* <SectionWrapper contents={acceptanceContents}>
          <p className='text-justify'>{acceptanceContents.desc.text}</p>
        </SectionWrapper> */}

        {/*  2 . Reference to privacy policy*/}
        {/* <SectionWrapper contents={privacyPoliceContents}>
          <BulletListContents contents={privacyPoliceContents} />
        </SectionWrapper> */}

        {/* 3.Subscription payments and billing */}
        {/* <SectionWrapper contents={susbscriptionContents}>
          <MulitiList contents={susbscriptionContents} />
        </SectionWrapper> */}

        {/* 4. Termination of account  */}
        {/* <SectionWrapper contents={terminateAccount}>
          <MulitiList contents={terminateAccount} />
        </SectionWrapper> */}

        {/*  5  Intellectual property rights and copyright*/}
        {/* <SectionWrapper contents={rightCopyrightsContents}>
          <BulletListContents contents={rightCopyrightsContents} />
        </SectionWrapper> */}

        {/*  6. Indemnities and limitation of liability */}
        {/* <SectionWrapper contents={liabilityContents}>
          <BulletListContents contents={liabilityContents} />
        </SectionWrapper> */}

        {/*  7. Changes to the Terms and Conditions */}
        {/* <SectionWrapper contents={changesContents}>
          <MulitiList contents={changesContents} />
        </SectionWrapper> */}
      </div>
    </div>
  );
};

export default JournalerTermsConditions;
