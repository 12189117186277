
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Accountants from './Accountants';
import BusinessOwners from './BusinessOwners';
import FinancialInstitution from './FinancialInstitution';
import './UseCase.css';

const UseCase = () => {
    const [mobileWallet, setMobileWallet] = useState(1);

    const onChangeMobile = (item) => {
        // chooseWallet(item)
        setMobileWallet(item);

    }
    const isMobile = useMediaQuery({ maxWidth: 700 });

    const bodyUseCase = () => {
        if (mobileWallet === 1) {
            return (<Accountants />)
        }
        else if (mobileWallet === 2) {
            return <BusinessOwners />
        }
        else {
            return <FinancialInstitution />
        }

    }

    return (
        <div style={{ overflow: 'hidden' }}>
            <div className='container text-center pt-5' style={{ marginTop: 70 }}>
                <span className='usecase-header'>Use Cases</span>
            </div>
            {!isMobile ? <div className='container'>

                <div className="d-flex justify-content-center pt-5">
                    <div className='pr-5'>
                        <button onClick={() => onChangeMobile(1)} className={mobileWallet === 1 ? 'clickbBtnSimplifyUsecase' : 'btnSimplifyUsecase'}>Accountants</button>
                    </div>
                    <div className='pr-5'>
                        <button onClick={() => onChangeMobile(2)} className={mobileWallet === 2 ? 'clickbBtnSimplifyUsecase' : 'btnSimplifyUsecase'}>Business Owners</button>
                    </div>
                    <div>
                        <button onClick={() => onChangeMobile(3)} className={mobileWallet === 3 ? 'clickbBtnSimplifyUsecase' : 'btnSimplifyUsecase'}>Financial Institutions</button>
                    </div>
                </div>
            </div> :

                <div >
                    <div className='d-flex justify-content-center pt-3'>
                        <button onClick={() => onChangeMobile(1)} className={mobileWallet === 1 ? 'clickbBtnSimplifyUsecase' : 'btnSimplifyUsecase'}>Accountants</button>
                    </div>
                    <div className='d-flex justify-content-center pt-3'>
                        <button onClick={() => onChangeMobile(2)} className={mobileWallet === 2 ? 'clickbBtnSimplifyUsecase' : 'btnSimplifyUsecase'}>Business Owners</button>
                    </div>
                    <div className='d-flex justify-content-center pt-3'>
                        <button onClick={() => onChangeMobile(3)} className={mobileWallet === 3 ? 'clickbBtnSimplifyUsecase' : 'btnSimplifyUsecase'}>Financial Institutions</button>
                    </div>
                </div>

            }

            {bodyUseCase()}
        </div>
    )
}


export default UseCase;